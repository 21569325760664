import React from "react";
import Slider from "react-slick";
import { json, Link } from 'react-router-dom';
import Moment from 'react-moment';
// import './Editorpicksection.css';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Topwritersection(props)
{
  const topwriter=props.topwriter;
// check story array lenght
const arraylength=topwriter.length;
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    infinite: true,
    

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };


    return(
        <>
        {arraylength>0 ?(
            <div className="container-fluid  ">
            <div className="container2 mt-5">
            <h4 className='mb-5 mt-5 color214545 fw-bold'>Top Writer This Month 
            <span className="floatright">
            {/* <a href="#" className="btn btn-dark btn-sm cardbtn b-radius-30">View All &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i>
            </a>  */}
            </span>
            </h4>
            <div className="row">
            <Slider {...settings} className="customeslider">
                {topwriter.map((writer, index) => (
                <div className="col-md-3 p-r-10"  key={index}>
                    <div  style={{position:'relative'}}>
                <div className="hashtag1">
                    {index==0?(
                     <span className="hashrag2">#1 {writer.Name}</span>
                    ):
                    <span className="bgcolor whiltecolo hashrag2">#{index+1} {writer.Name}</span>
                    }
              
                </div>
                    <img src={writer.filename}  className="w-100 h-300px"  />
                    </div>
                </div> 
            )) }
            </Slider>
            </div>
            </div>

            </div>
        ):null}
  

    
        </>
    )
}