import React,{ useState ,useEffect} from 'react';
import './Navbar.css';
import logo from '../Assets/logo/logo.png';
import { Modal, Button } from 'react-bootstrap'
import swal from 'sweetalert';
import axios from "axios";
import $ from 'jquery';
import { Link, useLocation,useNavigate  } from 'react-router-dom';
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Navbar(props) {

  const baseurl=props.baseurl;
  const baseurlapi=props.baseurlapi;

  let navigate = useNavigate();
  

  function changeLocation(placeToGo){
      navigate(placeToGo, { replace: true });
      window.location.reload();
  }


  const [mainmodel, setmainmodel] = useState(false);
  const [termandcondition, settermandcondition] = useState(false);
  const [sswctermandcondition, setsswctermandcondition] = useState(false);
  const TermandconditionClose = () => settermandcondition(false);
  const SswctermandconditionClose = () => setsswctermandcondition(false);
  

  const TermandconditionShow = () => settermandcondition(true);

  

  const Close = () => setmainmodel(false);
  const Show = () => setmainmodel(true);

  const [loginshow, setShowlogin] = useState(false);
  const LoginClose = () => setShowlogin(false);

  
  
  const [studentreg, setStudentreg] = useState(false);
  const [reviewerreg, setReviewerreg] = useState(false);
  const [schoolreg, setSchoolreg] = useState(false);

  const [Maincat, setMaincat] = useState([]);
  const [Subcat, setSubcate] = useState([]);
  const [Subcat2, setSubcate2] = useState([]);
  const { pathname } = useLocation();
  
  useEffect(() => {

    var userid = sessionStorage.getItem("userid");
    axios.get(baseurlapi+"/Navbar.php")
    .then(response2 => {
      setMaincat(response2.data.Maincat)
      setSubcate(response2.data.Subcat)
    })
    window.history.replaceState(null, null, window.location.href);
     window.scrollTo(0, 0);
  }, [pathname]);

  // array filter code here
  const handleHover = value => ()=>
  {
    var Subcat2 =[];
      for(var i=0;i<Subcat.length;i++)
      { 
        var obj = {};
          if(Subcat[i].maincatid==value)
          {
            obj['id'] =Subcat[i].id;
            obj['title'] =Subcat[i].title;
            obj['mid'] =Subcat[i].maincatid;
            Subcat2.push(obj);
          }
      }
      setSubcate2(Subcat2)
   
  }

  function LoginShow()
  {
    var rate_value= document.querySelector('input[name="joinas"]:checked').value;
      setShowlogin(true);
      setmainmodel(false);

      if(rate_value==1)
      {
        setStudentreg(true);
        setReviewerreg(false);
        setSchoolreg(false);

      }
      else if(rate_value==2)
      {
        setStudentreg(false);
        setReviewerreg(true);
        setSchoolreg(false);

      }
      else if(rate_value==3)
      {
        setSchoolreg(true);
        setReviewerreg(false);
        setStudentreg(false);
      } 
  }

  function Login()
  {
    window.open(baseurl, '_blank');
  }

  // student function call here 
  function Studentreg()
  {
    
      var stdtname= document.getElementById('stdtname').value;
      var stdmno= document.getElementById('stdmno').value;
      var stdcity= document.getElementById('stdcity').value;
      var stdemail= document.getElementById('stdemail').value;
      var stdpassword= document.getElementById('stdpassword').value;
      var stdcnfpassword= document.getElementById('stdcnfpassword').value;
      var stdcheck = document.getElementById("stdcheck"); 
      var country= document.getElementById("country").value;
      var schoolname= document.getElementById("schoolname").value;
      console.log(schoolname)
      // var stdob = document.getElementById("stdob"); 
      var stdob = $("#stdob").val()
     
      if(stdtname=='')
      {
        swal({title: "",text: "Please fill out name",icon: "error", });
      }
      else if(stdmno=='')
      {
        swal({title: "",text: "Please fill out Mobile No ",icon: "error",});
      }
      else if (stdmno.length < 10 || stdmno.length > 10) {
       
        swal({title: "",text: "Mobile No. is not valid, Please Enter 10 Digit Mobile No. ",icon: "error",});
       
      }
      else if(stdemail=='')
      {
        swal({title: "",text: "Please fill out Email",icon: "error",});
      }
      else if(country=='')
      {
        swal({title: "",text: "Please fill out country",icon: "error",});
      }
      else if(stdcity=='')
      {
        swal({title: "",text: "Please fill out City ",icon: "error",});
      }
      
      else if(stdob=='')
      {
        swal({title: "",text: "Please fill out date of birth",icon: "error",});
      }
      else if(schoolname=='')
      {
        swal({title: "",text: "Please fill out school name",icon: "error",});
      }
      else if(stdpassword=='')
      {
        swal({title: "",text: "Please fill out Password",icon: "error",});
      }
      else if(stdcnfpassword=='')
      {
        swal({title: "",text: "Please fill out Confirm Password",icon: "error",});
      }
      else if(stdcnfpassword!=stdpassword)
      {
        swal({title: "",text: "Confirm Password Does not match",icon: "error",});
      }
      else if (stdcheck.checked == false) 
      {
        swal({title: "",text: "Please Click In Check Box",icon: "error",});
      }
      
      else
      {
        
        const Studentarr = new FormData();
        Studentarr.append("name", stdtname);
        Studentarr.append("mobileno", stdmno);
        Studentarr.append("city", stdcity);
        Studentarr.append("email", stdemail);
        Studentarr.append("dob", stdob);
        Studentarr.append("password", stdpassword);
        Studentarr.append("checkstatus", "Y");
        Studentarr.append("country", country);
        Studentarr.append("schoolname", schoolname);
        Studentarr.append("type", "S");
        Studentarr.append("R_firstname",'');
        Studentarr.append("R_lastname",'');
        Studentarr.append("R_qualification",'');
        Studentarr.append("R_position",'');
        Studentarr.append("R_phoneno",'');
        Studentarr.append("R_email",'');
        Studentarr.append("R_password",'');
        Studentarr.append("R_profile",'');
        Studentarr.append("R_checkval",'');
        Studentarr.append("sch_name", '');
        Studentarr.append("sch_principlename", '');
        Studentarr.append("sch_address", '');
        Studentarr.append("sch_officialemail", '');
        Studentarr.append("sch_phoneno", '');
        Studentarr.append("sch_website", '');
        Studentarr.append("sch_city", '');
        Studentarr.append("sch_logo", '');
        Studentarr.append("sch_checkval", '')

        axios.post(baseurlapi+"/SignUp.php", Studentarr).then(res => {
        
        if(res.data.success=='1')
        {
       
          var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
          sessionStorage.setItem('LoginData', JSON.stringify(obj));
          swal({title: "",text: res.data.message,icon: "success",}).then(function() {
            window.location.reload(false);
          });

        
        }
        else if(res.data.success=='2')
        {
         
          swal({title: "",text: res.data.message,icon: "error",});
        }
        else if(res.data.success=='3')
        {
          swal({title: "",text: res.data.message,icon: "error",});
        }
        else if(res.data.success=='4')
        {
          swal({title: "",text: res.data.message,icon: "error",});
        }
       
      });
       
      }
  }

  // reviewerfunction call here 
  function Reviewreg()
  {
    var R_firstname= document.getElementById('R_firstname').value;
    var R_lastname= document.getElementById('R_lastname').value;
    var R_qualification= document.getElementById('R_qualification').value;
    var R_position= document.getElementById('R_position').value;
    var R_phoneno= document.getElementById('R_phoneno').value;
    var R_email= document.getElementById('R_email').value;
    var R_password = document.getElementById("R_password").value;
    var R_checkval = document.getElementById("R_checkval");
    
      if(R_firstname=='')
      {
        swal({title: "",text: "Please fill out first name",icon: "error", });
      }
      else if(R_lastname=='')
      {
        swal({title: "",text: "Please fill out last name ",icon: "error",});
      }
      else if(R_qualification=='')
      {
        swal({title: "",text: "Please fill out qualiication ",icon: "error",});
      }
      else if(R_position=='')
      {
        swal({title: "",text: "Please fill out position",icon: "error",});
      }
      else if(R_phoneno=='')
      {
        swal({title: "",text: "Please fill out phone no",icon: "error",});
      }
      else if(R_email=='')
      {
        swal({title: "",text: "Please fill out email id",icon: "error",});
      }
      else if(R_password=='')
      {
        swal({title: "",text: "Please fill out password",icon: "error",});
      }
      else if (R_checkval.checked == false) 
      {
        swal({title: "",text: "Please Click In Check Box",icon: "error",});
      }
      else
      {
        var file = $('#R_profile')[0].files;
        const Reviewerarr = new FormData(); 
       
        Reviewerarr.append("name", '');
        Reviewerarr.append("mobileno", '');
        Reviewerarr.append("city", '');
        Reviewerarr.append("email", '');
        Reviewerarr.append("password", '');
        Reviewerarr.append("checkstatus", "");
        Reviewerarr.append("type", "R");
        Reviewerarr.append("R_firstname", R_firstname);
        Reviewerarr.append("R_lastname", R_lastname);
        Reviewerarr.append("R_qualification", R_qualification);
        Reviewerarr.append("R_position", R_position);
        Reviewerarr.append("R_phoneno", R_phoneno);
        Reviewerarr.append("R_email", R_email);
        Reviewerarr.append("R_password", R_password);
        Reviewerarr.append("R_checkval", 'Y');
        Reviewerarr.append("file", file[0])
        Reviewerarr.append("sch_name", '');
        Reviewerarr.append("sch_principlename", '');
        Reviewerarr.append("sch_address", '');
        Reviewerarr.append("sch_officialemail", '');
        Reviewerarr.append("sch_phoneno", '');
        Reviewerarr.append("sch_website", '');
        Reviewerarr.append("sch_city", '');
        Reviewerarr.append("sch_logo", '');
        Reviewerarr.append("sch_checkval", '')

        console.log(Reviewerarr)
      
        axios.post(baseurlapi+"/SignUp.php", Reviewerarr).then(res => {
        //  console.log(res.data.success)
          if(res.data.success=='1')
          {
            var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
            sessionStorage.setItem('LoginData', JSON.stringify(obj));
            swal({title: "",text: res.data.message,icon: "success",}).then(function() {
              window.location.reload(false);
            });

          }
          else if(res.data.success=='2')
          {
            swal({title: "",text: res.data.message,icon: "error",});
          }
          else if(res.data.success=='3')
          {
            swal({title: "",text: res.data.message,icon: "error",});
          } 
      });

     
    }

    
  }


  // school registration

  function Schoolreg()
  {
    var sch_name= document.getElementById('sch_name').value;
    var Sch_principlename= document.getElementById('Sch_principlename').value;
    var sch_address= document.getElementById('sch_address').value;
    var sch_officialemail= document.getElementById('sch_officialemail').value;
    var sch_phoneno= document.getElementById('sch_phoneno').value;
    var sch_website= document.getElementById('sch_website').value;
    var sch_city= document.getElementById('sch_city').value;
    var sch_checkval= document.getElementById('sch_checkval');

    if(sch_name=='')
      {
        swal({title: "",text: "Please fill out school name",icon: "error", });
      }
      else if(Sch_principlename=='')
      {
        swal({title: "",text: "Please fill out principle name ",icon: "error",});
      }
      else if(sch_address=='')
      {
        swal({title: "",text: "Please fill out school address ",icon: "error",});
      }
      else if(sch_officialemail=='')
      {
        swal({title: "",text: "Please fill out email id",icon: "error",});
      }
     
      else if(sch_website=='')
      {
        swal({title: "",text: "Please fill out school website",icon: "error",});
      }
      else if(sch_city=='')
      {
        swal({title: "",text: "Please fill out school city",icon: "error",});
      }
      else if (sch_checkval.checked == false) 
      {
        swal({title: "",text: "Please Click In Check Box",icon: "error",});
      }
      else
      {
            var file = $('#sch_logo')[0].files;
            const Schoolarr = new FormData(); 
            Schoolarr.append("name", '');
            Schoolarr.append("mobileno", '');
            Schoolarr.append("city", '');
            Schoolarr.append("email", '');
            Schoolarr.append("password", '');
            Schoolarr.append("checkstatus", '');
            Schoolarr.append("type", "SC");
            Schoolarr.append("R_firstname", '');
            Schoolarr.append("R_lastname", '');
            Schoolarr.append("R_qualification", '');
            Schoolarr.append("R_position", '');
            Schoolarr.append("R_phoneno", '');
            Schoolarr.append("R_email", '');
            Schoolarr.append("R_password", '');
            Schoolarr.append("R_checkval", '');
            Schoolarr.append("R_profile", '')
            Schoolarr.append("sch_name", sch_name);
            Schoolarr.append("sch_principlename", Sch_principlename);
            Schoolarr.append("sch_address", sch_address);
            Schoolarr.append("sch_officialemail", sch_officialemail);
            Schoolarr.append("sch_phoneno", sch_phoneno);
            Schoolarr.append("sch_website", sch_website);
            Schoolarr.append("sch_city", sch_city);
            Schoolarr.append("file",file[0]);
            Schoolarr.append("sch_checkval", 'Y')
           

            axios.post(baseurlapi+"/SignUp.php", Schoolarr)
            .then(res => {
        //       console.log(res);
        // console.log(res.data);
                if(res.data.success=='1')
                {
                  swal({title: "",text: res.data.message,icon: "success",});

                }
                else if(res.data.success=='2')
                {
                  swal({title: "",text: res.data.message,icon: "error",});
                  setmainmodel(false);
                }
                else if(res.data.success=='3')
                {
                  swal({title: "",text: res.data.message,icon: "error",});
                } 
          });

   
    }


  }

// get session value here

var LoginData = sessionStorage.getItem("LoginData");
if(LoginData ==null || LoginData=='')
{
  var userid='';
  var username='';
  var Roleid='';

}
else
{
  
   var data= JSON.parse(LoginData)
   var userid=data.userid;
   var username=data.Name;
   var Roleid =data.Role;
}


// for logout 

const logout = id => ()=>
{
     var  obj ={ userid: '', Name: '', Role: ''}
     sessionStorage.setItem('LoginData', JSON.stringify(obj));
    //  window.location.reload(false);
     navigate('');
}


const Search = () =>
{
  
   var searchval = document.getElementById('search').value;

   if(searchval=='')
   {
    swal({title: "",text: 'Please enter something?',icon: "error",});
   }else
   {
    var  navigateurl ='/search/'+searchval;
    navigate(navigateurl);
    document.getElementById("search").value = "";
    window.location.reload(false);
   }
  
}

const  handleKeyPress = (event) => {
  if(event.key === 'Enter'){
    var searchval = document.getElementById('search').value;
    
    if(searchval=='')
    {
     swal({title: "",text: 'Please enter something?',icon: "error",});
    }else
    {
     var  navigateurl ='/search/'+searchval;
     navigate(navigateurl);
     document.getElementById("search").value = "";
     window.location.reload(false);
    }
  }
}

  return (
    <nav className="navbar navbar-expand-lg bgtransparent bgcolorFFFFFF">
    <div className="container">
    {/* <a className="navbar-brand" href="/"> <img src={logo}  alt="logo" /></a> */}
    <Link  onClick={() => changeLocation(`/`)} className="navbar-brand"> <img src={logo}  alt="logo" /></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item nvbr m15 p-t-15 fw-bold">
          {/* <a className="nav-link active" aria-current="page" href="/">Home</a> */}
          <Link  onClick={() => changeLocation(`/`)}  className="nav-link active color214545 f-s-17">Home</Link>
        </li>
       
        <li className="nav-item nvbr m15 p-t-15 mainnav color214545 fw-bold"><a href="#" className="nav-link color214545 f-s-17">About</a>
          <ul className="color214545 dnone fw-bold" style={{backgroundColor:'red'}}>
            <li className="color214545 subnav fw-bold"><Link onClick={() => changeLocation(`/about`)}  className="subnava color214545">About</Link></li>
            <li className="color214545 subnav fw-bold"><Link onClick={() => changeLocation(`/career`)} className="subnava color214545">Career</Link></li>
            <li className="color214545 subnav fw-bold"><Link onClick={() => changeLocation(`/alumni`)}  className="subnava color214545">Alumni</Link></li>
            <li className="color214545 subnav fw-bold"><Link onClick={() => changeLocation(`/gallery`)} className="subnava color214545">Gallery</Link></li>
          </ul>
        </li>
        {/* <li className="nav-item nvbr m15 p-t-15"> */}
          {/* <a className="nav-link" href="/">Story</a> */}
          {/* <Link to="/readingpage" className="nav-link">Story</Link> */}
        {/* </li> */}
  <li className="nav-item nvbr m15 p-t-15 mainnav color214545 fw-bold"><a href="#" className="nav-link color214545 f-s-17">Story</a>
    <ul className="color214545 dnone" style={{backgroundColor:'red'}}>
     
      <li className="color214545 subnav "><Link to={'/storylist/0'} onMouseOver={handleHover(0)} onClick={() => changeLocation(`/storylist/0`)} className="subnava color214545" >ALL STORIES</Link>
      {/* <ul className="dnone m-l-mi-13">
          <li>
            <div className="row rowsection">
           
            {Subcat.map((subdata1,index)=>(
          <div className="col-md-3" key={index}>
          <ul className="ht-40">
        
          <li className="l-h-10"><Link to={`/storylist/${subdata1.maincatid}/${subdata1.title}`} onClick={() => changeLocation(`/storylist/${subdata1.maincatid}/${subdata1.title}`)}  className="customeanchor">{subdata1.title}</Link> </li>
          
          </ul>
        </div>
          ))}
          
       
             
            </div>
          </li>
        
        </ul> */}
        
      </li>
      

{Maincat.map((maincatdata,index)=>(
 <li className="color000 subnav " key={index}>
  {maincatdata.id==6 || maincatdata.id==7?
   null
  :<a className="subnava color214545" onMouseOver={handleHover(maincatdata.id)}>{maincatdata.title}</a>}

  {/* <button className="subnava"  onClick={Events('Events')}>{maincatdata.title}</button> */}
 <ul className="dnone m-l-mi-13" >
     <li>
       <div className="row rowsection">

     
       {Subcat2.map((subdata2,index)=>(
          <div className="col-md-3" key={index}>
          <ul className="">
            <li className=""><Link to={`/storylist/${subdata2.mid}/${subdata2.title}`} onClick={() => changeLocation(`/storylist/${subdata2.mid}/${subdata2.title}`)}  className="customeanchor">{subdata2.title}</Link> </li>
          
          </ul>
        </div>
          ))}
         
       
        
       </div>
     </li>
   
   </ul>
 </li>
))}
<li className="color214545 subnav "><Link to={`/storylist/6`} onClick={() => changeLocation(`/storylist/6`)} className="subnava color214545">Mega Story</Link></li>
<li className="color214545 subnav "><Link to={`/storylist/7`} onClick={() => changeLocation(`/storylist/7`)} className="subnava color214545">Novel</Link></li>
<li className="color214545 subnav "><Link to={'/contest/0'} onClick={() => changeLocation(`/contest/0`)}  className="subnava color214545" >Contest</Link></li>
<li className="color214545 subnav "><Link to={'/comic'} onClick={() => changeLocation(`/comic`)}  className="subnava color214545" >Comic </Link></li>

      
    </ul>
  </li>

  
        <li className="nav-item nvbr m15 p-t-15 color214545 fw-bold">
          <Link  onClick={() => changeLocation(`/activities`)} className="nav-link color214545 f-s-17">Contests</Link>
        </li>
        <li className="nav-item nvbr m15 p-t-15 color214545 fw-bold">
           <Link  onClick={() => changeLocation(`/bookstore`)} className="nav-link color214545 f-s-17">Store</Link>
        </li>
      
        <li className="nav-item m15 color214545 fw-bold">
        <div className="input-group custom-search-form">
              {/* <input type="text" className="form-control b-radius-30 bordercolor"  placeholder="Search"/> */}
            
              <div className="input-group">
              
                <button className="b-radius-30 bordercolor" type="button" onClick={Search}
                 style={{height: '36px', marginTop: '10px',paddingTop: '0px',marginLeft: '-5px',width:'40px',background:'#fff0'}}>
                  <i className="fa fa-search color214545"></i>
                </button>
                <input  type="text" id="search" className="form-control b-radius-30 bordercolor" onKeyPress={handleKeyPress} placeholder="Search.." />
              
             
            </div>
        </div>   
        </li>
        { userid==''?
          (
            <li className="nav-item  m15 p-t-15">

            <button  className="nav-link joinusbtn bgcolorD4AC28 color214545" onClick={Show}>Login/Register </button>
          </li>
          ) :
        
          <li className="nav-item  m15 p-t-15 mainnav color214545 fw-bold" style={{marginLeft: '0px'}}>
            <a href="#" className="nav-link color214545"><i className="fa fa-user  color214545" style={{fontSize:'20px'}} aria-hidden="true">

            </i>
            
          &nbsp;&nbsp;{username}</a>
          <ul className="color214545 dnone fw-bold" >
            {Roleid==4?
              <li className="color214545 subnav "><a  className="subnava color214545"  onClick={logout()}>Logout</a></li>
            :
            <span>
               <li className="color214545 subnav "><a  href={baseurl} className="subnava color214545" >Dashboard</a></li>
               <li className="color214545 subnav "><a  className="subnava color214545"  onClick={logout()}>Logout</a></li>
            </span>
            }
           
          </ul>
        </li>
        }
      </ul>
      
    </div>
  </div>

{/* sswc  term and conditions  */}

<Modal show={sswctermandcondition} onHide={SswctermandconditionClose} animation={false}  
      size="lg">
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">
                 {/* <h4 className='color214545 fw-bold'> Hi, Welcome to Stories By Children!</h4>
                 <p>Login for a smoother journey, choose your login option</p> */}
                 <div className="row pd-20">
                   <div className="col-md-12">
                    
                   <h4 className="text-center fw-bold">Stories By Children</h4>
                   <h5 className="text-center fw-bold">  Spot Story Writing Contest </h5>
                
                   <p className="fw-bold"> Date and Venue</p>
                   <p className="fw-bold">4 September 2022 | Sunday</p>
                   <p>Venue: KB School, Brahmanapally Road, <br/>
                    Turkayamjal, Hyderabad, Telangana 501510
                    </p>
                    <p>Google Maps: <a href='https://goo.gl/maps/HG2Fbg8xFPneGzGk6'>https://goo.gl/maps/HG2Fbg8xFPneGzGk6</a></p>
                    <p>Schedule</p>
                    <p>Please be present at the campus on or before 9:30 AM <br/> 
                    The event starts sharp at 10:00 AM 
                    </p>
                    <p>
                    10:00 AM - 10:15 AM - Orientation and Rules<br/>
                    10:15 AM - 10:30 AM - Segregation of Groups <br/>
                    10:45 AM - 11:45 AM -  Spot Story Writing Contest<br/>
                    11:45 AM - 12:00 PM - Snacks and Tea (Will be provided)<br/>
                    12:00 PM - 1:00 PM - Activity and Announcements<br/>
                    1:00 PM - 3:00 PM - Lunch Break <br/>
                    3:00 PM - 4:00 PM - Workshop and Games<br/>
                    4:00 PM - 5:00 PM - Results 

                    </p>
                   
                   <div className="row mt-3">
         
         
         <div className="col-md-12">
          <p className="fw-bold">Instructions</p>
          <ul className="bullets">
            
            <li >
            <div style={{display: 'flex'}}>
               <div>
               ●
                </div>
                <div className="m-l-10">
                	Registrations are to be done online on our website before 30 August 2022.
                </div>
             </div>
              
            </li><br/>

            <li >
            <div style={{display: 'flex'}}>
               <div>
               ●
                </div>
                <div className="m-l-10">
                	Only 400 slots are available. Registrations will close after that.
                </div>
             </div>
              
            </li><br/>

            <li >
            <div style={{display: 'flex'}}>
               <div>
               ●
                </div>
                <div className="m-l-10">
                Students must carry any ID proof or calendar of their school in case of verification 
                </div>
             </div>
               
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
               ●
                </div>
                <div className="m-l-10">
                Kindly be there at the campus on or before 9:30 AM to verify your registration
                </div>
             </div>
               
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
               ●
                </div>
                <div className="m-l-10">
                One parent/guardian must accompany the student for the age group 7-14 years.
                </div>
             </div>
              
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
               ●
                </div>
                <div className="m-l-10">
                Students are requested to be back on campus after lunch in time for results.
                </div>
             </div>
              
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
               ●
                </div>
                <div className="m-l-10">
              	Medals and awards will be presented to the winners on-spot after the lunch break. 
                </div>
             </div>
              
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
               ●
                </div>
                <div className="m-l-10">
              		Students can type and post their stories to be published on our website after the event.
                </div>
             </div>
              
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
               ●
                </div>
                <div className="m-l-10">
                Press coverage is expected.
                </div>
             </div>
              
            </li><br/>

           
          
          </ul>
       
         </div>
      
                  </div>
                  <div className="row mt-3">
                  <div className="col-md-12">
                    <p className="fw-bold">Lunch Break </p>
                    <ul className="bullets">
                      
                      <li >
                      <div style={{display: 'flex'}}>
                        <div>
                        ●
                          </div>
                          <div className="m-l-10">
                            For lunch, Pulav and Raita will be available at the campus at an additional cost
                          </div>
                      </div>
                        
                      </li><br/>

                      <li >
                      <div style={{display: 'flex'}}>
                        <div>
                        ●
                          </div>
                          <div className="m-l-10">
                            If interested, please opt for lunch while registering for the event (not included in the entry fee) 
                          </div>
                      </div>
                        
                      </li><br/>
                      <li >
                      <div style={{display: 'flex'}}>
                        <div>
                        ●
                          </div>
                          <div className="m-l-10">
                            Collect your tokens and pay at the registration counter if you have opted for lunch
                          </div>
                      </div>
                        
                      </li><br/>
                    </ul>
                
                  </div>
      
                  </div>
                  <div className="row mt-3">
                  <div className="col-md-12">
                    <p className="fw-bold">Things to remember for participants  </p>
                    <ul className="bullets">
                      
                      <li >
                      <div style={{display: 'flex'}}>
                        <div>
                        ●
                          </div>
                          <div className="m-l-10">
                          The story has to be written on the spot based on the topic given on campus 
                          </div>
                      </div>
                        
                      </li><br/>

                      <li >
                      <div style={{display: 'flex'}}>
                        <div>
                        ●
                          </div>
                          <div className="m-l-10">
                           15 minutes time will be given to brainstorm, get creative and gear up 
                          </div>
                      </div>
                        
                      </li><br/>
                      <li >
                      <div style={{display: 'flex'}}>
                        <div>
                        ●
                          </div>
                          <div className="m-l-10">
                          	No phones or gadgets will be allowed during the contest 
                          </div>
                      </div>
                        
                      </li><br/>
                      <li >
                      <div style={{display: 'flex'}}>
                        <div>
                        ●
                          </div>
                          <div className="m-l-10">
                          	Papers and pens/pencils will be provided on the campus 
                          </div>
                      </div>
                        
                      </li><br/>
                      <li >
                      <div style={{display: 'flex'}}>
                        <div>
                        ●
                          </div>
                          <div className="m-l-10">
                          	Students can carry other necessary stationery materials if required  
                          </div>
                      </div>
                        
                      </li><br/>
                    </ul>
                
                  </div>
      
                  </div>
                   </div>
          
                 </div>
                </div>
                </Modal.Body>
               
         </Modal>


   {/* term and condition  model here  */}

   <Modal show={termandcondition} onHide={TermandconditionClose} animation={false}  
      size="lg">
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">
                 {/* <h4 className='color214545 fw-bold'> Hi, Welcome to Stories By Children!</h4>
                 <p>Login for a smoother journey, choose your login option</p> */}
                 <div className="row pd-20">
                   <div className="col-md-12">
                    <p>
                    Stories By Children.com is platform to facilitate Children to read and/or upload literary and Art works (such as stories , poems and Painting) upload, reviews on such literary works of others the same or and communicate with the Company through mails mentioned in “contact us” on the Website/Application (<span className="fw-bold">“Services”</span>). Published Work and Company Content shall together be referred to as <span className="fw-bold">“Content”. Publisher </span> (Children (Parents of the children) who share the stories, poems and Art work for publishing)  <br/>
                    This is an electronic record under the Information Technology Act, 2000 and rules there under. Therefore, no signature is required to make the Terms of Use binding on the User. These Terms of Use along with the Privacy Policy have been formulated as required under Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011.<br/>
                    <span className="fw-bold">Publishing Guidelines:</span> To ensure the stories and poems are original work and not copied content. We are not responsible for any legal and financial issues arising because of the same.<br/>
                    <span className="fw-bold">Reproducing the Content:</span> User are informed not reproduce any Published Works of another User Content from the Website/Application and publish it in any other platform without authority.<br/>
                    <span className="fw-bold">Virus:</span> Do not upload any computer code material which contains software viruses or files which are designed to interrupt or destroy or limit the working of the any computer and additional resources used by user or to provide the Services.<br/>
                    <span className="fw-bold">Advertise:</span> Storiesbychildren.com can permit advertiser to put up advertising material in any part of the website. Publisher is not entitled for revenue sharing from the same.<br/>

                    </p>
                   <h4 className="text-center fw-bold">RIGHTS OF THE COMPANY</h4>
                   <p> User acknowledges the following rights of the Company: </p>
                   <div className="row mt-3">
         <div className="col-md-12">
          <p className="fw-bold">How we will do it: The Purpose</p>
          <ul className="bullets">
            
            <li >
            <div style={{display: 'flex'}}>
               <div>
               1.
                </div>
                <div className="m-l-10">
                <span className="fw-bold"> Content acceptance: </span>storiesbychildern.com has the right accept and to remove any Published Works which it deems objectionable or is infringing, as per its discretion or as required under law.
                </div>
             </div>
              
            </li><br/>

            <li >
            <div style={{display: 'flex'}}>
               <div>
                2.
                </div>
                <div className="m-l-10">
                <span className="fw-bold"> Charges: </span> Company may decide to charge fees for the use of the Services which shall be notified in advance to the Users.
                </div>
             </div>
              
            </li><br/>

            <li >
            <div style={{display: 'flex'}}>
               <div>
               3.
                </div>
                <div className="m-l-10">
                <span className="fw-bold">Legal disclosure:</span> Company may disclose the details of any User or any other details regarding the Published Works/Inputs or take any other action as required under law or a lawful order by government agencies who are authorized to investigate any cyber security incidents.
                </div>
             </div>
               
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
                4.
                </div>
                <div className="m-l-10">
                <span className="fw-bold">Indemnity:</span> User expressly agrees to indemnify and hold harmless the Company/Website/Application, for its use of any Published Works/Inputs provided to it or published on the Website/Application by the User. The Company reserves its right to defend itself in any such legal disputes that may arise, and recover the costs incurred in such proceedings from the User.
                </div>
             </div>
               
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
               5.
                </div>
                <div className="m-l-10">
                <span className="fw-bold">No Indirect Liability:</span> Company disclaims any and all special, incidental, indirect, consequential or punitive damages, losses costs towards any User or third party arising from the provision of Services or use of the Website/Application by others.
                </div>
             </div>
              
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
               6.
                </div>
                <div className="m-l-10">
                Arts submitted by the children can be used by the website at its discretion. The paintings are for display purpose only. 
                </div>
             </div>
              
            </li><br/>
            <li >
            <div style={{display: 'flex'}}>
               <div>
               7.
                </div>
                <div className="m-l-10">
                Information of the children are used for correspondence and display only and not shared with anybody else.  
                </div>
             </div>
              
            </li><br/>
          
          
          </ul>
       
         </div>
      
        </div>
                   </div>
          
                 </div>
                </div>
                </Modal.Body>
               
         </Modal>


    {/* popup model code here  */}

    <Modal show={mainmodel} onHide={Close} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">
                 <h4 className='color214545 fw-bold'> Hi, Welcome to Stories By Children!</h4>
                 <p>Login for a smoother journey, choose your login option</p>
                 <div className="row pd-20">
                   <div className="col-md-4">
                     <div className="bgcolorD4AC28 text-center b-r-10">
                      <div className="p-r-10 p-t-10 text-right">
                      <input className="  p-r-10" type="radio" id="joinas" name="joinas" value="1" defaultChecked={true}/>
                      </div>
                        
                        <img src={images['STUDENT.png']} className="bgFFFCF2 b-r-50-p pd-10 m-t-20" />
                        <p className="m-b-none colorfff mt-4">Login/Signup As</p>
                        <p className="fw-bold pb-20">STUDENT</p>
                     </div>
                   </div>
                   <div className="col-md-4">
                   <div className="bgcolorD4AC28 text-center b-r-10">
                   <div className="p-r-10 p-t-10 text-right">
                      <input className=" p-r-10" type="radio" id="joinas" name="joinas" value="2"  />
                      </div>
                        <img src={images['REVIEWER.png']} className="bgFFFCF2 b-r-50-p pd-10 m-t-20" />
                        <p className="m-b-none colorfff mt-4">Login/Signup As</p>
                        <p className="fw-bold pb-20">REVIEWER</p>
                     </div>
                   </div>

                   <div className="col-md-4">
                   <div className="bgcolorD4AC28 text-center b-r-10">
                   <div className="p-r-10 p-t-10 text-right">
                      <input className=" p-r-10" type="radio" id="joinas" name="joinas" value="3"  />
                      </div>
                        <img src={images['SCHOOL.png']} className="bgFFFCF2 b-r-50-p pd-10 m-t-20" />
                        <p className="m-b-none colorfff mt-4">Login/Signup As</p>
                        <p className="fw-bold pb-20">SCHOOL</p>
                     </div>
                   </div>
                   <div className="text-right">
                      <button className=" b-r-30  bgcolor214545 p-l-10 p-r-10 colorfff border-0 mt-5 " onClick={LoginShow}>NEXT</button>
                   </div>
                  
                 </div>
                </div>
                </Modal.Body>
               
         </Modal>

        {/* Login model */}



        {/* popup model for signup code here  */}

    <Modal show={loginshow} onHide={LoginClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                {studentreg && <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Student Register</h4>
                 <p className="mt-2">Already registered? <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Login}>Sign in</button></p>
                   
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name</label>
                    <input type="text" className="form-control" id="stdtname"   />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Mobile No</label>
                    <input type="tel" className="form-control" id="stdmno"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Email</label>
                    <input type="email" className="form-control" id="stdemail"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Country</label>
                    <input type="text" className="form-control" id="country"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">City</label>
                    <input type="text" className="form-control" id="stdcity"  />
                  </div>
                  
                  <div className="form-group col-md-6">
                    <label className="popuplable">Date Of Birth</label>
                    <input type="date" className="form-control" id="stdob"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">School Name</label>
                    <input type="text" className="form-control" id="schoolname"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="stdpassword" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Confirm Password</label>
                    <input type="password" className="form-control" id="stdcnfpassword"  />
                  </div>
                  <div className="form-group col-md-8">
                   
                    <input className="form-check-input mt-3" type="checkbox" id="stdcheck" value="Y" />
                    <label className="form-check-label popuplable">
                    I agree terms & conditions  
                    {/* <button onClick={TermandconditionShow} className="colorD4AC28">Read all terms & conditions</button>  */}
                    </label>
                    <button className="colorD4AC28" style={{background: 'none',border: 'none'}} onClick={TermandconditionShow}>Read all terms & conditions</button>
                    
                    <br/>
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Studentreg}>Submit</button>
                   
                    
                  </div>
                 </div>}

                {reviewerreg && <div className="row pd-20"> 
                 <h4 className=' fw-bold text-center colorD4AC28'>REVIEWER Register</h4>
                 <p className="mt-2">Already registered? <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Login}>Sign in</button></p>
                  <div className="form-group col-md-6">
                    <label className="popuplable">First Name</label>
                    <input type="text" className="form-control" id="R_firstname"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Last Name</label>
                    <input type="text" className="form-control" id="R_lastname"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Qualification</label>
                    <input type="text" className="form-control" id="R_qualification" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Present Position and Last Position held</label>
                    <input type="email" className="form-control" id="R_position"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Phone No</label>
                    <input type="tel" className="form-control" id="R_phoneno"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Email-Id</label>
                    <input type="email" className="form-control" id="R_email"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="R_password"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Upload your recent photograph</label>
                    <input type="file" className="form-control" id="R_profile"  />
                  </div>
                  <div className="form-group col-md-8">
                   
                    <input className="form-check-input mt-3" type="checkbox" id="R_checkval" value="Y" />
                    <label className="form-check-label popuplable">
                    I agree terms & conditions <a href="#" className="colorD4AC28">Read all terms & conditions</a> 
                    </label>
                    <br />
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Reviewreg}>Submit</button>
                   
                  </div>
                 </div> }

                 {schoolreg && <div className="row pd-20"> 
                 <h4 className=' fw-bold text-center colorD4AC28'>School Register</h4>
                 <p className="mt-2">Already registered? <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Login}>Sign in</button></p>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name of the school</label>
                    <input type="text" className="form-control" id="sch_name" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name of the principal</label>
                    <input type="text" className="form-control" id="Sch_principlename" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">School address</label>
                    <input type="text" className="form-control" id="sch_address" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Official Email-id</label>
                    <input type="email" className="form-control" id="sch_officialemail" />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Phone No.</label>
                    <input type="tel" className="form-control" id="sch_phoneno" />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Website address</label>
                    <input type="text" className="form-control" id="sch_website" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">City</label>
                    <input type="text" className="form-control" id="sch_city" />
                  </div>
                
                  <div className="form-group col-md-6">
                    <label className="popuplable">Upload Logo Pic.</label>
                    <input type="file" className="form-control" id="sch_logo" />
                  </div>
                
                 
                  <div className="form-group col-md-8">
                   
                    <input className="form-check-input mt-3" type="checkbox" id="sch_checkval" />
                    <label className="form-check-label popuplable">
                    I agree terms & conditions <a href="#" className="colorD4AC28">Read all terms & conditions</a> 
                    </label>
                    <br/>
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Schoolreg}>Submit</button>
                   
                  </div>
                 </div> }
                </div>
                </Modal.Body>
         </Modal>
</nav>



  )
}
