import React from "react";
import Slider from "react-slick";
import { json, Link } from 'react-router-dom';
import Moment from 'react-moment';
// import './Editorpicksection.css';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Achieverssection(props)
{
  const Achievers=props.Achievers;

  
  const arraylength=Achievers.length;
  
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    infinite: true,
    

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };
    return(
        <>
           {arraylength>0 ?(
                  <div className="container-fluid bgcolorFFFFFF pb-40">
                  <div className="container2 ">
                      <div className="row">
                      <h4 className='mb-5 mt-5 color214545 fw-bold textcenter'><img src={images['achivement.png']} alt="news"/> Our Achievers <img src={images['achivement.png']} alt="news"/></h4>
                        <div className="row textcenter mt-2">
                          <div className="col-md-1"></div>
                              <Slider {...settings} className="customeslider">
                              {Achievers.map((achiverdata,index) => (
                                <div className="col-md-2" key={index}>
                                <img src={achiverdata.filename}   style={{height: '120px',borderRadius: '50%',width:'125px'}} />
                                <p className="fw-bold mt-3">{achiverdata.Name}</p>
                                <span>Credit Points - {achiverdata.points}</span>
                                <p className="m-b-none">
                                <Moment fromNow ago>{achiverdata.DOB}</Moment>
                                </p>
                                <p className="font-15">{achiverdata.School_Name}</p>
                                </div>
                          ))}
                              
                              </Slider>
                              <div className="col-md-1"></div>
                        </div>
                      
                      </div>
                  </div>
          
                  </div>
           ):null}
    
        </>
    )
}