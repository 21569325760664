import React,{ useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import './Authorclub.css'

export default function Authorclub(props)
{
  const baseurlapi=props.baseurlapi;
  const [Authorclub1, setAuthorclub1] = useState([]);
 
  const { pathname } = useLocation();

  useEffect(() => {
    axios.get(baseurlapi+"/Authorclub.php")
    .then(response2 => {
    
      setAuthorclub1(response2.data)
     
    })
     window.scrollTo(0, 0);
  }, [pathname]);


  return(
    <>
    <div className="container-fluid p-t-20 p-b-10 bgcolorFFFFFF border-1px">
    <div className="container-fluid ">
       <div className="container2 ">
       <h4 className='mb-3  color214545 fw-bold text-center'> Young Writers Club</h4>

        <div className="row">
        <div className="col-md-12">
         <h6> Who can join?</h6>
         <h6> A writer has to fulfill any one of the following:</h6>
          <ul className="bullets">
            
            <li>
            <div style={{display: 'flex',marginLeft: '30px'}}>
               <div>1.</div>
                <div className="m-l-20">
                Any writer who has earned a minimum of 300 points under the Credit Point System will 
                become eligible to join the ‘Young Writers Club’
                </div>
                <br/>
             </div>
             <div style={{display: 'flex',marginLeft: '30px'}}>
             <div>2.</div>
                <div className="m-l-20"> Should have submitted an original novel on<span style={{marginLeft:'-10px'}}><a href="https://storiesbychildren.com/" style={{display: 'inherit',color: 'blue',background:'transparent'}} >www.storiesbychildren.com</a> </span>  </div>  
              </div>
            </li><br/>
          </ul>
          <br/>
          <h6> Benefits: Members of the club will enjoy the following benefits</h6>
          <ul className="bullets">
            
            <li>
            <div style={{display: 'flex',marginLeft: '30px'}}>
               <div>1.</div>
                <div className="m-l-20">  Free Access to E-Books.</div>
             </div>
             <div style={{display: 'flex',marginLeft: '30px'}}>
               <div>2.</div>
                <div className="m-l-20"> Will be featured on Social Media. </div>  
              </div>
              <div style={{display: 'flex',marginLeft: '30px'}}>
               <div>3.</div>
                <div className="m-l-20">Will have 30% off on all the webinars conducted by StoriesByChildren.</div>  
              </div>
              <div style={{display: 'flex',marginLeft: '30px'}}>
               <div>4.</div>
                <div className="m-l-20"> Interaction with fellow writers across the globe. </div>  
              </div>
              <div style={{display: 'flex',marginLeft: '30px'}}>
               <div>5.</div>
                <div className="m-l-20">Any other benefits as decided in due course by StoriesByChildren and intimated through the
website.</div>  
              </div>
            </li><br/>
          </ul>
       
         </div>
        </div>

        <div className="row  mt-4 ">
        
        <h4 className='mb-3  color214545 fw-bold text-center'> Members</h4>
          {Authorclub1.map((Auth1,index)=>(
            <div className="col-md-2 text-center" key={index}>
              <img src={Auth1.filename}  className="aboutprofile b-r-50" />
              <p className="color000 mt-2 f-s-13">{Auth1.Name}
              <br/>
              {Auth1.City}  
              
              {Auth1.State}
              </p> 
            </div>
          ))}
             

        </div>
        
       </div>
    </div>
    </div>
  
    </>
  )
}