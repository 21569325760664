import React,{ useState ,useEffect} from 'react';
import {useParams ,useNavigate,useLocation  } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import swal from 'sweetalert';
import './Writingcontest.css';
import { Modal, Button } from 'react-bootstrap';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Writingcontest(props)
{
    const { pathname } = useLocation();
    const baseurlapi=props.baseurlapi;
    const params = useParams();
    const id=params.id;
    var currenturl=window.location.pathname;
    const [openLoginmodel, setLogin] = useState(false);
    const [opensignupmodel, setSignup] = useState(false); 
    const [openupdatemodel, setUpdate] = useState(false); 
    const navigate = useNavigate();
    const [Maindata, setMaindata]=useState([]);
    const [Maincat, setMaincat]=useState([]);
    const [Subcat, setSubcat]=useState([]);
    const [Subcatdat, setSubcatdata]=useState([]);
    const [attendst, settendst]=useState([]);

    const [countryval, setcountryval]=useState('');
    const [cityval, setcityval]=useState('');
    const [dobval, setdobval]=useState('');
    const [schoolval, setschoolval]=useState('');
    const [profileval, setprofileval]=useState('');

        // session user check 
var LoginData = sessionStorage.getItem("LoginData");
if(LoginData ==null || LoginData=='')
{
  var userid='';
  var roleid='';
}
else
{
  //  var  obj ={ userid: '', Name: '', Role: ''}
  //  sessionStorage.setItem('LoginData', JSON.stringify(obj));
   var data= JSON.parse(LoginData)
   var userid=data.userid;
   var roleid=data.Role;
  
}

    useEffect(() => {
        axios.get(baseurlapi+'/Readingcontest.php?id='+id)
        .then(response2 => {
            setMaindata(response2.data.Maindata)
        })
        axios.get(baseurlapi+'/Enrollcontest.php?contestid='+id+'&userid='+userid+'&roleid='+roleid+'&flag=W')
        .then(res => {
          settendst(res.data.attendst)
        })

        axios.get(baseurlapi+'/Mainandsubcat.php')
        .then(response3 => {
          var temparr1=[];
            response3.data.Maincat.forEach(main => {
                var obj1 = {};
                if(main.id!=6 && main.id!=7 && main.id!=8)
                {
                    obj1['id']=main.id;
                    obj1['title']=main.title;
                    temparr1.push(obj1);
                }
            });
            setMaincat(temparr1)
            setSubcat(response3.data.Subcat)
        })

        window.scrollTo(0, 1);
    }, [pathname],[]);

    const OpenLoginmodel =  () =>
    {
     
      setLogin(true);
      setSignup(false);
      $("#loginmob").val('');
      $("#loginpassword").val('');
    }

    const LoginClose = () => setLogin(false);

    const OpenSignupmodel = () =>
    {
      setLogin(false);
      setSignup(true);
    } 
    const  SignupClose= () => setSignup(false);
    const  UpdateClose= () => setUpdate(false);

    
function filterbymaincat()
{
    var maincat = document.getElementById("maincat").value;

    var subcat2=[];
    Subcat.forEach(sub => {
        var obj = {};
        if(sub.maincatid==maincat)
        {
            obj['id']=sub.id;
            obj['title']=sub.title;
            subcat2.push(obj);
        }
    });
    setSubcatdata(subcat2)

}


const Allertfun = ()=>
{
  confirmAlert({
    title: '',
    message: 'Already You Have Done This Contest',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          navigate(`/home`,{ replace: true });
        },
      }
    ],
  });
}

// for login

const Loginuser  = () =>
{
  
 
  var loginmob= document.getElementById('loginmob').value;
  var loginpassword= document.getElementById('loginpassword').value;
  if(loginmob=='')
  {
    swal({title: "",text: "Please fill out Mobile no",icon: "error", });
  }
  else if(loginpassword=='')
  {
    swal({title: "",text: "Please fill out Password ",icon: "error",});
  }
  else
  {
    const loginarr = new FormData();
    loginarr.append("password", loginpassword);
    loginarr.append("mobileno", loginmob)

    axios.post(baseurlapi+"/Login.php", loginarr).then(res => 
      {
    if(res.data.success=='1')
    {
      var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
      sessionStorage.setItem('LoginData', JSON.stringify(obj));
      setLogin(false);
      
      if(res.data.country!=null && res.data.City!=null && res.data.Age_Group!=null && res.data.School_Name!=null && res.data.filename!='')
      {
        Publish(res.data.id,res.data.Role);
      }
      else
      {
        setcountryval(res.data.country);
        setcityval(res.data.City);
        setdobval(res.data.DOB);
        setschoolval(res.data.School_Name);
        setprofileval(res.data.filename);
        setUpdate(true);
      }
    
    //  window.location.reload(false);

    }
    else if(res.data.success=='2' || res.data.success=='3')
    {
      swal({title: "",text: res.data.message,icon: "error",});
    }
  });
  
  }
 
}

// user Signup function call here
const Usersignup = () =>
{
 
  var reviewername= document.getElementById('reviewername').value;
  var reviewermno= document.getElementById('reviewermno').value;
  var revieweremail= document.getElementById('revieweremail').value;
  var reviewerpassword= document.getElementById('reviewerpassword').value;
  var dob= document.getElementById('dob').value;
  var country= document.getElementById('country').value;
  var city= document.getElementById('city').value;
  var schoolname= document.getElementById('schoolname').value;

  

  if(reviewername=='')
  {
    swal({title: "",text: "Please fill out Name",icon: "error", });
  }
  else if(reviewermno=='')
  {
    swal({title: "",text: "Please fill out Mobile no",icon: "error",});
  }
  else if(revieweremail=='')
  {
    swal({title: "",text: "Please fill out Email",icon: "error",});
  }
  else if(country=='')
  {
    swal({title: "",text: "Please fill out country",icon: "error",});
  }
  else if(city=='')
  {
    swal({title: "",text: "Please fill out city",icon: "error",});
  }
  else if(dob=='')
  {
    swal({title: "",text: "Please fill out Dob",icon: "error",});
  }
  else if(schoolname=='')
  {
    swal({title: "",text: "Please fill out school name",icon: "error",});
  }
  else if(reviewerpassword=='')
  {
    swal({title: "",text: "Please fill out Password",icon: "error",});
  }
  else
  {
      const signuparr = new FormData();
      signuparr.append("reviewername", reviewername);
      signuparr.append("reviewermno", reviewermno)
      signuparr.append("revieweremail", revieweremail);
      signuparr.append("reviewerpassword", reviewerpassword);
      signuparr.append("country", country);
      signuparr.append("city", city);
      signuparr.append("schoolname", schoolname);
      signuparr.append("role", 2)
      signuparr.append("dob", dob)

      axios.post(baseurlapi+"/ReviewerSignup.php", signuparr).then(res => {
        console.log(res.data.success)
      if(res.data.success=='1')
      {
        var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
        sessionStorage.setItem('LoginData', JSON.stringify(obj));
       
        setSignup(false);
        Publish(res.data.id,res.data.Role);
        
        // window.location.reload(false);
      }
      else if(res.data.success=='2' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
      else if(res.data.success=='3' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
      else if(res.data.success=='4' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
     });
  }

}

// user update profile

const Userupdate = () =>
{
 
  var dob= document.getElementById('up_dob').value;
  var country= document.getElementById('up_country').value;
  var city= document.getElementById('up_city').value;
  var schoolname= document.getElementById('up_schoolname').value;

  if(country=='')
  {
    swal({title: "",text: "Please fill out country",icon: "error",});
  }
  else if(city=='')
  {
    swal({title: "",text: "Please fill out city",icon: "error",});
  }
  else if(dob=='')
  {
    swal({title: "",text: "Please fill out Dob",icon: "error",});
  }
  else if(schoolname=='')
  {
    swal({title: "",text: "Please fill out school name",icon: "error",});
  }
  else
  {
    
      var file = $('#up_profile')[0].files;

    
      const signuparr = new FormData();
      if(profileval=='')
      {
        signuparr.append("file", file[0]);
      }
      signuparr.append("country", country);
      signuparr.append("city", city);
      signuparr.append("schoolname", schoolname);
      signuparr.append("dob", dob);
      signuparr.append("userid", userid);
      signuparr.append("profileval", profileval);
      
    
      axios.post(baseurlapi+"/UpdateProfile.php", signuparr).then(res => {
        console.log(res.data.success)
      if(res.data.success=='1')
      {
        var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
        sessionStorage.setItem('LoginData', JSON.stringify(obj));
        setUpdate(false);
        Publish(res.data.id,res.data.Role);
        
        // window.location.reload(false);
      }
      else if(res.data.success=='2' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
      
     });
    
  }

}



const  Publish  = (uid,rid) =>
{

  var title= document.getElementById('title').value;
  var description= document.getElementById('description').value;
  var maincategory= document.getElementById('maincat').value;
  var subcategory= document.getElementById('subcategory').value;
  
   if(title=='')
  {
    swal({title: "",text: "Please fill out title ",icon: "error",});
  }
  else if(description=='')
  {
    swal({title: "",text: "Please fill out description ",icon: "error",});
  }
  else if(maincategory=='')
  {
    swal({title: "",text: "Please Select Main Category",icon: "error",});
  }
  else if(subcategory=='')
  {
    swal({title: "",text: "Please Select Sub Category",icon: "error",});
  }
  else
  {
      var file = $('#file')[0].files;

    if(file[0].size>1048576)
    {
      swal({title: "",text: "Please upload coverpic less than 1MB.",icon: "error",});
    }
    else
    {
      const contestarr = new FormData();
      contestarr.append("file", file[0]);
      contestarr.append("title", title);
      contestarr.append("description", description);
      contestarr.append("maincategory", maincategory);
      contestarr.append("subcategory", subcategory);
      contestarr.append("role", rid);
      contestarr.append("userid", uid);
      contestarr.append("subcontestid", id);

      const emailarr=new FormData();
      emailarr.append("userid", uid);
      emailarr.append("maincategory", maincategory);
      emailarr.append("title", title);
      
      // for (let [key, value] of contestarr) {
      //   console.log(`${key}: ${value}`)
      // }

      axios.post(baseurlapi+"/Writingcontest.php", contestarr).then(res => {
       
        if(res.data.success=='1')
        {
          axios.post(baseurlapi+"/Emailsend.php", emailarr).then(res => {
          });

          swal({title: "",text: res.data.message,icon: "success",});
          var  navigateurl ='/home';
         
           navigate(navigateurl,{ replace: true });
        }
        else if(res.data.success=='2' || res.data.success=='3')
        {
          swal({title: "",text: res.data.message,icon: "error",});
        }
        else if(res.data.success=='3' || res.data.success=='3')
        {
          swal({title: "",text: res.data.message,icon: "error",});
        }
        else if(res.data.success=='4' || res.data.success=='4')
        {
          swal({title: "",text: res.data.message,icon: "error",});
        }
     
       });
      }

  
  }

}


    return(
        <>
        <div className="container2 mt-5 mb-5">
         {Maindata.map((mdata,index)=>(
            <div className="row mb-5" key={index}>
                <div className="col-md-12">
                {/* <iframe width="100%" height="400" src="https://www.youtube.com/embed/tgbNymZ7vqY?modestbranding=1&autohide=1&showinfo=0&controls=0" frameborder="1" allowfullscreen=""> </iframe> */}
                {mdata.VideoUrl==null || mdata.VideoUrl=='' ?
                    (
                        <div ></div>
                    ) :
                     <iframe width="100%" height="400"   src={mdata.VideoUrl}  >
                     </iframe>
                }
                </div> 
            </div>
            ))}
        <div className="row">
          <div className="col-md-4">
             <div className="filetag">
              <span className="addcovertag">Add Cover</span><br />
               <img src={images['imagenotfund.png']} className="filenotfound"/>
               <span >
               <input type="file" name="file" className="fileselection" id="file" />
                </span>
             </div>
          </div>
          <div className="col-md-8">
            <div>
            <span  className="color000">Enter Title ...</span>
            <input type="text" className="form-control styletag" id="title" />
            <br/>
             <textarea id="description" className="form-control writingtextarea" name="description"  rows="4" cols="50" placeholder="Enter your story here..."></textarea>
             <br/>
            
            <div>
            <label >Main Category</label>
            <select name="maincategory" onChange={filterbymaincat} id="maincat" className="writingdropdown color000">
                <option value="">Select a Category</option>
                {Maincat.map((maincat,index)=>(
                    <option value={maincat.id} key={index} >{maincat.title}</option>
                ))}
                
            </select>
           </div> 
            <br/> 
           <div>
            <label >Sub Category </label>
            <select id="subcategory" className="writingdropdown color000">
                <option value="">Select a Subcategory</option>
                {Subcatdat.map((sub,index)=>(
                      <option value={sub.id} key={index} >{sub.title}</option>
                ))}
            </select>
           </div> 
           <br/>
           <div>

                    {userid!='' && roleid==2 ?
                    <div>
                       {attendst=='N'?
                          <button type="button" className="btn btn-dark writingbtn" onClick={() => Publish(userid, roleid)}>Publish</button>

                        : <button type="button" className="btn btn-dark writingbtn" onClick={Allertfun}>Publish</button>
                       }
                      </div>

                    :<button type="button" className="btn btn-dark writingbtn" onClick={OpenLoginmodel}>Publish</button>
                    }
                    
          
           </div>
            </div>
          </div>
        
        </div>
    </div>

    <Modal show={openLoginmodel} onHide={LoginClose} animation={false}  
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Login</h4>
                 
                  <div className="form-group col-md-12">
                    <label className="popuplable">Mobile No.</label>
                    <input type="tel" className="form-control" id="loginmob"  placeholder='Enter Mobile No.'  />
                  </div>
                
                  <div className="form-group col-md-12">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="loginpassword" placeholder='Enter Password' />
                  </div>
                  
                  <div className="form-group col-md-12">
                    
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Loginuser} >Login</button>
                    <p className="mt-2">Don't have an Account ? &nbsp;<button className="border-0  p-l-10 p-r-10 p-b-5 mt-2 b-r-5 colorfff bgcolorD4AC28"onClick={OpenSignupmodel}>Sign Up</button>
                    </p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>

    <Modal show={opensignupmodel} onHide={SignupClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Register</h4>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name</label>
                    <input type="text" className="form-control" id="reviewername"    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Mobile No</label>
                    <input type="tel" className="form-control" id="reviewermno"   />
                  </div>
                  
                  <div className="form-group col-md-6">
                    <label className="popuplable">Email</label>
                    <input type="email" className="form-control" id="revieweremail"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Country</label>
                    <input type="text" className="form-control" id="country"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">city</label>
                    <input type="text" className="form-control" id="city"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Date Of Birth</label>
                    <input type="date" className="form-control" id="dob"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">School Name</label>
                    <input type="text" className="form-control" id="schoolname"  />
                  </div>


                  <div className="form-group col-md-6">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="reviewerpassword"  />
                  </div>
                 
                  <div className="form-group col-md-8">
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Usersignup} >Submit</button>
                    <p className="mt-2">Already have an account ? <span className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={OpenLoginmodel}>Sign in</span></p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>

         {/* user update model */}
         
    <Modal show={openupdatemodel} onHide={UpdateClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Update Profile</h4>
                 
                  <div className="form-group col-md-6">
                    <label className="popuplable">Country</label>
                    <input type="text" className="form-control" id="up_country" value={countryval} />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">city</label>
                    <input type="text" className="form-control" id="up_city" value={cityval} />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Date Of Birth</label>
                    <input type="date" className="form-control" id="up_dob" value={dobval}  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">School Name</label>
                    <input type="text" className="form-control" id="up_schoolname" value={schoolval} />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Profile Pic</label>
                    <input type="file" name="file" className="" id="up_profile"  />
                    <br/>
                    <img src={profileval}  alt="Prfile not available" style={{height:'80px'}} />
                  </div>
                 
                  <div className="form-group col-md-8">
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Userupdate} >Update</button>
                   
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>

        </>
    )
}