import React,{ useState ,useEffect} from 'react';

export default function Privacypolicy()
{
    return(
        <>
    <div className="container-fluid">
            <div className="container2">
            <div className="row mt-5 mb-5" style={{minHeight: '440px'}}>
              <div className="col-md-12">
               <h1 className='mt-1 color214545 fw-bold '>Privacy Policy</h1>
                
                <div className="row mt-3">
         <div className="col-md-12">
          <h5>Last updated on Jul 23rd 2023</h5>
          <ul className="bullets">
            
            <li className="m-b-15">
             This privacy policy sets out how Stories By Children uses and protects any information that you give Stories By Children when you use this website. 
            </li>
            <li className="m-b-15">
            Stories By Children is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
            </li>
            <li className="m-b-15">Stories By Children may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</li>

            <li className="m-b-15">
                <h5>We may collect the following information:</h5>
             <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">Name and job title</div>
             </div>
             <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">Contact information including email address</div>
             </div>
             <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">Demographic information such as postcode, preferences and interests</div>
             </div>
             <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">Other information relevant to customer surveys and/or offers</div>
             </div>
               
            </li><br/>

            <li className="m-b-15">
                <h5>What we do with the information we gather</h5>
                <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
             <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">Internal record keeping.</div>
             </div>
             <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">We may use the information to improve our products and services.</div>
             </div>
             <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</div>
             </div>
             <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</div>
             </div>
             <br/>
             <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in suitable measures.</p>  
            </li><br/>

           

            
          

            <li className="m-b-15">
                <h5>How we use cookies</h5>
                <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyses web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
                <p>We use traffic log cookies to identify which pages are being used. This helps us analyses data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
                <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
                <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
            </li><br/>

            <li className="m-b-15">
                <h5>Controlling your personal information</h5>
                <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
            <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</div>
             </div>
             <div style={{display: 'flex'}}>
               <div className='bullettext'>⚫</div>
                <div className="m-l-10">if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at storiesbychildren@gmail.com</div>
             </div>
             <br/>
             <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
            <p>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</p>
            </li><br/>

          </ul>
       
         </div>
       
        </div>
                </div>
              </div>
            </div>
         </div>
        </>
    )
}