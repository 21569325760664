import React,{ useState ,useEffect} from 'react';
import { Routes, Route, Link, useParams ,useNavigate,useLocation  } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Moment from 'react-moment';
import swal from 'sweetalert';
import './Search.css';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));


export default function Search(props)
{
    const baseurlapi=props.baseurlapi;
    const { "*": param } = useParams();
    const paramArray = param.split("/");
    var searchval=paramArray[0];

    const [Story, setStory] = useState([]);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const [arrlength, setArrlength] = useState(0);
  
    useEffect(() => {
      setLoading(true);
      axios.get(baseurlapi+"/Searchstory.php?text="+searchval)
      .then(response2 => {
        setStory(response2.data.Story)
        setArrlength(response2.data.Story.length)
        setLoading(false);
        
      })
       window.scrollTo(0, 0);
    }, [pathname]);

    console.log(arrlength)

    return(
        <>
         {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        ) : (  

          
        <div className="container-fluid p-t-10 p-b-10 bgcolorFFFFFF border-1px">
        {arrlength>0 ?
            (
              <div className="container mt-3">
              <div className="row mt-5 ">
                         {Story.map((st,index)=>(
     
                               <div className="col-md-3 mb-4" key={index}>
                                             
                               <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'375px'}}>
                               <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff">{st.studentname}</span></div>
                   
                               <img src={st.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                               <p> <img src={st.profilepic} width={'100%'}  className="profilepic_story" alt="profilepic" /></p>
                               <p className="text-left mt-3 m-b-none color214545">Category :  {st.maincategory}</p>
                               <p  className="text-left m-b-none color214545">Genre :  {st.subcategory}</p>
                               <p  className="text-left m-b-none color214545">
                                   Age :  {st.AgeGroup}
                                   {/* <Moment fromNow ago>{st.DOB}</Moment> */}
                               </p>
                               {/* <p  className="text-left color214545">School :  {st.School_Name}</p> */}

                               {st.School_Name==null?(
                                <p  className="text-left color214545 f-s-14">School :  {st.School_Name}</p>
                              ): <p  className="text-left color214545 f-s-14">School :  {st.School_Name.substring(0, 20)}...</p>}
                             

                               <h6  className="text-left "><span className="fw-bold">{st.title.substring(0, 20)}...</span> </h6>
                   
                               <div className="mb-3">
                   
                                
                                     <Link to={`/storyreading/${st.id}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 
                                     <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {st.StoryViewCount}</span>
                      
                                 
                                   {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                               </div>
                               </div>
                               </div>
                         ))}
                        
                         </div>
              </div>
          ): <div className="row mt-5 ">
              <div className="col-md-12 mb-4">
              <p className='text-center'>No Record Found</p>
            </div>
            </div>
        }
        
        </div>
         )}
        
         
        </>
    )
}