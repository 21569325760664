
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/font-awesome/css/font-awesome.css';

import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Aboutus from './Components/Aboutus';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from './Components/Footer';
import Bookstore from './Components/Bookstore';
import NewAnnouncement from './Components/Newsannouncements';
import Readingchallenge from './Components/Readingchallenge';
import Gallery from './Components/Gallery';
import Ourassociation from './Components/Ourassociation';
import Career from './Components/Career';
import Storyreading from './Components/Storyreading';
import Novelreading from './Components/Novelreading';
import Writingchallenge from './Components/Writingchallenge';
import Videostory from './Components/Videostory';
import Allumni from './Components/Allumni';
import Newsreading from './Components/Newsreading';
import Storylist from './Components/Storylist';
import Readingcontest from './Components/Readingcontest'; 
import Writingcontest from './Components/Writingcontest';
import Activities from './Components/Activities';
import Search from './Components/Search';
import Writingmegalist from './Components/Writingmegalist';
import Quiz from './Components/Quiz';
import Bookread from './Components/Bookread';
import Authorclub from './Components/Authorclub';
import Contactus from './Components/Contactus';
import Allnews from './Components/Allnews';
import Conteststory from './Components/Conteststory';
import Comicmaster from './Components/Comicmaster';
import Comicreading from './Components/Comicreading';
import Faq from './Components/Faq';
import Oldcontest from './Components/Oldcontest';
import Contest from './Components/Contest';
import Readingconteststory from './Components/Readingconteststory';
import Novelconteststory from './Components/Novelconteststory';
import Contestreviewstory from './Components/Contestreviewstory';
import Reviewstory from './Components/Reviewstory';
import Reviewconteststory from './Components/Reviewconteststory';

import Privacypolicy from './Components/Privacypolicy';
import Termsconditions from './Components/Termsconditions';
import ShippingDeliveryPolicy from './Components/ShippingDeliveryPolicy';


function App() {
  const baseurl="https://storiesbychildren.com/AdminPanel";
  const baseurlapi="https://storiesbychildren.com/API";
  const socialurl='https://www.storiesbychildren.com/';
  const defaultprofilepic='https://storiesbychildren.com/AdminPanel/images/studentpic/studentlogo.png';

  // const baseurl="https://getfitwithurbanfit.in/AdminPanel";
  // const baseurlapi="https://getfitwithurbanfit.in/API";
  // const socialurl='https://www.storiesbychildren.com/';


  
  sessionStorage.setItem("offerval", '');
  
  return (
   <>
    <Navbar baseurl={baseurl} baseurlapi={baseurlapi} />
      <Routes >
        <Route path="/" element={<Home baseurl={baseurl} baseurlapi={baseurlapi} />} />
        <Route path="home" element={<Home baseurl={baseurl} baseurlapi={baseurlapi} />} />
        <Route path="about" element={<Aboutus baseurlapi={baseurlapi} />} />
        <Route path="contactus" element={<Contactus baseurlapi={baseurlapi} />} />
        <Route path="faq" element={<Faq baseurlapi={baseurlapi} />} />
        <Route path="Privacy-policy" element={<Privacypolicy baseurlapi={baseurlapi} />} />
        <Route path="Terms-And-Conditions" element={<Termsconditions baseurlapi={baseurlapi} />} />
        <Route path="Shipping-And-Delivery-Policy" element={<ShippingDeliveryPolicy baseurlapi={baseurlapi} />} />
        
        <Route path="/storylist/*"  element={<Storylist baseurlapi={baseurlapi} defaultprofilepic={defaultprofilepic} />} />
        <Route path="/conteststory/*"  element={<Conteststory baseurlapi={baseurlapi} defaultprofilepic={defaultprofilepic} />} />
        <Route path="/readingconteststory/*"  element={<Readingconteststory baseurlapi={baseurlapi} defaultprofilepic={defaultprofilepic} />} />
        <Route path="/novelconteststory/*"  element={<Novelconteststory baseurlapi={baseurlapi} defaultprofilepic={defaultprofilepic} />} />
        <Route path="/reviewconteststory/*"  element={<Reviewconteststory baseurlapi={baseurlapi} defaultprofilepic={defaultprofilepic} />} />

        
        <Route path="/Comic/*"  element={<Comicmaster baseurlapi={baseurlapi} />} />
        
        <Route path="bookstore" element={<Bookstore baseurlapi={baseurlapi} />} />
        <Route path="newsannouncement" element={<NewAnnouncement baseurlapi={baseurlapi}/>} />
        <Route path="news" element={<Allnews baseurlapi={baseurlapi}/>} />
        <Route path="/contest/:id" element={<Contest baseurl={baseurl} baseurlapi={baseurlapi} />} />
        
        <Route path="/readingchellenge/*" element={<Readingchallenge baseurl={baseurl} baseurlapi={baseurlapi} />} />
        <Route path="gallery" element={<Gallery  baseurlapi={baseurlapi} />} />
        <Route path='ourassociation' element={<Ourassociation baseurlapi={baseurlapi} />} />
        <Route path='authorclub' element={<Authorclub baseurlapi={baseurlapi} />} />
        <Route path='career' element={<Career  baseurlapi={baseurlapi} />} />
        <Route path='/storyreading/:id'   element={<Storyreading baseurlapi={baseurlapi} baseurl={baseurl} defaultprofilepic={defaultprofilepic} />} />
        <Route path='/comicreading/:id'   element={<Comicreading baseurlapi={baseurlapi} />} />
        <Route path='/novelreading/:hid' element={<Novelreading baseurlapi={baseurlapi} />} />
        <Route path='/readingcontest/:id' element={<Readingcontest baseurl={baseurl} baseurlapi={baseurlapi}  />} />
        <Route path='/oldcontest/:id' element={<Oldcontest baseurl={baseurl} baseurlapi={baseurlapi}  />} />
        <Route path='/contestreviewstory/:id' element={<Contestreviewstory baseurl={baseurl} baseurlapi={baseurlapi}  />} />
        <Route path="/reviewstory/*" element={<Reviewstory baseurl={baseurl} baseurlapi={baseurlapi}  />} />
        
        
        
        <Route path='/quiz/:id' element={<Quiz baseurlapi={baseurlapi}  />} />
        <Route path='/writingcontest/:id' element={<Writingcontest baseurlapi={baseurlapi} />} />
        <Route path='/writingmegalist/:id/:mcid' element={<Writingmegalist baseurlapi={baseurlapi} />} />
        
        <Route path='writingchallenge' element={<Writingchallenge />} />
        <Route path='videostory' element={<Videostory />} />
        <Route path='alumni' element={<Allumni  baseurlapi={baseurlapi} />} />
        {/* <Route path='/newsreading/:id/:name'  element={<Newsreading  baseurlapi={baseurlapi}/>} /> */}
        <Route path='/newsreading/:id'  element={<Newsreading  baseurlapi={baseurlapi}/>} />
        <Route path='activities'  element={<Activities  baseurlapi={baseurlapi}/>} />
        <Route path='/search/*'  element={<Search  baseurlapi={baseurlapi}/>} />
        <Route path='/bookread/:id' element={<Bookread baseurlapi={baseurlapi} />} />        
      </Routes>
     <Footer baseurl={baseurl} baseurlapi={baseurlapi} socialurl={socialurl}/> 
     <ScrollToTop smooth />
   </>
  );
}

export default App;
