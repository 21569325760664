import React,{ useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import Moment from 'react-moment';

export default function Allnews(props)
{
    const baseurlapi=props.baseurlapi;
    const [Allnews, setallnews] = useState([]);
    const[Limitnews,setLimitnews]=useState([]);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
      axios.get(baseurlapi+"/News.php?flag=A")
      .then(response2 => {
        setallnews(response2.data.AllNews)
        setLimitnews(response2.data.Limitnews)
        setLoading(false);
      })
       window.scrollTo(0, 0);
    }, [pathname]);

    // console.log(Allnews[0].id)
    return(
    <>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        ) : ( 
            <div className="cotainer-fluid p-t-20 p-b-10 ">
            <div className="container2">
               
            <div className="row">
            <h4 className='mb-5 mt-2 color214545 fw-bold'>News & Announcements</h4>
            {Allnews.map((newsdata, index) => (
            <div className="col-md-3 " key={index}>
                <div className="card mb-4 b-none bgcolorFFF8E6" style={{height:'300px'}}>
                    <img className="card-img-top " src={newsdata.filename} alt="Card image cap" style={{height:'180px'}}/>
                    <div className="card-body px-0 bgcolorFFFFFF">
                    {/* <span class="bgcolorD4AC28 p-l-10 p-r-10">{newsdata.Duration}  Read</span> */}
                    <h6 className="card-title fw-bold p-l-10 p-r-10" style={{height:'48px',fontSize:'13px'}}>{newsdata.title}</h6>
                    {/* <p className="card-text">{newsdata.Short_Dsecripation.substring(0, 100)}....</p> */}
                    {/* <a href="#" className="btn btn-dark btn-sm cardbtn b-radius-30">Enroll &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></a> */}
                    <Link  to={`/newsreading/${newsdata.id}`} className="btn btn-dark btn-sm cardbtn b-radius-30 f-s-12  m-l-10">Read Now &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></Link>
                    </div>
                </div>
            </div>
            )) }
            </div>
            </div>
           </div>
        )}
     


        </>
    )
}