import React from "react";
import Slider from "react-slick";
import { json, Link } from 'react-router-dom';
import Moment from 'react-moment';
// import './Editorpicksection.css';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Newssection(props)
{
  const news=props.news;

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };


    return(
        <>
          <div className="container-fluid  ">
          <div className="container2">         
                   <div className="row">
                   <h4 className='mb-5 mt-5 color214545 fw-bold'>News & Announcements 
                    <Link to="/news" className="floatright color214545" >View All</Link>
                   </h4>
                    <Slider {...settings} className="customeslider">
                    {news.map((newsdata, index) => (
                        <div className="col-md-3 " key={index}>
                            <div className="card mb-4 b-none bgcolorFFF8E6" style={{height:'300px',margin:'10px'}}>
                            <img className="card-img-top " src={newsdata.filename} alt="Card image cap" style={{height:'180px'}}/>
                            <div className="card-body px-0 bgcolorFFFFFF">
                            {/* <span class="bgcolorD4AC28 p-l-10 p-r-10">{newsdata.Duration}  Read</span> */}
                                <h6 className="card-title fw-bold p-l-10 p-r-10" style={{height:'48px',fontSize:'13px'}}>{newsdata.title}</h6>
                                {/* <p className="card-text">{newsdata.Short_Dsecripation.substring(0, 100)}....</p> */}
                                {/* <a href="#" className="btn btn-dark btn-sm cardbtn b-radius-30">Enroll &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></a> */}
                                <Link  to={`/newsreading/${newsdata.id}`} className="btn btn-dark btn-sm cardbtn b-radius-30 f-s-12  m-l-10">Read Now &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></Link>
                            </div>
                            </div>
                        </div>
                        )) }
                    
                    </Slider>
            </div>
            </div>

    </div>
        </>
    )
}