import React,{ useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleChevronLeft, 
  faCircleChevronRight, 
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons'

import './Gallery.css'

//  const Gallery = ({props}) => {

  export default function Gallery(props)
  {

  const baseurlapi=props.baseurlapi;
  const [galleryImages, setgallery] = useState([]);
  const [filtergallery, setfiltergallery] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    axios.get(baseurlapi+"/Gallery.php")
    .then(response2 => {
      setgallery(response2.data.Gallery)
      setfiltergallery(response2.data.Gallery)
    })
     window.scrollTo(0, 0);
  }, [pathname]);

  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( galleryImages.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === galleryImages.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1)
  }

  // array filter code here
  const Events = value => ()=>
  {
    if(value==0)
    {
      setgallery(filtergallery)
    }
    else
    {
      var galleryImages2 =[];
      for(var i=0;i<filtergallery.length;i++)
      { 
        var obj = {};
          if(filtergallery[i].Category==value)
          {
            obj['id'] =filtergallery[i].id;
            obj['filename'] =filtergallery[i].filename;
            obj['title'] =filtergallery[i].title;
            galleryImages2.push(obj);
          }
      }
      setgallery(galleryImages2)
    }
    
  }

  return (
    <div className="container2 mt-5 p-b-50">
     <h4 className='mb-5 mt-5 color214545 fw-bold'>Gallery 
     <span className="floatright">
        <button className="Gallerybtn target"  onClick={Events('0')}>All</button>
        <button className="Gallerybtn target"  onClick={Events('Events')}>Events</button>
        <button className="Gallerybtn target" onClick={Events('Contests')}>Contests</button>
        <button className="Gallerybtn target" onClick={Events('Schools')} >Schools</button>
    </span></h4>
      {openModal && 
        <div className='sliderWrap'>
          <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal}  />
          <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
          <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
          <div className='fullScreenImage'>
            <img src={galleryImages[slideNumber].filename} alt='' />
          </div>
        </div>
      }

      <div className='galleryWrap'>
        {
          galleryImages && galleryImages.map((slide, index) => {
            return(
              <div key={index}  className='single' onClick={ () => handleOpenModal(index) }>
                <img src={slide.filename} alt='' className="glimg" style={{width: '100%'}}/>
                <br/>
                <p style={{height:'2px'}}>{slide.title}</p>
                <br/>
              </div>
            )
          })
        }
      </div>

    </div>
  )
}

// export default Gallery