import React,{ useState ,useEffect} from "react";
import './Readingchallenge.css';
import { Link, useLocation,useParams,useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
import swal from 'sweetalert';
import $ from 'jquery';
import Ads from './Ads';
import Contestbannersection from './Contestbannersection';

export default function Contest(props)
{
    const { pathname } = useLocation();
    const baseurlapi=props.baseurlapi;
    const panelurl=props.baseurl;
    const baseurl=props.baseurl;
    const params = useParams();
    const mcid=params.id;

    
    const [Contest, SetContest]=useState([]);
   
    useEffect(() => {
        axios.get(baseurlapi+'/Contest.php?mcid='+mcid)
        .then(response2 => {
            SetContest(response2.data.Contest)
           
            
        })
        window.scrollTo(0, 1);
    }, [pathname],[]);

// for login


let navigate = useNavigate();

  function changeLocation(placeToGo){
      navigate(placeToGo, { replace: true });
      window.location.reload();
  }

// check array length 

const subcontestlength=Contest.length;


    return(
        <>
          <div className="container-fluid bgcolorFFFFFF minheight">

 {subcontestlength>0?(
  <div className="container2 p-b-50">
  <div className="row">
     <h4 className="mt-2 color214545 fw-bold">  Contest</h4>
   
    {Contest.map((subcst,index)=>(
     
        <div className="col-md-4 " key={index} style={{paddingLeft: '0px',paddingRight:'0px'}}>
            <div style={{margin:'30px'}} className="bgcolorFFF8E6 b-r-30">
         <img src={subcst.SubContest_Image} className="w-100  b-r-30 previouscontestimg" />
         <h4 className="mt-3 fw-bold  fw-normal p-l-10">{subcst.title}</h4>
       
        {subcst.maincatid=='1'?
            <div className="p-l-10 text-center mt-15" >
            { subcst.ContestType=='Review Story' ? 
             <Link to={`/reviewconteststory/${subcst.maincatid}/${subcst.id}`}  className="bgcolor enrollbtn t-decoration-none color000 mt-5">View Story</Link>
               
            :<Link to={`/readingconteststory/${subcst.maincatid}/${subcst.id}`}  className="bgcolor enrollbtn t-decoration-none color000 mt-5">View Story</Link>
            }
            
            
        </div> :null }

        { subcst.maincatid=='2'? 
            <div  className="p-l-10 text-center mt-15">
            <Link to={`/conteststory/${subcst.maincatid}/${subcst.id}`}  className="bgcolor enrollbtn t-decoration-none color000 mt-3">View Story</Link>
            </div>
            :null }
            
        {subcst.maincatid=='3'?
        <div  className="p-l-10 text-center mt-15">
            <Link to={`/novelconteststory/${subcst.maincatid}/${subcst.id}`}  className="bgcolor enrollbtn t-decoration-none color000 mt-3">View Story</Link>
        </div>
        :null }
        <br/>
        </div>
        </div>
       
    ))}
    </div>
   
  
</div>
):
<div className="container2 p-b-50">
  < div className="row">
<p style={{textAlign:'center'}} className="mt-5">No Previous contest</p>
</div>
</div>
}
    
        </div>
        
    
        
        </>
    )
}