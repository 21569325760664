import React,{ useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import Moment from 'react-moment';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));
 
export default function NewAnnouncement(props)
{
    const baseurlapi=props.baseurlapi;
    const [Allnews, setallnews] = useState([]);
    const[Limitnews,setLimitnews]=useState([]);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
      axios.get(baseurlapi+"/News.php?flag=A")
      .then(response2 => {
        setallnews(response2.data.AllNews)
        setLimitnews(response2.data.Limitnews)
        setLoading(false);
      })
       window.scrollTo(0, 0);
    }, [pathname]);

    // console.log(Allnews[0].id)
    return(
    <>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        ) : ( 
            <div className="cotainer-fluid p-t-20 p-b-10 bgcolorFFFFFF border-1px">
            <div className="container2">
               
                {Limitnews.map((Limitnewsdata,index)=>(
                    <div className="row" key={index}>
                    <h4 className='mb-5 mt-5 color214545 fw-bold'>News & Announcements</h4>
         
                    <div className="col-md-8">
                    <a href="#" className='bgcolor color000 text-decoration-none enrollbtn'>{Limitnewsdata.Duration} Read</a>
                    <h5 className="mt-3 fw-bold">{Limitnewsdata.title}</h5>
                    <p dangerouslySetInnerHTML={{__html: Limitnewsdata.Short_Dsecripation.substring(0, 600)  }}></p>
                    </div>
                    <div className="col-md-4 textright">
                    <p>
                    <Moment format="DD-MMM-YYYY">
                        {Limitnewsdata.entrydate}
                    </Moment>
                    </p>
                    <img src={Limitnewsdata.filename} alt="news" className="newsimg w-100"/>
                    {/* <a href="http://www.jquery2dotnet.com/" className="btn btn-dark btn-sm cardbtn b-radius-30 mt-5">View All &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i> </a>  */}
                    {/* <Link to="/newsannouncement" className="btn btn-dark btn-sm cardbtn b-radius-30 mt-5"> View All &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></Link> */}
                    <Link to={`/newsreading/${Limitnewsdata.id}`} className="colorfff floatright btn btn-dark btn-sm cardbtn b-radius-30 mt-5">
                                    
                                    Read More...
                                </Link>
                    </div>
                    </div>
                ))}
              
                
    
               
                <div className="row mt-5">
                    {Allnews.map((newsdata,index)=>(
                        <div className="col-md-4 " key={index}>
                        <div className="bgcolor214545 b-r-30 m-10">
                            <img src={newsdata.filename} className="w-100 p-t-10 p-l-10 p-r-10 b-r-30" />
                            <div className="pd-20">
                                <p>
                                <span className="bgcolorD4AC28 p-l-10 p-r-10">{newsdata.Duration} Read</span> <span className="floatright colorfff"> 
                                 <Moment format="DD-MMM-YYYY">
                                   {newsdata.entrydate}
                                </Moment>
                                </span>
                                </p>
                                <h6 className="colorfff fw-bold">{newsdata.title}</h6>
                                <p className="colorB9B9B9" dangerouslySetInnerHTML={{__html: newsdata.Short_Dsecripation.substring(0, 200)+ '...'  }}></p>
                                {/* <h6 className="colorfff floatright">Read More... </h6> */}
                                {/* <Link  to="newsreading/'"{newsdata.id}"'"  className="colorfff floatright"> */}
                                {/* <Link to={`/newsreading/${newsdata.id}/shravan`} className="colorfff floatright"> */}
                                <Link to={`/newsreading/${newsdata.id}`} className="colorfff floatright">
                                    
                                    Read More...
                                </Link>
    
                                <br/>
                                <br/>
                            </div>
                        </div>
                        </div>
                    ))}
                     
                </div>
            </div>
           </div>
        )}
     


        </>
    )
}