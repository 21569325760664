import React,{ useState ,useEffect} from 'react';
import {useParams ,useNavigate,useLocation  } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import swal from 'sweetalert';
import './Writingmegalist.css';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Writingmegalist(props)
{
    const { pathname } = useLocation();
    const baseurlapi=props.baseurlapi;
    const params = useParams();
    const id=params.id;
    const mcid=params.mcid;
    var currenturl=window.location.pathname; 
    const navigate = useNavigate();
    const [Maindata, setMaindata]=useState([]);
    const [Maincat, setMaincat]=useState([]);
    const [Subcat, setSubcat]=useState([]);
    const [Subcatdat, setSubcatdata]=useState([]);

    useEffect(() => {
        axios.get(baseurlapi+'/Readingcontest.php?id='+id)
        .then(response2 => {
            setMaindata(response2.data.Maindata)
        })
        axios.get(baseurlapi+'/Mainandsubcat.php')
        .then(response3 => {
      // filter main cate 
            var temparr1=[];
            response3.data.Maincat.forEach(main => {
                var obj1 = {};
                if(main.id==6)
                {
                    obj1['id']=main.id;
                    obj1['title']=main.title;
                    temparr1.push(obj1);
                }
            });
            setMaincat(temparr1)

            setSubcat(response3.data.Subcat)
        })

        window.scrollTo(0, 1);
    }, [pathname],[]);


    // session user check 
var LoginData = sessionStorage.getItem("LoginData");
if(LoginData ==null || LoginData=='')
{
  var userid='';
  var roleid='';
}
else
{
  //  var  obj ={ userid: '', Name: '', Role: ''}
  //  sessionStorage.setItem('LoginData', JSON.stringify(obj));
   var data= JSON.parse(LoginData)
   var userid=data.userid;
   var roleid=data.Role;
  
}


  const  Publish = val =>()=>
  {
    var coverpic= document.getElementById('coverpic').value;
    var title= document.getElementById('title').value;
    var description= document.getElementById('description').value;
    var maincategory= document.getElementById('maincat').value;
    var subcategory= document.getElementById('subcategory').value;
    
    if(coverpic=='')
    {
      swal({title: "",text: "Please select cover pic",icon: "error", });
    }
    else if(title=='')
    {
      swal({title: "",text: "Please fill title ",icon: "error",});
    }
    else if(description=='')
    {
      swal({title: "",text: "Please fill out description ",icon: "error",});
    }
    else if(maincategory=='')
    {
      swal({title: "",text: "Please Select Main Category",icon: "error",});
    }
    else if(subcategory=='')
    {
      swal({title: "",text: "Please Select Sub Category",icon: "error",});
    }
    else
    {
        const contestarr = new FormData();
        var coverpic = document.getElementById("coverpic").files;
        let file = coverpic[0];
        let reader = new FileReader();
        reader.onloadend = function() {
        contestarr.append("coverpic", reader.result);
        contestarr.append("title", title);
        contestarr.append("description", description);
        contestarr.append("maincategory", maincategory);
        contestarr.append("subcategory", subcategory);
        contestarr.append("role", roleid);
        contestarr.append("userid", userid);
        contestarr.append("subcontestid", id);
        
        // for (let [key, value] of contestarr) {
        //   console.log(`${key}: ${value}`)
        // }

        axios.post(baseurlapi+"/Writingcontest.php", contestarr).then(res => {
          if(res.data.success=='1')
          {
            swal({title: "",text: res.data.message,icon: "success",});
            var  navigateurl ='/home';
           
             navigate(navigateurl,{ replace: true });
          }
          else if(res.data.success=='2' || res.data.success=='3')
          {
            swal({title: "",text: res.data.message,icon: "error",});
          }
          else if(res.data.success=='3' || res.data.success=='3')
          {
            swal({title: "",text: res.data.message,icon: "error",});
          }
       
         });

      }
     reader.readAsDataURL(file);
    }

  }

function filterbymaincat()
{
    var maincat = document.getElementById("maincat").value;

    var subcat2=[];
    Subcat.forEach(sub => {
        var obj = {};
        if(sub.maincatid==maincat)
        {
            obj['id']=sub.id;
            obj['title']=sub.title;
            subcat2.push(obj);
        }
    });
    setSubcatdata(subcat2)

}

    return(
        <>
        <div className="container2 mt-5 mb-5">
         {Maindata.map((mdata,index)=>(
            <div className="row mb-5" key={index}>
                <div className="col-md-12">
                {/* <iframe width="100%" height="400" src="https://www.youtube.com/embed/tgbNymZ7vqY?modestbranding=1&autohide=1&showinfo=0&controls=0" frameborder="1" allowfullscreen=""> </iframe> */}
                {mdata.VideoUrl==null || mdata.VideoUrl=='' ?
                    (
                        <div ></div>
                    ) :
                     <iframe width="100%" height="400"   src={mdata.VideoUrl}  >
                     </iframe>
                }
                </div> 
            </div>
            ))}
        <div className="row">
          <div className="col-md-4">
             <div className="filetag">
              <span className="addcovertag">Add Cover</span><br />
               <img src={images['imagenotfund.png']} className="filenotfound"/>
               <span >
               <input type="file" className="fileselection" id="coverpic" />
                </span>
             </div>
          </div>
          <div className="col-md-8">
            <div>
            <span  className="color000">Enter Title ...</span>
            <input type="text" className="form-control styletag" id="title" />
            <br/>
             <textarea id="description" className="form-control writingtextarea" name="description"  rows="4" cols="50" placeholder="Description..."></textarea>
             <br/>
            
            <div>
            <label >Main Category</label>
            <select name="maincategory" onChange={filterbymaincat} id="maincat" className="writingdropdown colorB9B9B9">
                <option value="">Select a Category</option>
                {Maincat.map((maincat,index)=>(
                    <option value={maincat.id} key={index}>{maincat.title}</option>
                ))}
                
            </select>
           </div> 
            <br/> 
           <div>
            <label >Sub Category </label>
            <select id="subcategory" className="writingdropdown colorB9B9B9">
                <option value="">Select a Subcategory</option>
                {Subcatdat.map((sub,index)=>(
                      <option value={sub.id} key={index}>{sub.title}</option>
                ))}
            </select>
           </div> 
           <br/>
           <div>
           <button type="button" className="btn btn-dark writingbtn" onClick={Publish()}>Publish</button>&nbsp;&nbsp;
           <button type="button" className="btn btn-dark writingbtn">Cancel</button>
           </div>
            </div>
          </div>
        
        </div>
    </div>
        </>
    )
}