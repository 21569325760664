import React from "react";
import Slider from "react-slick";
import { json, Link } from 'react-router-dom';
import Moment from 'react-moment';
// import './Editorpicksection.css';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Bookcollectionsection(props)
{
  const book=props.book;

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };

    return(
        <>
         <div className="container-fluid ">
     <div className="container2">
      <div className="row">
          <h4 className='mb-5 mt-5 color214545 fw-bold'> Shop Our New Book Collection 
          <Link to={`/bookstore`} className="floatright color214545">View All</Link>
          </h4>
 
                    <Slider {...settings} className="customeslider">
                    {book.map((books,index) => (  
        <div className="col-md-2 " key={index}>
         <div className="card mb-4 b-none bgcolorFFF8E6 borderD4AC28 booksectionheight" style={{margin:'10px'}} >
            <img className="card-img-top bookimageheight" src={books.book_image} alt="Card image cap"  />
            <div className="card-body  pd-10">
           
               <h6 className="card-title fw-bold" style={{height:'38px',fontSize:'13px'}}>{books.title}</h6>
               <p className="card-text banner2pcolor m-b-none content" style={{height:'60px',fontSize:'13px'}}>{books.Descripation.substring(0, 40)+ '...'}</p>
               {/* <div className="content" dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div> */}
               {/* <a href="#" className="floatright banner2pcolor">Read More...</a> */}
               
               <div>
                        {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolor000000 colorfff">BUY NOW</button> */}
                        {/* <a href={books.ByLink} className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolor000000 colorfff t-decoration-none" target="__blank">Read Now</a> */}
                        <Link to={`/bookread/${books.id}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolor000000 colorfff t-decoration-non"> View</Link> 
              
                        <span className="floatright color8B752C m-b-none ">INR {books.price}</span>
                    </div>
               {/* <p className="floatright color8B752C m-b-none mt-2">INR {books.price}</p> */}
            </div>
         </div>
      </div>
      ))}
                    
                    </Slider>
                  
             
            
            </div>
        </div>

        </div>
        </>
    )
}