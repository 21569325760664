import React,{ useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import './Ourassociation.css'
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));
export default function Ourassociation(props)
{
  const baseurlapi=props.baseurlapi;
  const [AllAssoc, setAllAssoc] = useState([]);
  const [LimitAssoc, setLimitAssoc] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    axios.get(baseurlapi+"/Aurassociation.php")
    .then(response2 => {
      setAllAssoc(response2.data.AllAssoc)
      setLimitAssoc(response2.data.LimitAssoc)
    })
     window.scrollTo(0, 0);
  }, [pathname]);


  return(
    <>
    <div className="container-fluid bgcolorFFF4CE">
       <div className="container2 pt-5">
       <h4 className='mb-5  color214545 fw-bold'>Our Association </h4>
       <h6 className="text-center">Top Contributors</h6>
        <div className="row bgcolorD4AC28 text-center mt-5">
          {LimitAssoc.map((limitdata,index)=>(
            <div className="col-md-3" key={index}>
              <img src={limitdata.filename} height={140}/>
              <p className=" colorfff">{limitdata.title}</p> 
            </div>
          ))}
            

        </div>
        <h6 className="text-center mt-5">Registered Schools</h6>
        <div className="row mt-5 p-b-50">

          {AllAssoc.map((alldata,index)=>(
            <div className="col-md-3 text-center" key={index}>
            <img src={alldata.filename}  className="assocociationimg" />
            <p className="">{alldata.title}</p> 
            </div>
          ))}
         
       
        </div>

       </div>
    </div>
    </>
  )
}