import React,{ useState ,useEffect} from 'react';
import './Comicreading.css';
import { Link, useLocation,useParams } from 'react-router-dom';
import axios from "axios";
import Moment from 'react-moment';
import $ from 'jquery';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleChevronLeft, 
  faCircleChevronRight, 
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Modal, Button } from 'react-bootstrap';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Comicreading(props)
{
 
  const { pathname } = useLocation();
  const params = useParams();
  const baseurlapi=props.baseurlapi;
  const comic = params.id;

  const [loading, setLoading] = useState(false);
  const [Profiledata, setProfiledata] = useState([]);
  const [Comicdata, setComicdata] = useState([]);
  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [openLoginmodel, setLogin] = useState(false);
  const [opensignupmodel, setSignup] = useState(false);
  const [likestatus, setlikestatus] = useState('');



  const OpenLoginmodel = () => 
  {
    setLogin(true);
    setSignup(false);
    $("#loginmob").val('');
    $("#loginpassword").val('');
  }

  const LoginClose = () => setLogin(false);

  const OpenSignupmodel = () =>{
    setLogin(false);
    setSignup(true);
  } 
  const  SignupClose= () => setSignup(false);

  useEffect(() => {
        setLoading(true);
        axios.get(baseurlapi+'/Comicbyid.php?id='+comic+'&userid='+userid)
        .then(response2 => {
        setProfiledata(response2.data.profiledata)
        setComicdata(response2.data.comicdata)
        setlikestatus(response2.data.likestatus)
        setLoading(false);
        })

     window.scrollTo(0, 0);
  }, [pathname]);

  var LoginData = sessionStorage.getItem("LoginData");
  if(LoginData ==null || LoginData=='')
  {
    var userid='';
    var roleid='';
  }
  else
  {
    //  var  obj ={ userid: '', Name: '', Role: ''}
    //  sessionStorage.setItem('LoginData', JSON.stringify(obj));
     var data= JSON.parse(LoginData)
     var userid=data.userid;
     var roleid=data.Role;
    
  }
  
 
  const age2=[];
  Profiledata.forEach(element => {
    var dob = new Date(element.DOB);  
    //calculate month difference from current date in time  
    var month_diff = Date.now() - dob.getTime();  
       
    var age_dt = new Date(month_diff);   
      
    //extract year from date      
    var year = age_dt.getUTCFullYear();  
      
    //now calculate the age of the user  
    var age = Math.abs(year - 1970); 
    age2.push(Math.abs(year - 1970)) 
    
  });

  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( Comicdata.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === Comicdata.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1)
  }

  const Alertfunction = () =>
  {
    swal({title: "",text: 'It should just be already liked.',icon: "success",});

  }


  
  const Likefunction = () =>
  {
    confirmAlert({
      title: '',
      message: 'Do you want to like this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
          {
            const likearr = new FormData();
            likearr.append("userid", userid);
            likearr.append("roleid", roleid);
            likearr.append("comicid", comic);

            axios.post(baseurlapi+"/Comiclike.php", likearr).then(res => {
              if(res.data.success=='1' || res.data.success=='2')
              { 
                swal({title: "",text: res.data.message,icon: "success",}).then(function() {
                  window.location.reload(false);
                });
              }
              else if( res.data.success=='3')
              {
                swal({title: "",text: res.data.message,icon: "error",});
              }
            });
           
          } 
        },
        {
          label: 'No',
          onClick: () =>
          {
            console.log('hi')
          }
        },
      ],
    });
  }

    // for login

    const Loginuser = id => ()=>
    {
      var loginmob= document.getElementById('loginmob').value;
      var loginpassword= document.getElementById('loginpassword').value;
      if(loginmob=='')
      {
        swal({title: "",text: "Please fill out Mobile no",icon: "error", });
      }
      else if(loginpassword=='')
      {
        swal({title: "",text: "Please fill out Password ",icon: "error",});
      }
      else
      {
        const loginarr = new FormData();
        loginarr.append("password", loginpassword);
        loginarr.append("mobileno", loginmob)
  
        
        axios.post(baseurlapi+"/Login.php", loginarr).then(res => {
        if(res.data.success=='1')
        {
          var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
          sessionStorage.setItem('LoginData', JSON.stringify(obj));
         
          setLogin(false);
          window.location.reload(false);
        
    
        }
        else if(res.data.success=='2' || res.data.success=='3')
        {
          swal({title: "",text: res.data.message,icon: "error",});
        }
      });
      
      }
     
    }
  

      // user Signup function call here


  const Usersignup  = ()=>
  {
  
    var reviewername= document.getElementById('reviewername').value;
    var reviewermno= document.getElementById('reviewermno').value;
    var revieweremail= document.getElementById('revieweremail').value;
    var reviewerpassword= document.getElementById('reviewerpassword').value;

    if(reviewername=='')
    {
      swal({title: "",text: "Please fill out Name",icon: "error", });
    }
    else if(reviewermno=='')
    {
      swal({title: "",text: "Please fill out Mobile no",icon: "error",});
    }
    else if(revieweremail=='')
    {
      swal({title: "",text: "Please fill out Email",icon: "error",});
    }
    else if(reviewerpassword=='')
    {
      swal({title: "",text: "Please fill out Password",icon: "error",});
    }
    else
    {
      const signuparr = new FormData();
      signuparr.append("reviewername", reviewername);
      signuparr.append("reviewermno", reviewermno)
      signuparr.append("revieweremail", revieweremail);
      signuparr.append("reviewerpassword", reviewerpassword);
      signuparr.append("role", 4)
      
      axios.post(baseurlapi+"/ReviewerSignup.php", signuparr).then(res => {
      
      if(res.data.success=='1')
      {
        var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
        sessionStorage.setItem('LoginData', JSON.stringify(obj));
        setSignup(false);
        window.location.reload(false);
       
      }
      else if(res.data.success=='2' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
    });
    
    }
  
  }
  
    return(
        <>
         {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        ) : ( 

          <div className="cotainer-fluid p-t-20 p-b-10 bgcolorFFFFFF border-1px">
          {Profiledata.map((stdata,index)=>(
            
            <div className="container2 mt-5" key={index}>
             <div className="row mb-2">
              <div className="col-md-6 rightsection" style={{zIndex: '1',textAlign: 'right'}}>
              <img src={stdata.CoverPic} className="storyreadingimg1 b-r-30"/>
              </div>
              
               <div className="col-md-6 leftsection" >
                <div className="studentinfo bgcolor214545">
                 <p className="p-t-15"><span className="fl-r bgcolorD4AC28 p-l-10 p-r-10">By {stdata.Name}</span></p>
                 <div>
                 <img src={stdata.filename} className="studentprofile" style={{borderRadius: '50%'}}/>
                 <span className="profilesummery">
                 Age :  {age2} Year<br/>
                 School :  {stdata.School_Name}
                 </span>
                
                 </div>
                </div>
              </div>
              <h4 className="text-center fw-bold mt-3 color214545 ">{stdata.title}</h4>
             </div>
            </div>
             ))}

             <div className="container2 mt-3">
             {openModal && 
                <div className='sliderWrap'>
                <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal}  />
                <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
                <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
                <div className='fullScreenImage'>
                    <img src={Comicdata[slideNumber].Image} alt='' />
                </div>
                </div>
            }
                <div className="row">
                    {Comicdata && Comicdata.map((cmd,index)=>(
                        <div className="col-md-4" key={index} onClick={ () => handleOpenModal(index) }>
                        <img src={cmd.Image} className="m-b-10 w-100 h-300px" />
                        </div>
                    ))}
                </div>

            <div className="row">
            {Profiledata.map((stdata,index)=>(
              <div className="col-md-12">
                  <div className="mt-5 fw-bold">
                     {likestatus=='Y'?
                     <span  className="fw-bold " onClick={Alertfunction}>Like <i className="fa fa-thumbs-o-up f-s-20 " aria-hidden="true"></i> ({stdata.ComicLikeCount})</span>
                     
                     :
                     <span>
                     {userid==''?
                      <span>
                         <span  className="fw-bold like" onClick={OpenLoginmodel}>Like <i className="fa fa-thumbs-o-up f-s-20" aria-hidden="true"></i> </span>
  
                      </span>
                     : <span  className="fw-bold like" onClick={Likefunction}>Like <i className="fa fa-thumbs-o-up f-s-20" aria-hidden="true"></i> </span>
  
                     }
                     ({stdata.ComicLikeCount})
                     </span>
     
                     }
                      
                  </div>
                   
                 
              </div>
              ))}
             </div>
             
             </div>
          </div>
        )}
  <Modal show={openLoginmodel} onHide={LoginClose} animation={false}  
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Login</h4>
                 
                  <div className="form-group col-md-12">
                    <label className="popuplable">Mobile No.</label>
                    <input type="tel" className="form-control" id="loginmob"  placeholder='Enter Mobile No.'  />
                  </div>
                
                  <div className="form-group col-md-12">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="loginpassword" placeholder='Enter Password' />
                  </div>
                  
                  <div className="form-group col-md-12">
                    
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Loginuser()} >Login</button>
                    <p className="mt-2">Don't have an Account ? &nbsp;<button className="border-0  p-l-10 p-r-10 p-b-5 mt-2 b-r-5 colorfff bgcolorD4AC28"onClick={OpenSignupmodel}>Sign Up</button>
                    </p>
                  </div>
                 </div>
               
                </div>
                </Modal.Body>
         </Modal>

        <Modal show={opensignupmodel} onHide={SignupClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Register</h4>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name</label>
                    <input type="text" className="form-control" id="reviewername"    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Mobile No</label>
                    <input type="tel" className="form-control" id="reviewermno"   />
                  </div>
                  
                  <div className="form-group col-md-6">
                    <label className="popuplable">Email</label>
                    <input type="email" className="form-control" id="revieweremail"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="reviewerpassword"  />
                  </div>
                 
                  <div className="form-group col-md-8">
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Usersignup} >Submit</button>
                    <p className="mt-2">Already have an Account ? <span className="border-0  p-l-10 p-r-10 p-b-5 mt-2 b-r-5 colorfff bgcolorD4AC28" onClick={OpenLoginmodel}>Sign in</span></p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>
        </>
    )
}