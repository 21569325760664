import React from "react";
import Slider from "react-slick";
import { json, Link } from 'react-router-dom';
import Moment from 'react-moment';
import Carousel from 'react-bootstrap/Carousel';
// import './Editorpicksection.css';

export default function Contestbannersection(props)
{
  const ContestBanner=props.ContestBanner;

  const arraylength=ContestBanner.length;
  
    return(
        <>
        {arraylength>0?(
<div className='container2 mt-3 b-r-5'>
<div className="row">
<h4 className="mb-5 mt-2 color214545 fw-bold">Result Section</h4>
 <div className="col-md-1"></div>
 <div className="col-md-10 contesrcarouseldiv" >

 <Carousel>
 {ContestBanner.map((sld,index)=>(
     <Carousel.Item key={index}>
   
     <div className='row bgcolor214545 b-r-30 mb-3'  style={{padding: '10px',margin: '0px'}}>
               <div className='col-md-12'>
                <img  className="w-100 dekstopimage b-r-30 contestimg"  src={sld.ContestDekstopBanner} alt="First slide" style={{height:'365px'}}/>
                <img  className=" w-100 mobileimage b-r-30 contestimgmobile"  src={sld.ContestMobileBanner} alt="First slide" style={{height:'500px'}}/>
               </div>
       </div>
      
     </Carousel.Item>
      ))}
   </Carousel>
 
 </div>
 <div className="col-md-1"></div>
 </div>
 
  
</div>
        ):null}
    
        </>
    )
}