import React,{ useState ,useEffect} from 'react';

export default function ShippingDeliveryPolicy()
{
    return(
        <>
    <div className="container-fluid">
            <div className="container2">
            <div className="row mt-5 mb-5" style={{minHeight: '440px'}}>
              <div className="col-md-12">
               <h1 className='mt-1 color214545 fw-bold '>Shipping & Delivery Policy</h1>
                
                <div className="row mt-3">
                  <div className="col-md-12">
                    <h5>Last updated on Jul 23rd 2023</h5>
                    <ul className="bullets">
                      <li className="m-b-15">No cancellations & Refunds are entertained</li>
                      <li className="m-b-15">
                      For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 6-8 days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms. Stories By Children is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 6-8 days from the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on 8660477790 or storiesbychildren@gmail.com 
                      </li>
                    </ul>
                
                  </div>
                
                  </div>
                </div>
              </div>
            </div>
         </div>
        </>
    )
}