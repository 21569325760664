import React,{ useState ,useEffect} from 'react';
import axios from "axios";
import { Link, useLocation } from 'react-router-dom';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Allumni(props)
{
  const baseurlapi=props.baseurlapi;
  const [Allallumni, setAllallumni] = useState([]);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get(baseurlapi+"/Allumni.php")
    .then(response2 => {
      setAllallumni(response2.data.Allumni);
      setLoading(false);
    })
     window.scrollTo(0, 0);
  }, [pathname]);

  
    return(
        <>
         {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        ) : (  
          <div className="container2 mt-5 mb-5">
          <div className="text-center">
            <h4 className="text-center fw-bold ">Our Alumni - Our Pride</h4>
            <p>  We are happy to have you associated with us. We would love to see you with StoriesByChildren forever.<br/>
As an Alumni, if you are interested in Reviewing, Mentoring, Guiding your juniors, then get in touch with us at
<a style={{color: 'blue', background: 'transparent'}} href="storiesbychildren@gmail.com" target="_blank"> storiesbychildren@gmail.com</a>
 </p>
          </div>
       <div className="row mt-5">
        {Allallumni.map((Al,index)=>(

       
        <div className="col-md-2 text-center" key={index}>
          <img src={Al.filename} className="b-r-50-p aboutprofile" />
          <h6 className="card-title fw-bold mt-4">{Al.Name}</h6>
          <p className="m-b-none">{Al.School_Name}</p>
          <p className="m-b-none">{Al.country} {Al.State} {Al.City}</p>
        </div>
         ))}
       </div>
       
      </div>
        )}
          
        </>
    )
}