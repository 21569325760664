import React from "react";
import Slider from "react-slick";
import { json, Link } from 'react-router-dom';
import Moment from 'react-moment';
// import './Editorpicksection.css';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Editorpicksection(props)
{
  const Topeditor3=props.Topeditor3;

  const arraylength=Topeditor3.length;
  

    var settings = {
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay: true,
        speed: 1000,
        infinite: true,

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 4
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return(
        <>
        {arraylength>0?(
          <div className="container-fluid  ">
            <div className="container2">
              <div className="row">
                <h4 className='mb-5 mt-5 color214545 fw-bold'>Editors Pick Of The Week</h4>
                
                    <Slider {...settings} className="customeslider">
                    {Topeditor3.map((edt,index)=>(
                        <div className="col-md-3 mb-4" key={index}>
                                                        
                        <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'365px',margin:'10px'}}>
                        <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff studentname">{edt.studentname}</span></div>

                        <img src={edt.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                        <p> <img src={edt.profilepic} width={'100%'}  className="profilepic_home" alt="profilepic" /></p>
                        <p className="text-left mt-3 m-b-none color214545 f-s-14">Category :  {edt.maincategory}</p>
                        <p  className="text-left m-b-none color214545 f-s-14">Genre :  {edt.subcategory}</p>
                        <p  className="text-left m-b-none color214545 f-s-14">
                            Age : {edt.AgeGroup} 
                            {/* <Moment fromNow ago>{edt.DOB}</Moment> */}
                        </p>
                        {edt.School_Name==null?(
                                <p  className="text-left color214545 f-s-14">School :  {edt.School_Name}</p>
                              ):<p  className="text-left color214545 f-s-14">School :  {edt.School_Name.substring(0, 20)}...</p>
                        }
                             
                        
                        <h6  className="text-left f-s-14"><span className="fw-bold">{edt.title.substring(0, 30)}...</span> </h6>

                        <div className="mb-3">

                        
                            <Link to={`/storyreading/${edt.id}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 
                            {/* <span className="fl-r bg868686"><i class="fa fa-thumbs-up" aria-hidden="true"></i> {edt.StoryLikeCount}</span> */}
                            <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {edt.viewcount}</span>
                        
                            {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                        </div>
                        </div>
                        </div>
                    )) }
                    </Slider>


                            
            </div>		
            </div>
            </div>
        ):null}
    
        </>
    )
}