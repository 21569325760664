import React from "react";
import './Writingchallenge.css'

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));


export default function Writingchallenge()
{
   return (
    <>
    <div className="container2 mt-5 mb-5">
        <div className="row">
          <div className="col-md-4">
             <div className="filetag">
              <span className="addcovertag">Add Cover</span><br />
               <img src={images['imagenotfund.png']} className="filenotfound"/>
               <span >
               <input type="file" className="fileselection" />
                </span>
             </div>
          </div>
          <div className="col-md-6">
            <div>
            <span for="exampleInputPassword1" className="color000">Enter Title ...</span>
            <input type="text" className="form-control styletag"  />
            <br/>
             <textarea id="w3review" className="form-control writingtextarea" name="w3review" rows="4" cols="50" placeholder="Description..."></textarea>
             <br/>
             <span for="exampleInputPassword1" className="color000">Main Characters</span>
             <input type="text" className="form-control styletag" placeholder="Name" />
             <br/>
            <div>
            <label for="cars">Category</label>
            <select name="cars" className="writingdropdown colorB9B9B9">
                <option value="">Select a Category</option>
                <option value="saab">Saab</option>
                <option value="opel">Opel</option>
                <option value="audi">Audi</option>
            </select>
           </div> 
            <br/> 
           <div>
            <label for="cars">Age Group </label>
            <select name="cars" className="writingdropdown colorB9B9B9">
                <option value="">Select a Age</option>
                <option value="saab">Saab</option>
                <option value="opel">Opel</option>
                <option value="audi">Audi</option>
            </select>
           </div> 
           <br/>
           <div>
            <label for="cars">Language </label>
            <select name="cars" className="writingdropdown colorB9B9B9">
                <option value="">English</option>
                <option value="saab">Saab</option>
                
            </select>
           </div>
           <br/>
           <div>
           <button type="button" className="btn btn-dark writingbtn">Publish</button>&nbsp;&nbsp;
           <button type="button" className="btn btn-dark writingbtn">Cancel</button>
           </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
    </div>
    </>
   )
}