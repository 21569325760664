import React,{ useState ,useEffect} from 'react';
import { Link, useLocation,useParams } from 'react-router-dom';
import axios from "axios";
import Slider from "react-slick";

import Moment from 'react-moment';
// import './Editorpicksection.css';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Contestreviewstory(props)
{
    const params = useParams();
    const contestid=params.id;
  const baseurlapi=props.baseurlapi;
  const { pathname } = useLocation();
  const [Story1, setStory1] = useState([]);
  const [Story2, setStory2] = useState([]);
  const [Story3, setStory3] = useState([]);

  useEffect(() => {
    axios.get(baseurlapi+"/Reviewstory.php?contestid="+contestid)
    .then(response2 => {
        setStory1(response2.data.Story1)
        setStory2(response2.data.Story2)
        setStory3(response2.data.Story3)
    })

     window.scrollTo(0, 0);
  }, [pathname]);
  

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    infinite: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

      const arrayStory1=Story1.length;
      const arrayStory2=Story2.length;
      const arrayStory3=Story3.length;
  

    return(
        <>
        {arrayStory1>0?(
          <div className="container-fluid  ">
            <div className="container2">
              <div className="row">
                <h4 className='mb-5 mt-5 color214545 fw-bold'>Age 7 Year - 10 Year</h4>
                

                  {arrayStory1>3 ?
                       <Slider {...settings} className="customeslider">
                       {Story1.map((edt,index)=>(
                           <div className="col-md-3 mb-4" key={index}>
                                                           
                           <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'365px',margin:'10px'}}>
                           <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff studentname">{edt.studentname}</span></div>
   
                           <img src={edt.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                           <p> <img src={edt.profilepic} width={'100%'}  className="profilepic_home" alt="profilepic" /></p>
                           <p className="text-left mt-3 m-b-none color214545 f-s-14">Category :  {edt.maincategory}</p>
                           <p  className="text-left m-b-none color214545 f-s-14">Genre :  {edt.subcategory}</p>
                           <p  className="text-left m-b-none color214545 f-s-14">
                               Age : {edt.AgeGroup} 
                               {/* <Moment fromNow ago>{edt.DOB}</Moment> */}
                           </p>
                           {edt.School_Name==null?(
                                <p  className="text-left color214545 f-s-14">School :  {edt.School_Name}</p>
                              ):<p  className="text-left color214545 f-s-14">School :  {edt.School_Name.substring(0, 20)}...</p>
                        }
                           <h6  className="text-left f-s-14"><span className="fw-bold">{edt.title.substring(0, 25)}...</span> </h6>
   
                           <div className="mb-3">
   
                           
                               <Link to={`/reviewstory/${edt.id}/${contestid}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 
                               {/* <span className="fl-r bg868686"><i class="fa fa-thumbs-up" aria-hidden="true"></i> {edt.StoryLikeCount}</span> */}
                               <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {edt.StoryViewCount}</span>
                           
                               {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                           </div>
                           </div>
                           </div>
                       )) }
                       </Slider>
                     :
                     <div className="row contestuserrow"> 
                     {Story1.map((edt,index)=>(
                      <div className="col-md-3 mb-4" key={index}>
                                                      
                      <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'365px',margin:'10px'}}>
                      <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff studentname">{edt.studentname}</span></div>

                      <img src={edt.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                      <p> <img src={edt.profilepic} width={'100%'}  className="profilepic_home" alt="profilepic" /></p>
                      <p className="text-left mt-3 m-b-none color214545 f-s-14">Category :  {edt.maincategory}</p>
                      <p  className="text-left m-b-none color214545 f-s-14">Genre :  {edt.subcategory}</p>
                      <p  className="text-left m-b-none color214545 f-s-14">
                          Age : {edt.AgeGroup} 
                          {/* <Moment fromNow ago>{edt.DOB}</Moment> */}
                      </p>
                      {edt.School_Name==null?(
                                <p  className="text-left color214545 f-s-14">School :  {edt.School_Name}</p>
                              ):<p  className="text-left color214545 f-s-14">School :  {edt.School_Name.substring(0, 20)}...</p>
                        }
                      <h6  className="text-left f-s-14"><span className="fw-bold">{edt.title.substring(0, 25)}...</span> </h6>

                      <div className="mb-3">

                      
                          <Link to={`/reviewstory/${edt.id}/${contestid}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 
                          {/* <span className="fl-r bg868686"><i class="fa fa-thumbs-up" aria-hidden="true"></i> {edt.StoryLikeCount}</span> */}
                          <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {edt.StoryViewCount}</span>
                      
                          {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                      </div>
                      </div>
                      </div>
                      )) }
                     </div>
                     }

                            
            </div>		
            </div>
            <hr/>
            </div>
            
        ):null}
      

        {arrayStory2>0?(
          
          <div className="container-fluid  ">
            
            <div className="container2">
              <div className="row">
                <h4 className='mb-5 mt-2 color214545 fw-bold'>Age 11 Year - 14 Year</h4>
                
                    {arrayStory2>3 ?
                       <Slider {...settings} className="customeslider">
                       {Story2.map((edt,index)=>(
                           <div className="col-md-3 mb-4" key={index}>
                                                           
                           <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'365px',margin:'10px'}}>
                           <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff studentname">{edt.studentname}</span></div>
   
                           <img src={edt.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                           <p> <img src={edt.profilepic} width={'100%'}  className="profilepic_home" alt="profilepic" /></p>
                           <p className="text-left mt-3 m-b-none color214545 f-s-14">Category :  {edt.maincategory}</p>
                           <p  className="text-left m-b-none color214545 f-s-14">Genre :  {edt.subcategory}</p>
                           <p  className="text-left m-b-none color214545 f-s-14">
                               Age : {edt.AgeGroup} 
                               {/* <Moment fromNow ago>{edt.DOB}</Moment> */}
                           </p>
                           {edt.School_Name==null?(
                                <p  className="text-left color214545 f-s-14">School :  {edt.School_Name}</p>
                              ):<p  className="text-left color214545 f-s-14">School :  {edt.School_Name.substring(0, 20)}...</p>
                        }
                           <h6  className="text-left f-s-14"><span className="fw-bold">{edt.title.substring(0, 25)}...</span> </h6>
   
                           <div className="mb-3">
   
                           
                               <Link to={`/reviewstory/${edt.id}/${contestid}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 
                               {/* <span className="fl-r bg868686"><i class="fa fa-thumbs-up" aria-hidden="true"></i> {edt.StoryLikeCount}</span> */}
                               <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {edt.viewcount}</span>
                           
                               {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                           </div>
                           </div>
                           </div>
                       )) }
                       </Slider>
                     :
                     <div className="row contestuserrow"> 
                     {Story2.map((edt,index)=>(
                      <div className="col-md-3 mb-4" key={index}>
                                                      
                      <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'365px',margin:'10px'}}>
                      <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff studentname">{edt.studentname}</span></div>

                      <img src={edt.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                      <p> <img src={edt.profilepic} width={'100%'}  className="profilepic_home" alt="profilepic" /></p>
                      <p className="text-left mt-3 m-b-none color214545 f-s-14">Category :  {edt.maincategory}</p>
                      <p  className="text-left m-b-none color214545 f-s-14">Genre :  {edt.subcategory}</p>
                      <p  className="text-left m-b-none color214545 f-s-14">
                          Age : {edt.AgeGroup} 
                          {/* <Moment fromNow ago>{edt.DOB}</Moment> */}
                      </p>
                     
                     
                      {edt.School_Name==null?(
                                <p  className="text-left color214545 f-s-14">School :  {edt.School_Name}</p>
                              ):<p  className="text-left color214545 f-s-14">School :  {edt.School_Name.substring(0, 20)}...</p>
                        }
                     
                      <h6  className="text-left f-s-14"><span className="fw-bold">{edt.title.substring(0, 25)}...</span> </h6>

                      <div className="mb-3">

                      
                          <Link to={`/reviewstory/${edt.id}/${contestid}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 
                          {/* <span className="fl-r bg868686"><i class="fa fa-thumbs-up" aria-hidden="true"></i> {edt.StoryLikeCount}</span> */}
                          <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {edt.StoryViewCount}</span>
                      
                          {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                      </div>
                      </div>
                      </div>
                  )) }
                     </div>
                     }
                    


                            
            </div>		
            </div>
            </div>
        ):null}

 {arrayStory3>0?(
          <div className="container-fluid  ">
            <hr/>
            <div className="container2">
              <div className="row">
                <h4 className='mb-5 mt-2 color214545 fw-bold'>Age 15 Year - 18 Year</h4>
                
                {arrayStory3>3 ?
                       <Slider {...settings} className="customeslider">
                       {Story3.map((edt,index)=>(
                           <div className="col-md-3 mb-4" key={index}>
                                                           
                           <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'365px',margin:'10px'}}>
                           <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff studentname">{edt.studentname}</span></div>
   
                           <img src={edt.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                           <p> <img src={edt.profilepic} width={'100%'}  className="profilepic_home" alt="profilepic" /></p>
                           <p className="text-left mt-3 m-b-none color214545 f-s-14">Category :  {edt.maincategory}</p>
                           <p  className="text-left m-b-none color214545 f-s-14">Genre :  {edt.subcategory}</p>
                           <p  className="text-left m-b-none color214545 f-s-14">
                               Age : {edt.AgeGroup} 
                               {/* <Moment fromNow ago>{edt.DOB}</Moment> */}
                           </p>
                           {edt.School_Name==null?(
                                <p  className="text-left color214545 f-s-14">School :  {edt.School_Name}</p>
                              ):<p  className="text-left color214545 f-s-14">School :  {edt.School_Name.substring(0, 20)}...</p>
                        }
                           <h6  className="text-left f-s-14"><span className="fw-bold">{edt.title.substring(0, 25)}...</span> </h6>
   
                           <div className="mb-3">
   
                           
                               <Link to={`/reviewstory/${edt.id}/${contestid}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 
                               {/* <span className="fl-r bg868686"><i class="fa fa-thumbs-up" aria-hidden="true"></i> {edt.StoryLikeCount}</span> */}
                               <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {edt.viewcount}</span>
                           
                               {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                           </div>
                           </div>
                           </div>
                       )) }
                       </Slider>
                     :
                     <div className="row contestuserrow"> 
                     {Story3.map((edt,index)=>(
                      <div className="col-md-3 mb-4" key={index}>
                                                      
                      <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'365px',margin:'10px'}}>
                      <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff studentname">{edt.studentname}</span></div>

                      <img src={edt.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                      <p> <img src={edt.profilepic} width={'100%'}  className="profilepic_home" alt="profilepic" /></p>
                      <p className="text-left mt-3 m-b-none color214545 f-s-14">Category :  {edt.maincategory}</p>
                      <p  className="text-left m-b-none color214545 f-s-14">Genre :  {edt.subcategory}</p>
                      <p  className="text-left m-b-none color214545 f-s-14">
                          Age : {edt.AgeGroup} 
                          {/* <Moment fromNow ago>{edt.DOB}</Moment> */}
                      </p>
                      {edt.School_Name==null?(
                                <p  className="text-left color214545 f-s-14">School :  {edt.School_Name}</p>
                              ):<p  className="text-left color214545 f-s-14">School :  {edt.School_Name.substring(0, 20)}...</p>
                        }
                      <h6  className="text-left f-s-14"><span className="fw-bold">{edt.title.substring(0, 25)}...</span> </h6>

                      <div className="mb-3">

                      
                          <Link to={`/reviewstory/${edt.id}/${contestid}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 
                          {/* <span className="fl-r bg868686"><i class="fa fa-thumbs-up" aria-hidden="true"></i> {edt.StoryLikeCount}</span> */}
                          <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {edt.viewcount}</span>
                      
                          {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                      </div>
                      </div>
                      </div>
                  )) }
                     </div>
                     }
          
            </div>		
            </div>
            </div>
        ):null}
    
        </>
    )
}