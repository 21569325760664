import React,{ useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import './Contactus.css';
import swal from 'sweetalert';


export default function Faq()
{
    return(
        <>
  <div className="container-fluid">
            <div className="container2">
            <div className="row mt-5 mb-5" style={{minHeight: '440px'}}>
              <div className="col-md-12">
               <h1 className='mt-1 color214545 fw-bold '>FAQ’s</h1>
                
                <div className="row mt-3">
         <div className="col-md-12">
          <p>Here are some Frequently Asked Questions answered for you.</p>
          <ul className="bullets">
            
            <li className="m-b-15">
            <div style={{display: 'flex'}}>
               <div>
                1
                </div>
                <div className="m-l-10">
                Who can submit stories/poems on the website?<br/>
                - Any kid in the age group of 7-18 years can submit their stories/poems/articles.
                </div>
             </div>
              
            </li><br/>

            <li className="m-b-15">
            <div style={{display: 'flex'}}>
               <div>
               2
                </div>
                <div className="m-l-10">
                Do we have to login to write?<br/>
                - Yes, you to create your account to submit stories/poems. This helps you getting all the details
                about your story like-No of stories submitted/approved/rejected, status, Credit Points etc. The
                login process is very easy and takes just 2 minutes.
                </div>
             </div>
               
            </li><br/>

            <li className="m-b-15 w-100">
            <div style={{display: 'flex'}}>
               <div>
               3
                </div>
                <div className="m-l-10">
                  Do I have to login to read the stories? <br/>                                   
                - No, you don't have to login to read the stories/poems.
                </div>
             </div>
               
            </li><br/>

            <li className="m-b-15"> 
            <div style={{display: 'flex'}}>
               <div>
                4
                </div>
                <div className="m-l-10">
                How do I login?<br/>
                - Click on Login/Register on Menu Bar<br/>
                - Select the option – Student<br/>
                - Enter your details.<br/>
                - Your phone number and the password you entered will be your Login details.<br/>
                - Login to your account<br/>
                - Update your profile.<br/>
                - Start submitting your story/poem etc.
                </div>
             </div>
              
            </li><br/>
            <li className="m-b-15">
            <div style={{display: 'flex'}}>
               <div>
              5
                </div>
                <div className="m-l-10">
                Will my story/poem be immediately published on the website?<br/>
                - After you submit your work, your story/poem/article etc will be assigned to a Reviewer. ( You
                can view our Reviewers in About us section). The Reviewer will check for duplication ( plagiarism
                ), grammatical mistakes and content. The Reviewer will then approve or reject the work based
                on content and conditions. ( are available as Instructions while submitting your work ). Only
                when the Reviewer approves the story it is published on the website.
                </div>
             </div>
            
            </li><br/>

            <li className="m-b-15">
            <div style={{display: 'flex'}}>
               <div>
              6
                </div>
                <div className="m-l-10">
                Will I be notified of the approval?<br/>
                - Yes, a mail will be sent to your registered mail id on submission/approval/rejection. Please check
                your mail.
                </div>
             </div>
            
            </li><br/>

            <li className="m-b-15">
            <div style={{display: 'flex'}}>
               <div>
              7
                </div>
                <div className="m-l-10">
                How long does it take to approve/reject?<br/>
                - This cannot be said with certainty. 4-5 business days is usual time taken. Sometimes it might
                take a little longer as the Reviewers may be busy.
                </div>
             </div>
            
            </li><br/>

            <li className="m-b-15">
            <div style={{display: 'flex'}}>
               <div>
              8
                </div>
                <div className="m-l-10">
                Is there any word limit?<br/>
                - Only certain contests may have word limits. ( will be notified ). Otherwise, this platform
                supports Short stories, Long stories, Novels, Mega Story etc.
                For short stories limit your words to 2000.
                For long stories ( if it exceeds 2000 words) submit them in parts.
                For Novel and Mega story, we will notify the word limit during the contest.
                </div>
             </div>
            
            </li><br/>

            <li className="m-b-15">
            <div style={{display: 'flex'}}>
               <div>
              9
                </div>
                <div className="m-l-10">
                Do we have to pay any fees?<br/>
                - No, its absolutely free. You can submit any number of stories/poems anytime without worrying
                about charges. Even the online contests are free.
                For Offline contest, there may be some charges.
                </div>
             </div>
            
            </li><br/>
          
          
          </ul>
       
         </div>
       
        </div>
                </div>
              </div>
            </div>
         </div>
        </>
    )
}