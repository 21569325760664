import React,{ useState ,useEffect} from 'react';
import { Link, useLocation,useParams } from 'react-router-dom';
import axios from "axios";
import Moment from 'react-moment';

import './Newsreading.css'
export default function Newsreading(props)
{
    const params = useParams();

    const id=params.id;
    const name=params.name;

    const baseurlapi=props.baseurlapi;
    const [Newsbyid, setNewsbyid] = useState([]);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
      axios.get(baseurlapi+"/News.php?flag=B&id="+id)
      .then(response2 => {
        setNewsbyid(response2.data.Newsbyid);
        setLoading(false);
      })
       window.scrollTo(0, 0);
    }, [pathname]);

  // console.log(Newsbyid); // 👉️ {userId: '4200'}

    return(
        <>
         {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        ) : ( 
          <div className="cotainer-fluid p-t-20 p-b-10 bgcolorFFFFFF border-1px">
          <div className="container2 ">
          <div className="row mt-5">
              {Newsbyid.map((news,index)=>(
                  <div className="col-md-12" key={index}>
                  <img src={news.DetailImage} className="newsmainimg" width="100%"/>
                  <h4 className="text-center fw-bold mt-5">{news.title}</h4>

                  <div className="mt-2 paragraph-text " dangerouslySetInnerHTML={{__html: news.Short_Dsecripation }}>

                  </div>
                  <div className="mt-2 paragraph-text " dangerouslySetInnerHTML={{__html: news.Long_Descripation }}>

                 </div>
                  {/* <p  className="mt-2 paragraph-text " dangerouslySetInnerHTML={{__html: news.Short_Dsecripation }}></p> */}
                  {/* <p  className="mt-2 paragraph-text " dangerouslySetInnerHTML={{__html: news.Long_Descripation }}></p> */}
                 
                  {/* <div className="mt-5">
                  share &nbsp;<i className="fa fa-facebook-square socialicon" aria-hidden="true"></i>&nbsp;
                      <i className="fa fa-instagram socialicon" aria-hidden="true"> </i>&nbsp;
                      <i className="fa fa-whatsapp socialicon" aria-hidden="true"></i>&nbsp;
                      <i className="fa fa-envelope socialicon" aria-hidden="true"></i>
                      
                      <span style={{paddingLeft:'50px'}}>like <i className="fa fa-thumbs-o-up f-s-20" aria-hidden="true"></i></span>
                  <span className="fl-r">
                  <span className="color000">Rate:</span>  <span className="fa fa-star checked"></span><span className="fa fa-star checked"></span><span className="fa fa-star checked"></span><span className="fa fa-star checked"></span><span className="fa fa-star checked"></span>
  
                  </span>
                      
                  </div><br/>
                  
  
                  <span htmlFor="exampleFormControlTextarea1" className="form-label">Comment</span><br/><br/>
                  <textarea  id="exampleFormControlTextarea1" placeholder="Share your thought..." rows="2" cols="50" style={{width:'100%'}} ></textarea>
  
                  <button type="button " className="btn btn-dark mt-2 mb-5">Share</button> */}
  
                  </div>
              ))}
             
             </div>
          </div>
          </div>
        )}
      
        </>
    )
}