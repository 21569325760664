import React,{ useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import './Footer.css';
export default function Footer(props)
{
  const panelurl=props.baseurl;
  const baseurlapi=props.baseurlapi;
  const socialurl=props.socialurl;

  const [Homecount, setHomecount] = useState('');

  useEffect(() => {
    axios.get(baseurlapi+"/Footer.php")
    .then(response2 => {
      setHomecount(response2.data.Homecount)
    })
     window.scrollTo(0, 0);
  });
    return(
        <>
           <div className="container-fluid bgD4AC28">
            
            <div className="fixed-plugin bgcolor214545 colorfff" style={{backgroundColor: 'black'}}>
                <a href={panelurl} target="_blank" className="t-decoration-none ">
                    
                    <b style={{color: 'white', fontSize: '0.7em'}}>Submit Story</b>
                </a>
            </div>
             <div className="row ">
              <div className="col-md-2">
                <p style={{marginTop: '84px'}} className="color214545">Visits : {Homecount} </p>
              </div>
              <div className="col-md-8 text-center">
                <div className="flex-gap">
                  <div className="fw-bold"><Link to="/about" className="color214545 text-decoration-none  footertext">About Us</Link> </div>
                  <div className="fw-bold"><Link to="/faq" className="color214545 text-decoration-none footertext" >FAQ’s</Link> </div>
                  <div className=" fw-bold">
                    {/* <a href="#" className="color214545 text-decoration-none">Career</a> */}
                    <Link to="/career" className="color214545 text-decoration-none footertext">Career</Link>
                  </div>
                  <div className="fw-bold"> <Link to="/contactus" className="color214545 text-decoration-none footertext">Contact Us</Link></div>
                  <div className="fw-bold"> <Link to="/Privacy-policy" className="color214545 text-decoration-none footertext">Privacy Policy</Link></div>
                  <div className="fw-bold"> <Link to="/Terms-And-Conditions" className="color214545 text-decoration-none footertext"> Terms & Conditions</Link></div>
                  <div className="fw-bold"> <Link to="/Shipping-And-Delivery-Policy" className="color214545 text-decoration-none footertext"> Shipping & Delivery Policy</Link></div>
                
                  
                 </div>
                <div className="mt-2 text-center">
                <a href="http://www.facebook.com/storiesbychildren" target="_blank"><i className="fa fa-facebook-square facebookcolor font-25" aria-hidden="true"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="http://www.instagram.com/storiesbychildren" target="_blank"><i className="fa fa-instagram instagramcolor font-25" aria-hidden="true"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://twitter.com/the_sbc_tweets" target="_blank"><i className="fa fa-twitter twettercolor font-25" aria-hidden="true"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                {/* <a href='https://www.youtube.com/watch?v=RgNKt0H0NkI' target="_blank"><i className="fa fa-youtube-play youtubecolor font-25" aria-hidden="true"></i></a>&nbsp;&nbsp;&nbsp;&nbsp; */}
                <a href="https://www.linkedin.com/company/stories-by-children/" target="_blank"><i className="fa fa-linkedin linkedIncolor font-25" aria-hidden="true"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                
              
                <p className="mt-2  color214545"> © 2022 LiaisonIT, Inc. All rights reserved.</p>
                </div>
              </div>
              <div className="col-md-2"></div>
             </div>
           </div>
        </>
    )
}