import React,{ useState ,useEffect} from 'react';
import './Home.css';
import Moment from 'react-moment';
import { json, Link,useLocation,useNavigate } from 'react-router-dom';
import Homegallery from './Homegallery';
import swal from 'sweetalert';
import axios from "axios";
// import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Ads from './Ads';
import Editorpicksection from './Editorpicksection';
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import Carousel from 'react-bootstrap/Carousel';
import Newssection from './Newssection';
import Achieverssection from './Achieverssection';
import Associationsection from './Associationsection';
import Bookcollectionsection from './Bookcollectionsection';
import Instagramsection from './Instagramsection';
import Topwritersection from './Topwritersection';
import Contestbannersection from './Contestbannersection';



function importAll(r) {
    let images = {};
    r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));
 

export default function Home(props) {
  let navigate = useNavigate();
  const baseurl=props.baseurl;
  const baseurlapi=props.baseurlapi;
  const panelurl=props.baseurl;
  const [news, setnews] = useState([]);
  const [author1, setauthor1] = useState([]);
  
  
  const [gallery, setgallery] = useState([]);
  const [association, setassociation] = useState([]);
  const [book, setbook] = useState([]);
  const [youtube, setyoutub] = useState([]);
  const [ads, setads] = useState([]);
  const [contest, setcontest] = useState([]);
  const [Topeditor3, setTopeditor3] = useState([]);
  const [Achievers, setAchievers] = useState([]);
  const [Topwriter, setTopwriter] = useState([]);
  const [Subcontest, setSubcontest] = useState([]);
  const [Offers, setOffers] = useState([]);
  const [Slider, setSlider] = useState([]);
  const [Instafeed, setInstafeed] = useState([]);
  const [Homecount, setHomecount] = useState('');

  
  
  const [openLoginmodel, setLogin] = useState(false);
  const [opensignupmodel, setSignup] = useState(false);
  const [offermodel, setOffersmodel] = useState(false);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [subcontestid, setsubcontestid] = useState('');
  const [maincontestid, setmaincontestid] = useState('');
  
  var offerval = sessionStorage.getItem("offerval");

  const OpenLoginmodel = val => (e) => 
  {
    let maincontestid = e.target.getAttribute("data-maincontestid")
    
    setsubcontestid(val)
    setmaincontestid(maincontestid)
    setLogin(true);
    setSignup(false);
    $("#loginmob").val('');
    $("#loginpassword").val('');
  }

  const LoginClose = () => setLogin(false);

  const OpenSignupmodel = () =>
  {
    setLogin(false);
    setSignup(true);
  } 
  const  SignupClose= () => setSignup(false);
  const OffersClose=()=>
  {
    setOffersmodel(false);
   
  }
  // session user check 
var LoginData = sessionStorage.getItem("LoginData");

if(LoginData ==null || LoginData=='')
{
  var userid='';
  var roleid='';
}
else
{
   var data= JSON.parse(LoginData)
   var userid=data.userid;
   var roleid=data.Role;
  
}

const [openinstructionmodel, setInstruction] = useState(false);
const [instructiondata, setinstructiondata] = useState('');
const [Conteststatus, SetConteststatus] = useState([]);
const [ContestBanner, SetContestBanner] = useState([]);


  
  useEffect(() => { 
    var userid = sessionStorage.getItem("userid");
       setLoading(true);
        axios.get(baseurlapi+"/Home.php?type=H")
        .then(response => {
          setnews(response.data.News)
          setgallery(response.data.Gallery)
          setyoutub(response.data.Youtube)
          setassociation(response.data.Association)
          setbook(response.data.Book)
          setauthor1(response.data.Authorclub1)
          setInstafeed(response.data.Instafeed)
          setLoading(false);
          setTopwriter(response.data.Topwriter)
          setcontest(response.data.Contests)
          setTopeditor3(response.data.Topeditor3)
          setAchievers(response.data.Achievers)
          setSubcontest(response.data.Subcontest)
          setOffers(response.data.Offers)
          setSlider(response.data.Slider)
          SetContestBanner(response.data.ContestBanner)
          setHomecount(response.data.Homecount)

          if(response.data.Offers!='' && offerval=='')
          {
            setOffersmodel(true);
            sessionStorage.setItem("offerval", 1);
          }
          
        })
        axios.get(baseurlapi+"/Ads.php?type=Home")
        .then(response2 => {
               setads(response2.data.Ads)
        })

        axios.get(baseurlapi+'/Checkconteststatus.php?userid='+userid+'&flag=1')
        .then(response3 => {
            SetConteststatus(response3.data.Conteststatus)
        })
  
        window.scrollTo(0, 0);
}, [pathname]);



const Readinstruction = val=> ()=>
{
 
  setinstructiondata(val)
  setInstruction(true);
}
const InstructionClose = ()=> setInstruction(false);

// for login

const Loginuser = id => ()=>
{
  var subid=subcontestid;
  var maincatid=maincontestid;
  var loginmob= document.getElementById('loginmob').value;
  var loginpassword= document.getElementById('loginpassword').value;
  if(loginmob=='')
  {
    swal({title: "",text: "Please fill out Mobile no",icon: "error", });
  }
  else if(loginpassword=='')
  {
    swal({title: "",text: "Please fill out Password ",icon: "error",});
  }
  else
  {
    const loginarr = new FormData();
    loginarr.append("password", loginpassword);
    loginarr.append("mobileno", loginmob)

    
    axios.post(baseurlapi+"/Login.php", loginarr).then(res => {
    if(res.data.success=='1')
    {
      var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
      sessionStorage.setItem('LoginData', JSON.stringify(obj));
     
      setLogin(false);
      if(maincatid==2)
      {
       var  navigateurl ='/writingcontest/'+subid;
       navigate(navigateurl);
       // window.location.reload(false);
      }
      if(maincatid==3)
      {
       window.open(panelurl, '_blank');
       window.location.reload(false);
      }
    

    }
    else if(res.data.success=='2' || res.data.success=='3')
    {
      swal({title: "",text: res.data.message,icon: "error",});
    }
  });
  
  }
 
}

// user Signup function call here
const Usersignup = id => ()=>
{
  var subid=subcontestid;
  var maincatid=maincontestid;
  var reviewername= document.getElementById('reviewername').value;
  var reviewermno= document.getElementById('reviewermno').value;
  var revieweremail= document.getElementById('revieweremail').value;

  var country= document.getElementById('country').value;
  var stdcity= document.getElementById('stdcity').value;
  var schoolname= document.getElementById('schoolname').value;

  var reviewerpassword= document.getElementById('reviewerpassword').value;
  var dob= document.getElementById('dob').value;

  if(reviewername=='')
  {
    swal({title: "",text: "Please fill out Name",icon: "error", });
  }
  else if(reviewermno=='')
  {
    swal({title: "",text: "Please fill out Mobile no",icon: "error",});
  }
  else if(revieweremail=='')
  {
    swal({title: "",text: "Please fill out Email",icon: "error",});
  }
  else if(country=='')
  {
      swal({title: "",text: "Please fill out Country",icon: "error",});
  }
  else if(stdcity=='')
  {
        swal({title: "",text: "Please fill out City",icon: "error",});
  }
  else if(dob=='')
  {
    swal({title: "",text: "Please fill out Dob",icon: "error",});
  }
  else if(schoolname=='')
  {
          swal({title: "",text: "Please fill out School Name",icon: "error",});
  }
  else if(reviewerpassword=='')
  {
    swal({title: "",text: "Please fill out Password",icon: "error",});
  }
  else
  {
      const signuparr = new FormData();
      signuparr.append("reviewername", reviewername);
      signuparr.append("reviewermno", reviewermno)
      signuparr.append("revieweremail", revieweremail);
      signuparr.append("reviewerpassword", reviewerpassword);
      signuparr.append("role", 2);
      signuparr.append("country", country);
      signuparr.append("city", stdcity);
      signuparr.append("schoolname", schoolname);
      signuparr.append("dob", dob);

      axios.post(baseurlapi+"/ReviewerSignup.php", signuparr).then(res => {
      
      if(res.data.success=='1')
      {
        var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
        sessionStorage.setItem('LoginData', JSON.stringify(obj));
        setSignup(false);
        if(maincatid==2)
        {
        var  navigateurl ='/writingcontest/'+subid;
        navigate(navigateurl);
        // window.location.reload(false);
        }
        if(maincatid==3)
        {
        window.open(panelurl, '_blank');
        window.location.reload(false);
        }
      }
      else if(res.data.success=='2' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
      else if(res.data.success=='3' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
      else if(res.data.success=='4' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
     });
  }

}

const Eventfun = val => () =>
{
  axios.get(baseurlapi+'/Enrollcontest.php?contestid='+val+'&userid='+userid+'&roleid='+roleid)
  .then(res => {
    if(res.data.success==1)
    {
      window.open(panelurl, '_blank');
    }

  })
}
const Conteststs = () =>
{
  swal({title: "",text: 'Already attended?',icon: "error",});

}


// check subcontest array size 
const Subcontestlength=Subcontest.length;
const Conteststatuslength=Conteststatus.length;
return (
    <>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (  
       null
      )}
{/* slider code start here   */}
<div className="container-fluid ">
  <div className="container">
  <div className="row">
      <Carousel className="carousel-fade" interval="10000"  keyboard={true} >
          {Slider.map((sld,index)=>(
          <Carousel.Item key={index}>
            <div className="row">
              <div className="col-md-12">
              <img className=" w-100 dekstopimage" src={sld.SliderImage} alt="First slide" />
              <img  className=" w-100 mobileimage"  src={sld.MobileViewSliderImage} alt="First slide" style={{height:'300px'}}/>
              </div>
             </div>
          </Carousel.Item>
         ))}
        </Carousel>
         </div>
  </div>
        
  </div>
{/* slider code end here */}



{/* subcontest start here  */}

{Subcontestlength>0?(
  <div className='container2 mt-5 b-r-5'>
     <div className="row">
      <div className="col-md-1"></div>
      <div className="col-md-10 contesrcarouseldiv" >
     
      <Carousel>
      {Subcontest.map((sub,index)=>(
          <Carousel.Item key={index}>
        
          <div className='row bgcolor214545 b-r-30 mb-3'  style={{padding: '10px',margin: '0px'}}>
                    <div className='col-md-6'>
                    <img src={sub.SubContest_Image}   alt="banner2"  className='w-100  b-r-30 contestimg' />
                    </div>

                    <div className="col-md-6 text-left">
                 <h2 className="mt-2 fw-bold colorfff fw-normal">{sub.title}</h2>
                 {sub.runningflag=='R' ?
                 <span>
                     {sub.maincatid=='1'?
                          <div className="inline-flex">
                            {Conteststatuslength!=''?
                            <span>
                              {Conteststatus.map((csts,index)=>(
                              <span key={index}>
                              {csts.contestid == sub.id && csts.maincatid  == sub.maincatid ?
                                <button className="bgcolor enrollbtn t-decoration-none color000 mt-2" type="button " onClick={Conteststs} > Enroll Now </button> 
                                :
                                <span>
                                   {sub.ContestType=='Review Story'?
                                   <Link to={`/contestreviewstory/${sub.id}`}  className="bgcolor enrollbtn t-decoration-none color000 mt-5">Enroll Now</Link>
                                   :<Link to={`/readingcontest/${sub.id}`}  className="bgcolor enrollbtn t-decoration-none color000 mt-5">Enroll Now</Link>
                                  }
                                </span>
                                
                               }
                              </span>
                            ))}
                            </span>
                            :
                            <span>
                                {sub.ContestType=='Review Story'?
                                <Link to={`/contestreviewstory/${sub.id}`}  className="bgcolor enrollbtn t-decoration-none color000 ">Enroll Now</Link>
                                :<Link to={`/readingcontest/${sub.id}`}  className="bgcolor enrollbtn t-decoration-none color000 ">Enroll Now</Link>
                                }
                            </span>
                          }
                          </div>
                      :null }

                          {sub.maincatid=='2'?
                            <div className="inline-flex">
                        
                           {userid!='' && roleid==2 ?
                            <Link to={`/writingcontest/${sub.id}`}  className="bgcolor enrollbtn t-decoration-none color000 ">Enroll Now</Link>

                            :<button className="bgcolor enrollbtn t-decoration-none color000 " type="button "  data-maincontestid={sub.maincatid} onClick={OpenLoginmodel(sub.id)} > Enroll Now</button>
                            }
                           
                            {sub.VideoUrl !='' ? 
                            <a href={sub.VideoUrl} target="_blank" className="mt-2"><i className="fa fa-video-camera f-s-40 m-l-10 " style={{color:'red'}} aria-hidden="true"></i></a>
                            :null}
                            </div>
                         
                          :null }

                          {
                          sub.maincatid=='3'?
                         
                          <div className="inline-flex">
                         
                          {userid!='' && roleid==2 ?
                         <button  className="bgcolor enrollbtn t-decoration-none color000 mt-2" onClick={Eventfun(sub.id)}>Enroll Now</button>
                          : <button className="bgcolor enrollbtn t-decoration-none color000 " type="button "  data-maincontestid={sub.maincatid} onClick={OpenLoginmodel(sub.id)} > Enroll Now</button>
                          }
                       
                        {sub.VideoUrl !='' ? 
                        <a href={sub.VideoUrl} target="_blank" className="mt-2"><i className="fa fa-video-camera f-s-40 m-l-10 " style={{color:'red'}} aria-hidden="true"></i></a>
                        :null}
                        </div>
                          :null }
                
                     </span>
                  :null}
                  {/* <p className="mt-3 colorB9B9B9 ">{sub.shortdesc}...</p> */}
                  <p className="mt-3 colorB9B9B9 shortdesctext" dangerouslySetInnerHTML={{__html : sub.shortdesc}}></p>
                  <p className="mt-4 colorB9B9B9"  onClick={Readinstruction(sub.Instruction)} style={{textDecoration: 'underline',cursor:'pointer'}}>View Instructions</p>
                
                </div>
            </div>
           
 
          </Carousel.Item>
           ))}
        </Carousel>
      
      </div>
      <div className="col-md-1"></div>
      </div>
      
       
</div>
):null}

{/* subcontest section end here  */}


{/* Contest Banner start here  */}

<Contestbannersection ContestBanner={ContestBanner} />


    
 {/* top pics section  */}
<Editorpicksection Topeditor3={Topeditor3} />

        {/* discover section here  */}
    <div className="container-fluid  ">
    <div className="container2">
       <div className="row ">
      <h4 className='mb-5 mt-5 color214545 fw-bold'>Discover Our Contests</h4>
    
      {/* {adsarr.map((adsdata, index) => (  */}
        {contest.map((contestdata,index)=>(
          <div className="col-md-4" key={index}>
         <div className="card mb-4 b-none bgcolorFFF8E6" style={{height:'520px'}}>
          <div className="discoverborder"></div>
          <img className="card-img-top discoverimg" src={contestdata.MainContest_Image}  alt="Card image cap"/>
          
            <div className="card-body px-0">
               <h5 className="card-title fw-bold p-l-10 p-r-10 color214545">{contestdata.title}</h5>
               <p className="card-text p-l-10 p-r-10">{contestdata.Short_Descripation}</p>
               {/* <a href="#" className="btn btn-dark btn-sm cardbtn b-radius-30">Enroll &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></a> */}
               <Link  to={`/readingchellenge/${contestdata.id}`} className="btn btn-dark btn-sm cardbtn b-radius-30  m-l-10">View &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></Link>
            </div>
         </div>
      </div>
        ) )}
       </div>
       </div> 
    </div>

    {/* news section */}
   <Newssection news={news} />
   {/* news section end here  */}

    <div className="container-fluid authorclubsec">
   
     <div className="container2 commsection">
     <div className="row">
      <div className="col-md-4 textcenter">
      <h4 className='mb-5 mt-5 color214545 fw-bold' style={{fontSize:'30px',paddingTop: '67px',textTransform: 'uppercase'}}> Young Writers CLUB</h4>
      <p className="f-s-20" style={{marginTop: '-30px'}}>Inspiring with their stories</p>
      <p className="fw-bold mt-2 f-s-20">Our Community</p>
      <div className="mt-5">
       {author1.map((auth,index)=>(
            <img key={index} src={auth.filename} className="communityimg marginleft-25 b-r-50-p"/>
        ))}
        
     
      </div>
      <br/>
      <br/>
      {/* <button className="commubtm mt-5">View All &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></button> */}
      <Link  to={`authorclub`} className="commubtm mt-5 t-decoration-none">View All &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></Link>
      </div>
      <div className="col-md-8 textright">
     <div className="mt-5">
      <img src={images['Authorsclubfinal.png']} alt="news" className="communityimg2 b-r-30"/>
     </div>
     
      </div>
      </div>
     </div>
   
    </div>

{/* achiver section start here  */}
<Achieverssection  Achievers={Achievers} />
{/* achiver section end here */}
{/* gallery section start here */}
   <Homegallery  galleryImages={gallery} />
{/* gallery section end here */}
{/* Association section start here */}
<Associationsection association={association} />
{/* Association section end here */}
{/* book collection section start here */}
<Bookcollectionsection book={book} />
{/* book collection section end here */}
{/* instagram section start here */}
<Instagramsection Instafeed={Instafeed} />
{/* instagram section end here */}


    <div className="container2 mt-5">
    <h6><span style={{letterSpacing: '15px'}} className="fw-bold">#YOUTUBE</span> <span className="floatright">@Storiesbychildren</span></h6>
     <div className="row youtuberow" >
      <div className="col-md-1"></div>
     <div className="col-md-10 mt-5">
     <iframe width="100%" height="300" src={youtube} title="YouTube video player" rel="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
     </div>
     <div className="col-md-1"></div>
     </div>
    </div>

     
 {/* top writer section start here   */}
<Topwritersection topwriter={Topwriter} />
 {/* top writer section end here  */}
 <br/>
   

  
{/* signup model code here  */}

<Modal show={openLoginmodel} onHide={LoginClose} animation={false}  
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Login</h4>
                 
                  <div className="form-group col-md-12">
                    <label className="popuplable">Mobile No.</label>
                    <input type="tel" className="form-control" id="loginmob"  placeholder='Enter Mobile No.'  />
                  </div>
                
                  <div className="form-group col-md-12">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="loginpassword" placeholder='Enter Password' />
                  </div>
                  
                
                  <div className="form-group col-md-12">
                    
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Loginuser()} >Login</button>
                    <p className="mt-2">Don't have an Account ? &nbsp;<button className="border-0  p-l-10 p-r-10 p-b-5 mt-2 b-r-5 colorfff bgcolorD4AC28"onClick={OpenSignupmodel}>Sign Up</button>
                    </p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>

    <Modal show={opensignupmodel} onHide={SignupClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Register</h4>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name</label>
                    <input type="text" className="form-control" id="reviewername"    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Mobile No</label>
                    <input type="tel" className="form-control" id="reviewermno"   />
                  </div>
                  
                  <div className="form-group col-md-6">
                    <label className="popuplable">Email</label>
                    <input type="email" className="form-control" id="revieweremail"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Country</label>
                    <input type="text" className="form-control" id="country"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">City</label>
                    <input type="text" className="form-control" id="stdcity"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Date Of Birth</label>
                    <input type="date" className="form-control" id="dob"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">School Name</label>
                    <input type="text" className="form-control" id="schoolname"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="reviewerpassword"  />
                  </div>
                 
                  <div className="form-group col-md-8">
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Usersignup()} >Submit</button>
                    <p className="mt-2">Already have an Account ? <span className="colorD4AC28 text-d-none" onClick={OpenLoginmodel}>Sign in</span></p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>


         {/* offers model */}

   <Modal show={offermodel} onHide={OffersClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row " > 
                {Offers.map((ofr,index)=>(
               
                <div className="col-md-12" key={index}>
                  <img src={ofr.offer_image} style={{width:'100%',height:'500px'}} />
                </div>
                   
                   ))}
                 </div>

                </div>
                </Modal.Body>
         </Modal>

         {/* instruction model start here */}

         <Modal show={openinstructionmodel} onHide={InstructionClose} animation={false}  
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered >
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row" style={{marginTop: '-40px'}} > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Instruction</h4>
                 
                 <p style={{whiteSpace: 'pre-wrap'}}>{instructiondata}</p>
                 
                 </div>

                </div>
                </Modal.Body>
         </Modal>



    {/* ads slider code here */}
     <Ads adsarr={ads}/>
     <br/> 
        
    </>

  )
}
