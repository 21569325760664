
    import React,{ useState ,useEffect} from 'react';
    import axios from "axios";
    import { Link, useLocation } from 'react-router-dom';
    import { Modal, Button } from 'react-bootstrap';
    import './Career.css';
    import swal from 'sweetalert';
    import $ from 'jquery';
  
    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
      }
      
      const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));
    
    export default function Career(props)
    {
      const baseurlapi=props.baseurlapi;
      const [Career, setCareer] = useState([]);
      const [crid, setcrid] = useState('');
      const { pathname } = useLocation();

      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const [loading, setLoading] = useState(false);

      const handleShow = id =>() =>
      {
       
        setcrid(id)
        setShow(true);
      } 
      
      useEffect(() => {
        setLoading(true);
        axios.get(baseurlapi+"/Career.php")
        .then(response2 => {
          setCareer(response2.data.Career);
          setLoading(false);
        })
         window.scrollTo(0, 0);
        }, [pathname]);



        // user Signup function call here
const Apply =  ()=>
{
  var name= document.getElementById('name').value;
  var mno= document.getElementById('mno').value;
  var email= document.getElementById('email').value;
  var resume= document.getElementById('file').value;
  var message= document.getElementById('message').value;
 
  if(name=='')
  {
    swal({title: "",text: "Please fill out Name",icon: "error", });
  }
  else if(mno=='')
  {
    swal({title: "",text: "Please fill out Mobile no",icon: "error",});
  }
  else if(email=='')
  {
    swal({title: "",text: "Please fill out Email",icon: "error",});
  }
  else if(resume=='')
  {
    swal({title: "",text: "Please select resume",icon: "error",});
  }
  else
  {
      var file = $('#file')[0].files;

      if(file[0].size>1048576)
      {
        swal({title: "",text: "Please upload resume less than 1MB.",icon: "error",});
      }
      else
      {
        var applyarr = new FormData();
        applyarr.append("Careerid", crid);
        applyarr.append("mno", mno);
        applyarr.append("email", email);
        applyarr.append("file", file[0]);
        applyarr.append("message", message);
        applyarr.append("name", name);
        
        axios.post(baseurlapi+"/Jobapply.php", applyarr).then(res => {
        
          if(res.data.success=='1')
          {
            swal({title: "",text: res.data.message,icon: "success",});
            setShow(false);
          }
          else if(res.data.success=='2' || res.data.success=='3')
          {
            swal({title: "",text: res.data.message,icon: "error",});
          }
          
       });
      }
  }

}

    return(
        <>
         {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        ) : (  
        <div className="container-fluid  p-t-20 p-b-10 bgcolorFFFFFF border-1px">
        <div className="container2 mt-5">
            <div className="row">
            <h1 className='mt-1 color214545 fw-bold'>Start doing work that matters</h1>
            <p className="mt-0">"Opportunities come by, talent lies in recognising them and making the best"</p>
            </div>
            <hr/>
          
            
              {Career.map((cr,index)=>(
              <div className="row" key={index}>
             <div className="col-md-4"> 
                <h5 className="fw-bold">{cr.PositionName}</h5>
                <p>Open positions for this role</p>
             </div>
             <div className="col-md-8"> 
              <div className="openingdiv mb-3">
                <p className="mb-0 f-s-20">{cr.PositionName} <span className="fl-r f-s-15 opn_location">{cr.Location}</span></p>
                <p className="f-s-12">{cr.Short_Descripation}</p>
                <div className="f-s-10 applysection">
                  <i className="fa fa-clock-o bg868686"  style={{fontSize:'20px'}}></i><span className="opntypeandsallry">{cr.Availability}</span>&nbsp;
                  <i className="fa fa-clock-o bg868686"  style={{fontSize:'20px'}}></i><span className="opntypeandsallry">{cr.Salary}</span>
                  <span className="fl-r"><button type="button" className="applybtn" onClick={handleShow(cr.id)}>APPLY</button></span>
                </div>
              </div>
             </div>
            
            
              <hr/>
            </div>
             ))}
           
           
         
        </div>
        </div>
        )}
          {/* popup model code here  */}


                
    <Modal show={show} onHide={handleClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row " > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Apply</h4>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name</label>
                    <input type="text" className="form-control" id="name"    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Mobile No</label>
                    <input type="tel" className="form-control" id="mno"   />
                  </div>
                  
                  <div className="form-group col-md-6">
                    <label className="popuplable">Email</label>
                    <input type="email" className="form-control" id="email"  />
                  </div>

                 

                  <div className="form-group col-md-6">
                    <label className="popuplable">Resume</label>
                    <input type="file" className="form-control" id="file" name="file" accept="application/pdf" />
                  </div>

                  <div className="form-group col-md-12">
                    <label className="popuplable">Message</label>
                    <textarea  className="form-control" id="message" rows="4" cols="50" ></textarea>
                  </div>
                 
                  <div className="form-group col-md-8">
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28"  onClick={Apply} >Submit</button>
                  
                  </div>
                 </div>

                </div>
                </Modal.Body>
               
         </Modal>
        </>
    )
}
