import React,{ useState ,useEffect} from 'react';
import { Routes, Route, Link, useParams ,useNavigate,useLocation  } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Moment from 'react-moment';
import swal from 'sweetalert';
import './Comicmaster.css';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));


export default function Comicmaster(props)
{
    const baseurlapi=props.baseurlapi;
    const { "*": param } = useParams();
    const paramArray = param.split("/");
    var searchval=paramArray[0];

    const [Comicmaster, setComicmaster] = useState([]);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const [arrlength, setArrlength] = useState(0);
  
    useEffect(() => {
      setLoading(true);
      axios.get(baseurlapi+"/Allcomic.php")
      .then(response2 => {
        setComicmaster(response2.data.Comicmaster)
        setArrlength(response2.data.Comicmaster.length)
        setLoading(false);
        
      })
       window.scrollTo(0, 0);
    }, [pathname]);

    return(
        <>
         {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        ) : (  

          
        <div className="container-fluid p-t-10 p-b-10 bgcolorFFFFFF border-1px">
        {arrlength>0 ?
            (
              <div className="container mt-3">
                    <h4 className='mb-2   color214545 fw-bold'>Comic</h4>
              <div className="row mt-5 ">
                         {Comicmaster.map((com,index)=>(
                               <div className="col-md-3 mb-4" key={index}>
                               <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'315px'}}>
                               <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff">{com.Name}</span></div>
                               <img src={com.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                               <p> <img src={com.filename} width={'100%'}  className="profilepic_story" alt="profilepic" /></p>
                               <p  className="text-left m-b-none color214545">
                                   Age :  
                                   <Moment fromNow ago>{com.DOB}</Moment>
                               </p>
                               <p  className="text-left color214545">School :  {com.School_Name}</p>
                               <h6  className="text-left "><span className="fw-bold">{com.title}</span> </h6>
                               <div className="mb-3">
                                   <Link to={`/comicreading/${com.id}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link>               
                                   {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                               </div>
                               </div>
                               </div>
                         ))}
                        
                         </div>
              </div>
          ): <div className="row mt-5 ">
              <div className="col-md-12 mb-4">
              <p className='text-center'>No Record Found</p>
            </div>
            </div>
        }
        
        </div>
         )}
        
         
        </>
    )
}