import React,{useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./Homegallery.css";
import {Link } from 'react-router-dom';
import {
  faCircleChevronLeft, 
  faCircleChevronRight, 
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons'

const Homegallery = ({galleryImages}) => {


    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)
  
    const handleOpenModal = (index) => {
      setSlideNumber(index)
      setOpenModal(true)
    }
  
    // Close Modal
    const handleCloseModal = () => {
      setOpenModal(false)
    }
  
    // Previous Image
    const prevSlide = () => {
      slideNumber === 0 
      ? setSlideNumber( galleryImages.length -1 ) 
      : setSlideNumber( slideNumber - 1 )
    }
  
    // Next Image  
    const nextSlide = () => {
      slideNumber + 1 === galleryImages.length 
      ? setSlideNumber(0) 
      : setSlideNumber(slideNumber + 1)
    }

    return(
        <>
         
      {openModal && 
        <div className='sliderWrap'>
          <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal}  />
          <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
          <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
          <div className='fullScreenImage'>
            <img src={galleryImages[slideNumber].filename} alt='' />
          </div>
        </div>
      }
<div className="container-fluid ">

<div className="container2 mt-5 p-b-50">
<h4 className='mb-4 mt-5 color214545 fw-bold'> Gallery
<Link to="/gallery" className="floatright color214545">View All</Link>
</h4>
      <div className='galleryWrap'>
        {
          galleryImages && galleryImages.map((slide, index) => {
            return(
              <div 
                className='single' 
                key={index}
                onClick={ () => handleOpenModal(index) }
              >
                <img src={slide.filename} alt='' className="glimg"  style={{width: '100%'}} />
              </div>
            )
          })
        }
      </div>
      </div>

</div>
        </>
    )
}
export default Homegallery