import React,{ useState ,useEffect} from 'react';
import { Routes, Route, Link, useParams ,useNavigate,useLocation  } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Moment from 'react-moment';
import Ads from './Ads';

import './Conteststory.css';
export default function Novelconteststory (props)
{
    
    const { "*": param } = useParams();
    const paramArray = param.split("/");
    const navigate = useNavigate();
    var currenturl1=window.location.pathname; 
    // var  mid1 = currenturl.replace ( /[^\d.]/g, '' ); 
    // var mid = parseInt(mid1);
    var mid1 = currenturl1.split('/', 4);
    var url  = mid1[1];
    var mid  = mid1[2];
    var subcid  = mid1[3];
// console.log(subcid)
   

    var currenturl='/'+url+'/'+mid;

    const baseurlapi=props.baseurlapi;
    const { pathname } = useLocation();
    var Urlarray =[];
    paramArray.forEach(element => {
        Urlarray.push(element);
        });
   const [monthstrcount, setmonthstrcount] = useState([]);
   const  [Story, setStory] = useState([]);
   const [FilterStory, setFilterStory] = useState([]);
   const [SchoolName, setSchoolName] = useState([]);
   const [ads, setads] = useState([]);
   const [loading, setLoading] = useState(false);
   const [storytitle, setTitle] = useState('');
   const [monthcountcount, semonthcountcount] = useState('');
   
        useEffect(() => {
            setLoading(true);
            document.querySelector('#School').style.display = 'none';
            document.querySelector('#Name').style.display = 'none';
            // api calls here 
            axios.get(baseurlapi+'/Novelconteststory.php?subcid='+subcid)
            .then(response2 => {
              setmonthstrcount(response2.data.monthstrcount)
              // story filter array end here 
              semonthcountcount(response2.data.monthcountcount)
              setStory(response2.data.Story) 
              setFilterStory(response2.data.Story)
              setSchoolName(response2.data.SchoolName)
              $("#"+response2.data.currentdateval).addClass("Active");
         
            })
           
            axios.get(baseurlapi+"/Ads.php?type=Story Page")
            .then(response2 => {
                   setads(response2.data.Ads) 
            })
           
            setTitle('Contest');
            window.scrollTo(0, 0);
        }, [pathname],[]);
    

 const Monthyearfun = val => ()=>
{
    $(".monthyearbtn").removeClass("Active");
    $("#"+val).addClass("Active");

    axios.get(baseurlapi+'/Conteststorybydate.php?yearandmonth='+val)
    .then(response2 => {
        setStory(response2.data.Story) 
        setFilterStory(response2.data.Story)
        setSchoolName(response2.data.SchoolName)

    })
}


        // get story by month and year

const Monthchange = ()=>
{
    var year = document.getElementById("year").value;
    var month = document.getElementById("month").value;

    axios.get(baseurlapi+'/Storybydate.php?month='+month+'&year='+year)
    .then(response2 => {
      
        setStory(response2.data.Story) 
        setFilterStory(response2.data.Story)

    })

}

const Novelandmegalisyear =()=>
{
    var novelandmegalisyear = document.getElementById("novelandmegalisyear").value;
    axios.get(baseurlapi+'/Novelandmegalisyear.php?year='+novelandmegalisyear+'&mid='+mid)
    .then(response2 => {
       
        setStory(response2.data.Story) 
        setFilterStory(response2.data.Story)

    })
}


// check story array lenght
const arraylength=Story.length;
        // console.log(Subcategory)
    //    const Minusbtn = val => ()=>
const Minusbtn = val => ()=>
{
    var  minusurl = currenturl.replace(val, "");
    navigate(minusurl,{ replace: true });
    var newval=val.replace(/[\/\\]/g,'');
    for (let i =0; i< Urlarray.length; i++) 
    {   
        if(Urlarray[i]==newval)
        {
            $('#plus'+newval).show();
            $('#minus'+newval).hide();
        }
    
    }   
} 
   

//   filter dropdown hide and show

function filterby()
{
    var filterby = document.getElementById("filterby").value;

    if(filterby=='Age')
    {
        document.getElementById("Agedropdown").hidden=false;
        document.querySelector('#School').style.display = 'none';
        document.querySelector('#Name').style.display = 'none';
       
    }
    else if(filterby=='School')
    {
        document.querySelector('#School').style.display = 'block';
        document.getElementById("Agedropdown").hidden='none';
        document.querySelector('#Name').style.display = 'none';
    }
    else if(filterby=='Name')
    {
        document.querySelector('#School').style.display = 'none';
        document.getElementById("Agedropdown").hidden='none';
        document.querySelector('#Name').style.display = 'block';
    }
}



    //filter code here 

    function agechanged() 
    {
     
      var agegroup = document.getElementById("agegroup").value;
    
      if(agegroup==0)
      {
          setStory(FilterStory)
      }
      else
      {
          var Story2 =[];
          for(var i=0;i<FilterStory.length;i++)
          { 
            
            var obj = {};
              if(FilterStory[i].AgeGroup==agegroup)
              {
               
                  obj['maincatid']=FilterStory[i].maincatid;
                  obj['subcatid']=FilterStory[i].subcatid;
                  obj['id']=FilterStory[i].id;
                  obj['title']=FilterStory[i].title;
                  obj['CoverPic']=FilterStory[i].CoverPic;
                  obj['studentname']=FilterStory[i].studentname;
                  obj['DOB']=FilterStory[i].DOB;
                  obj['School_Name']=FilterStory[i].School_Name;
                  obj['profilepic']=FilterStory[i].profilepic;
                  obj['subcategory']=FilterStory[i].subcategory;
                  obj['maincategory']=FilterStory[i].maincategory;
                  obj['StoryViewCount']=FilterStory[i].StoryViewCount;
                  if(mid==7 || mid==6)
                      {
                          obj['storydata']='';
                          obj['AgeGroup']='';
                      }
                      else
                      {
                          obj['storydata']=FilterStory[i].storydata;
                          obj['AgeGroup']=FilterStory[i].AgeGroup;
                      }
      
                Story2.push(obj);
              }
          }
          setStory(Story2)
      }
     
    }


    
  function schoolchanged()
  {
    var schoolval = document.getElementById("schoolval").value;

    if(schoolval==0)
    {
        setStory(FilterStory)
    }
    else
    {
        var Story2 =[];
        for(var i=0;i<FilterStory.length;i++)
        { 
          var obj = {};
            if(FilterStory[i].School_Name==schoolval)
            {
               
                obj['maincatid']=FilterStory[i].maincatid;
                obj['subcatid']=FilterStory[i].subcatid;
                obj['id']=FilterStory[i].id;
                obj['title']=FilterStory[i].title;
                obj['CoverPic']=FilterStory[i].CoverPic;
                obj['studentname']=FilterStory[i].studentname;
                obj['DOB']=FilterStory[i].DOB;
                obj['School_Name']=FilterStory[i].School_Name;
                obj['profilepic']=FilterStory[i].profilepic;
                obj['subcategory']=FilterStory[i].subcategory;
                obj['maincategory']=FilterStory[i].maincategory;
                obj['StoryViewCount']=FilterStory[i].StoryViewCount;
                if(mid==7 || mid==6)
                    {
                        obj['storydata']='';
                        obj['AgeGroup']='';
                    }
                    else
                    {
                        obj['storydata']=FilterStory[i].storydata;
                        obj['AgeGroup']=FilterStory[i].AgeGroup;
                    }
    
              Story2.push(obj);
            }
        }
        setStory(Story2)
    }
  }


  function Byname()
  {
    var entername = document.getElementById("entername").value;

    if(entername=='')
    {
        setStory(FilterStory)
    }
    else
    {
        var Story3 =[];
      
        FilterStory.forEach(elt => {

          var obj = {};
            if(elt.studentname.toLowerCase()==entername.toLowerCase())
            {
               
                obj['maincatid']=elt.maincatid;
                obj['subcatid']=elt.subcatid;
                obj['id']=elt.id;
                obj['title']=elt.title;
                obj['CoverPic']=elt.CoverPic;
                obj['studentname']=elt.studentname;
                obj['DOB']=elt.DOB;
                obj['School_Name']=elt.School_Name;
                obj['profilepic']=elt.profilepic;
                obj['subcategory']=elt.subcategory;
                obj['maincategory']=elt.maincategory;
                obj['StoryViewCount']=elt.StoryViewCount;
                if(mid==7 || mid==6)
                    {
                        obj['storydata']='';
                        obj['AgeGroup']='';
                    }
                    else
                    {
                        obj['storydata']=elt.storydata;
                        obj['AgeGroup']=elt.AgeGroup;
                    }
    
              Story3.push(obj);
            }
        });
        setStory(Story3)
        $("#entername").val('');
    }
  }


  // short by filter code here

function Shortby()
{ 
   
 
    var Shortbyval=document.getElementById("Shortbyval").value;
    if(Shortbyval=='Oldest')
    {

        const clonedOptions = [...Story];

        clonedOptions.sort((a, b) => {
          return a.id  - b.id;
        });
    
        setStory(clonedOptions);
    }
    else if(Shortbyval=='Newest')
    {
        const clonedOptions = [...Story];

        clonedOptions.sort((a, b) => {
          return b.id  - a.id;
        });
    
        setStory(clonedOptions);
        
    }
    else if(Shortbyval=='Popular')
    {
        const clonedOptions = [...Story];

        clonedOptions.sort((a, b) => {
          return b.StoryViewCount  - a.StoryViewCount;
        });
    
        setStory(clonedOptions);
        
    }
    else if(Shortbyval=='Alphabetically')
    {
        const clonedOptions = [...Story];

        const sortedList = clonedOptions.sort((a, b) => a.title.localeCompare(b.title));
    
        setStory(sortedList);
        
    }

}


    return(
        <>

    {/* {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (  
       null
      )} */}
        <div className="container-fluid bgcolorFFFFFF border-1px">
        <div className="container  ">
        

            <div className="row mt-3">
             <div className="col-md-8"  style={{display:'flex'}}>
                <div  className="row filtersection" id="filtersection">
                
                <div className="col-md-3  ">
                <label style={{paddingLeft: '10px'}}>Filter By:</label>
                <select onChange={filterby} id="filterby" className="bgcolorcolorB9B9B9 storydropdown">
                    <option value="Age">Age</option>
                    <option value="School">School</option>
                    <option value="Name">Name</option>
                </select>
                </div>
                &nbsp; &nbsp; &nbsp; &nbsp;
                

                <div id="Agedropdown" className="col-md-3">
                    <label style={{paddingLeft: '10px'}}>Age Group:</label>
                    <select onChange={agechanged} id="agegroup" className="bgcolorcolorB9B9B9 storydropdown">
                    <option value={'0'}>All</option>
                    <option value={'7-8 Years'}>7-8 Years</option>
                    <option value={'10-12 Years'}>10-12 Years</option>
                    <option value={'13-15 Years'}>13-15 Years</option>
                    <option value={'15-18 Years'}>15-18 Years</option>
                    </select>
                </div>
                <div  id="School" className="col-md-3">
                    <label style={{paddingLeft: '10px'}}>School:</label>
                    <select onChange={schoolchanged} id="schoolval" className="bgcolorcolorB9B9B9 storydropdown">
                    <option value={'0'}>All</option>
                    {SchoolName.map((Sch,index)=>(
                    <option value={Sch.School_Name} key={index}>{Sch.School_Name}</option>
                    ))}
                    </select>
                </div>

                <div id="Name" className="col-md-4">
                  <div style={{display:'flex'}}>
                    <input type="text"  id="entername" />
                    <button className="bgcolorD4AC28" onClick={Byname}>Submit</button>
                  </div>
                </div>
                
                
                </div>
                
            
             </div>
             <div className="col-md-4">
             <div className="sortbysection">
                    <label style={{paddingLeft: '10px'}}>Sort By:</label>
                    <select onChange={Shortby} id="Shortbyval" className="bgcolorcolorB9B9B9 storydropdown">
                    <option value="Newest">Newest</option>
                    <option value="Oldest">Oldest</option>
                    <option value="Popular">Popular</option>
                    <option value="Alphabetically">Alphabetically a-z</option>
                    </select>
                </div>
            </div>
            </div>
            {arraylength>0 ?
                    (
                        <div className="row mt-5 ">
                        {Story.map((st,index)=>(

                            <div className="col-md-3 mb-4" key={index}>
                             
                             <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'360px'}}>
                              <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff studentname">{st.studentname}</span></div>
                            
                              <img src={st.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                              <p> <img src={st.profilepic} width={'100%'}  className="profilepic_story" alt="profilepic" /></p>
                              <p className="text-left mt-3 m-b-none color214545">Category :  {st.maincategory}</p>
                              <p  className="text-left m-b-none color214545">Genre :  {st.subcatname}</p>
                              <p  className="text-left m-b-none color214545">
                                  Age :  
                                  <Moment fromNow ago>{st.DOB}</Moment>
                              </p>
                              <p  className="text-left color214545">School :  {st.School_Name}</p>
                              <h6  className="text-left "><span className="fw-bold">{st.title}</span> </h6>
                             
                              <div className="mb-3">
                              <Link to={`/novelreading/${st.id}`}   className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link>
                                    
                                 <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {st.StoryViewCount}</span>
                                  {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                              </div>
                             </div>
                            </div>
                            
                             ))}
                          </div>
                    ) :
                    <div className="row mt-5 ">
                          <div className="col-md-12 mb-4">
                            <p className='text-center'>No Record Found</p>
                            </div>
                    </div>

            }



        </div>
        <br/>
<br/>
        <Ads adsarr={ads}/>
        <br/>
        </div>
        

            
        </>
    )
}