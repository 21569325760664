import React,{ useState ,useEffect} from 'react';
import { Routes, Route, Link, useParams ,useNavigate,useLocation  } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Moment from 'react-moment';
import Ads from './Ads';

import './Storylist.css';
export default function Storylist (props)
{
    
    const { "*": param } = useParams();
    const paramArray = param.split("/");
    const navigate = useNavigate();
    var currenturl1=window.location.pathname; 

    
    // var  mid1 = currenturl.replace ( /[^\d.]/g, '' ); 
    // var mid = parseInt(mid1);
    var mid1 = currenturl1.split('/', 3);
    var url  = mid1[1];
    var mid  = mid1[2];

    
    

    var currenturl='/'+url+'/'+mid;

    const baseurlapi=props.baseurlapi;
    const defaultprofilepic=props.defaultprofilepic;
    const { pathname } = useLocation();
    var Urlarray =[];
    paramArray.forEach(element => {
        Urlarray.push(element);
        });
   const [Subcategory, setSubcategory] = useState([]);
   const  [Story, setStory] = useState([]);
   const [FilterStory, setFilterStory] = useState([]);
   const [SchoolName, setSchoolName] = useState([]);
   const [ads, setads] = useState([]);
   const [loading, setLoading] = useState(false);
   const [storytitle, setTitle] = useState('');
   
   const [totalPages, setTotalPages] = useState(1);
   const [page, setPage] = useState(1);
   

   
        useEffect(() => {
            setLoading(true);
            document.querySelector('#School').style.display = 'none';
            document.querySelector('#Name').style.display = 'none';
            
            // api calls here 
            axios.get(baseurlapi+'/AllStory.php?page='+page+'&mid='+mid+'&sid=0')
            .then(response2 => {
              setSubcategory(response2.data.Subcategory)
              setSchoolName(response2.data.SchoolName)
              setLoading(false);
              setTotalPages(response2.data.total_pages);
              console.log(totalPages)
              for (let i =0; i< Urlarray.length; i++) 
              {
              
                 response2.data.Subcategory.forEach(sub => {
                  if(Urlarray[i]==sub.title.replace(/\s/g, ''))
                  {
                    $('#plus'+sub.title.replace(/\s/g, '')).hide();
                    $('#minus'+sub.title.replace(/\s/g, '')).show();
                  }
                  else
                  {
                    $('#plus'+sub.title.replace(/\s/g, '')).show();
                    $('#minus'+sub.title.replace(/\s/g, '')).hide();
                  }
                });
              } 


            //  sory filter code here 

            if(mid==0)
            { 
              
                if (Urlarray.length>1) 
                {
                    var Story3 =[];    
                    FilterStory.forEach(element => {
                           var obj3 = {};
                           for (let j =0; j< Urlarray.length; j++) 
                           {
                            if(element.subcatname.replace(/\s/g, '')==Urlarray[j].replace(/\s/g, ''))
                                {
                                    obj3['maincatid']=element.maincatid;
                                    obj3['subcatid']=element.subcatid;
                                    obj3['id']=element.id;
                                    obj3['title']=element.title;
                                    obj3['CoverPic']=element.CoverPic;
                                    obj3['studentname']=element.studentname;
                                   
                                    obj3['School_Name']=element.School_Name;
                                    obj3['profilepic']=element.profilepic;
                                    obj3['subcatname']=element.subcatname;
                                    obj3['maincategory']=element.maincategory;
                                    obj3['StoryViewCount']=element.StoryViewCount;
                                    obj3['AgeGroup']=element.AgeGroup;
                                   
                                
                                    obj3['entrydate']=element.entrydate;
                                    Story3.push(obj3);
                                }
                               
                           }
                           
                        });
                        setStory(Story3) 
                      
                }
                else
                {
                    setStory(response2.data.Story) 
                    setFilterStory(response2.data.Story)  
                }

               
                   
            }
            else if(mid==99 || mid==6 || mid==7)
            {
                if (Urlarray.length>1) 
                {
                    var Story3 =[];    
                    FilterStory.forEach(element => {
                           var obj3 = {};
                           for (let j =0; j< Urlarray.length; j++) 
                           {
                            if(element.subcatname.replace(/\s/g, '')==Urlarray[j].replace(/\s/g, ''))
                                {
                                    obj3['maincatid']=element.maincatid;
                                    obj3['subcatid']=element.subcatid;
                                    obj3['id']=element.id;
                                    obj3['title']=element.title;
                                    obj3['CoverPic']=element.CoverPic;
                                    obj3['studentname']=element.studentname;
                                   
                                    obj3['School_Name']=element.School_Name;
                                    obj3['profilepic']=element.profilepic;
                                    obj3['subcatname']=element.subcatname;
                                    obj3['maincategory']=element.maincategory;
                                    obj3['StoryViewCount']=element.StoryViewCount;
                                    if(mid==7 || mid==6)
                                    {
                                       
                                        obj3['AgeGroup']='';
                                    }
                                    else
                                    {
                                     
                                        obj3['AgeGroup']=element.AgeGroup;
                                    }
                                
                                    obj3['entrydate']=element.entrydate;
                                    Story3.push(obj3);
                                }
                               
                           }
                           
                        });
                        setStory(Story3) 
                      
                }
                else
                {
                    setStory(response2.data.Story) 
                    setFilterStory(response2.data.Story) 
                }
            }
            else
            {
                var Story3 =[];    
                response2.data.Story.forEach(element => {
                       var obj3 = {};
                       for (let j =0; j< Urlarray.length; j++) 
                       {
                        if(element.subcatname.replace(/\s/g, '')==Urlarray[j].replace(/\s/g, ''))
                            {
                                obj3['maincatid']=element.maincatid;
                                obj3['subcatid']=element.subcatid;
                                obj3['id']=element.id;
                                obj3['title']=element.title;
                                obj3['CoverPic']=element.CoverPic;
                                obj3['studentname']=element.studentname;
                               
                                obj3['School_Name']=element.School_Name;
                                obj3['profilepic']=element.profilepic;
                                obj3['subcatname']=element.subcatname;
                                obj3['maincategory']=element.maincategory;
                                obj3['StoryViewCount']=element.StoryViewCount;

                                
                                if(mid==6)
                                {
                                   
                                    obj3['AgeGroup']='';
                                }
                                else
                                {
                                  
                                    obj3['AgeGroup']=element.AgeGroup;
                                }
                            
                                obj3['entrydate']=element.entrydate;
                                Story3.push(obj3);
                            }
                       }
                       
                    });
                    setStory(Story3) 
                    setFilterStory(Story3)
            }

           
                // story filter array end here   
            })

            axios.get(baseurlapi+"/Ads.php?type=Story Page")
            .then(response2 => {
                   setads(response2.data.Ads)
            })

            // for story  title 
            if(mid==0)
            {
                setTitle('All Stories');
            }
            else if(mid==1)
            {
                setTitle('Story');
            }
            else if(mid==2)
            {
                setTitle('Poem');
            }
            else if(mid==3)
            {
                setTitle('Article');
            }
            else if(mid==6)
            {
                setTitle('Mega Story');
            }
            else if(mid==7)
            {
                setTitle('Novel');
            }
            else if(mid==99)
            {
                setTitle('Contest');
            }
            window.scrollTo(0, 0);
        }, [pathname],[]);
    

        // get story by month and year

const Monthchange = ()=>
{
    var year = document.getElementById("year").value;
    var month = document.getElementById("month").value;

    axios.get(baseurlapi+'/Storybydate.php?month='+month+'&year='+year)
    .then(response2 => {
      
        setStory(response2.data.Story) 
        setFilterStory(response2.data.Story)

    })

}

const Novelandmegalisyear =()=>
{
    var novelandmegalisyear = document.getElementById("novelandmegalisyear").value;

    axios.get(baseurlapi+'/Novelandmegalisyear.php?year='+novelandmegalisyear+'&mid='+mid)
    .then(response2 => {
     
        setStory(response2.data.Story) 
        setFilterStory(response2.data.Story)

    })
}


// check story array lenght
const arraylength=Story.length;
        // console.log(Subcategory)
    //    const Minusbtn = val => ()=>
const Minusbtn = val => ()=>
{
    var  minusurl = currenturl.replace(val, "");
    navigate(minusurl,{ replace: true });
    var newval=val.replace(/[\/\\]/g,'');
    for (let i =0; i< Urlarray.length; i++) 
    {   
        if(Urlarray[i]==newval)
        {
            $('#plus'+newval).show();
            $('#minus'+newval).hide();
        }
    
    }   
} 
   

//   filter dropdown hide and show

function filterby()
{
    var filterby = document.getElementById("filterby").value;

    if(filterby=='Age')
    {
        document.getElementById("Agedropdown").hidden=false;
        document.querySelector('#School').style.display = 'none';
        document.querySelector('#Name').style.display = 'none';
       
    }
    else if(filterby=='School')
    {
        document.querySelector('#School').style.display = 'block';
        document.getElementById("Agedropdown").hidden='none';
        document.querySelector('#Name').style.display = 'none';
    }
    else if(filterby=='Name')
    {
        document.querySelector('#School').style.display = 'none';
        document.getElementById("Agedropdown").hidden='none';
        document.querySelector('#Name').style.display = 'block';
    }
}



    //filter code here 

    function agechanged() 
    {
     
      var agegroup = document.getElementById("agegroup").value;
    
      if(agegroup==0)
      {
          setStory(FilterStory)
      }
      else
      {
          var Story2 =[];
          for(var i=0;i<FilterStory.length;i++)
          { 
            
            var obj = {};
              if(FilterStory[i].AgeGroup==agegroup)
              {
               
                  obj['maincatid']=FilterStory[i].maincatid;
                  obj['subcatid']=FilterStory[i].subcatid;
                  obj['id']=FilterStory[i].id;
                  obj['title']=FilterStory[i].title;
                  obj['CoverPic']=FilterStory[i].CoverPic;
                  obj['studentname']=FilterStory[i].studentname;
                 
                  obj['School_Name']=FilterStory[i].School_Name;
                  obj['profilepic']=FilterStory[i].profilepic;
                  obj['subcatname']=FilterStory[i].subcatname;
                  obj['maincategory']=FilterStory[i].maincategory;
                  obj['StoryViewCount']=FilterStory[i].StoryViewCount;
                  obj['AgeGroup']=FilterStory[i].AgeGroup;
                 
      
                Story2.push(obj);
              }
          }
          setStory(Story2)
      }
     
    }


    
  function schoolchanged()
  {
    var schoolval = document.getElementById("schoolval").value;

    if(schoolval==0)
    {
        setStory(FilterStory)
    }
    else
    {
        var Story2 =[];
        for(var i=0;i<FilterStory.length;i++)
        { 
          var obj = {};
            if(FilterStory[i].School_Name==schoolval)
            {
               
                obj['maincatid']=FilterStory[i].maincatid;
                obj['subcatid']=FilterStory[i].subcatid;
                obj['id']=FilterStory[i].id;
                obj['title']=FilterStory[i].title;
                obj['CoverPic']=FilterStory[i].CoverPic;
                obj['studentname']=FilterStory[i].studentname;
              
                obj['School_Name']=FilterStory[i].School_Name;
                obj['profilepic']=FilterStory[i].profilepic;
                obj['subcatname']=FilterStory[i].subcatname;
                obj['maincategory']=FilterStory[i].maincategory;
                obj['StoryViewCount']=FilterStory[i].StoryViewCount;
                obj['AgeGroup']=FilterStory[i].AgeGroup;

                
               
    
              Story2.push(obj);
            }
        }
        setStory(Story2)
    }
  }


  function Byname()
  {
    var entername = document.getElementById("entername").value;

    if(entername=='')
    {
        setStory(FilterStory)
    }
    else
    {
        var Story3 =[];
      
            FilterStory.forEach(elt => {
             
          
          var obj = {};
            if(elt.studentname.toLowerCase()==entername.toLowerCase())
            {
               
              
                obj['maincatid']=elt.maincatid;
                obj['subcatid']=elt.subcatid;
                obj['id']=elt.id;
                obj['title']=elt.title;
                obj['CoverPic']=elt.CoverPic;
                obj['studentname']=elt.studentname;
               
                obj['School_Name']=elt.School_Name;
                obj['profilepic']=elt.profilepic;
                obj['subcatname']=elt.subcatname;
                obj['maincategory']=elt.maincategory;
                obj['StoryViewCount']=elt.StoryViewCount;
                obj['AgeGroup']=elt.AgeGroup;
               
    
              Story3.push(obj);
            }
        });
        setStory(Story3)
        $("#entername").val('');
    }
  }


  // short by filter code here

function Shortby()
{ 
   
 
    var Shortbyval=document.getElementById("Shortbyval").value;
    if(Shortbyval=='Oldest')
    {

        const clonedOptions = [...Story];

        clonedOptions.sort((a, b) => {
          return a.id  - b.id;
        });
    
        setStory(clonedOptions);
    }
    else if(Shortbyval=='Newest')
    {
        const clonedOptions = [...Story];

        clonedOptions.sort((a, b) => {
          return b.id  - a.id;
        });
    
        setStory(clonedOptions);
        
    }
    else if(Shortbyval=='Popular')
    {
        const clonedOptions = [...Story];

        clonedOptions.sort((a, b) => {
          return b.StoryViewCount  - a.StoryViewCount;
        });
    
        setStory(clonedOptions);
        
    }
    else if(Shortbyval=='Alphabetically')
    {
        const clonedOptions = [...Story];

        const sortedList = clonedOptions.sort((a, b) => a.title.localeCompare(b.title));
    
        setStory(sortedList);
        
    }

}

const Pagecount =(val)=>
{

 setPage(val);
 setLoading(true);
 axios.get(baseurlapi+'/AllStory.php?page='+val+'&mid='+mid+'&sid=1')
        .then(res => {
            
            setLoading(false);
          setStory([...Story, ...res.data.Story])
          console.log(res.data.total_pages);
          setTotalPages(res.data.total_pages);
        })
}


    return(
        <>

    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (  
       null
      )}
        <div className="container-fluid bgcolorFFFFFF border-1px">
        <div className="container  ">
            <div className="row p-t-20">
            <div className="col-md-6">
                <h4 className='mb-5  color214545 fw-bold'>{storytitle}</h4>
            </div> 
            <div className="col-md-6">
            {mid==0?
            <div className="row">
               
            <div className="col-md-2"></div>
                <div className="col-md-5">
                    <div className="yearsection">
                    <label style={{paddingLeft: '10px'}}>Year</label>
                        <select onChange={Monthchange} id="year" className="bgcolorcolorB9B9B9 storydropdown">
                            {/* <option value="2020">2020</option>
                            <option value="2021">2021</option> */}
                            {/* <option value="All">All</option> */}
                            <option value="2022">Old Stories</option>
                            <option value="2023">2023</option>
                            {/* <option value="2024">2024</option> */}
                        </select>
                    </div>
                
            </div>
          
                <div className="col-md-5">
                    <div className="monthsection">
                    <label style={{paddingLeft: '10px'}}>Month:</label>
                    <select  onChange={Monthchange} id="month" className="bgcolorcolorB9B9B9 storydropdown">
                        <option value="">Select Month</option>
                        <option value="01">Jan</option>
                        <option value="02">Feb</option>
                        <option value="03">Mar</option>
                        <option value="04">Apr</option>
                        <option value="05">May</option>
                        <option value="06">Jun</option>
                        <option value="07">Jul</option>
                        <option value="08">Aug</option>
                        <option value="09">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                    </select>
                    </div>
                
                </div>
            
            </div>
              :null}
        {/* for novel and megalist */}
            {mid==6 || mid==7?
            <div className="row">
               
            <div className="col-md-2"></div>
                <div className="col-md-5"></div>
                <div className="col-md-5">
                    <div className="yearsection">
                    <label style={{paddingLeft: '10px'}}>Year</label>
                        <select onChange={Novelandmegalisyear} id="novelandmegalisyear" className="bgcolorcolorB9B9B9 storydropdown">
                            <option value="All">All</option>
                            <option value="2022">Old {storytitle}</option>
                            <option value="2023">2023</option>
                            {/* <option value="2024">2024</option> */}
                        </select>
                    </div>
                
            </div>
          
              
            </div>
              :null}
            </div> 
            </div>

            <div className="row">
              <div className="col-md-12">
               <div className="bgcolorFAFAFA b-r-10" style={{border: '1px solid #D9D9D9'}}>
                  <h6 className="p-t-20 p-l-5 m-l-20">Select Sub - Category</h6>
                  <div className="pd-10">
                    {Subcategory==3?
                   <p className="text-center">No Record Found</p>
                    :<span>
                         {Subcategory.map((subdata,index)=>(
                          <span key={index}>
                            <Link to={`${currenturl}/${subdata.title.replace(/\s/g, '')}`} value={subdata.title} id={'plus'+subdata.title.replace(/\s/g, '')}  className="p-l-10 p-r-10 border-0 b-r-30  m-l-20 bg464646 bgcolorcolorB9B9B9 text-d-none" style={{display: 'inline-block',width:'165px',marginBottom:'15px',textAlign:'center'}}>{subdata.title}  <i className="fa fa-plus" aria-hidden="true"> </i></Link>
                            <button  id={'minus'+subdata.title.replace(/\s/g, '')}  style={{display:'none'}} onClick={Minusbtn('/'+subdata.title)}  className="p-l-10 p-r-10 border-0 b-r-30  m-l-20 bg464646 text-d-none minusbtn" > {subdata.title}  <i className="fa fa-minus" aria-hidden="true"> </i></button>

                          </span>
                           
                    ))}
                        </span>}
                   
                     
                  </div>
    
                
               </div>
              </div>
            </div>


            <div className="row mt-3">
             <div className="col-md-8"  style={{display:'flex'}}>
                <div  className="row filtersection" id="filtersection">
                
                <div className="col-md-3  ">
                <label style={{paddingLeft: '10px'}}>Filter By:</label>
                <select onChange={filterby} id="filterby" className="bgcolorcolorB9B9B9 storydropdown">
                    <option value="Age">Age</option>
                    <option value="School">School</option>
                    <option value="Name">Name</option>
                </select>
                </div>
                &nbsp; &nbsp; &nbsp; &nbsp;
                

                <div id="Agedropdown" className="col-md-3">
                    <label style={{paddingLeft: '10px'}}>Age Group:</label>
                    <select onChange={agechanged} id="agegroup" className="bgcolorcolorB9B9B9 storydropdown">
                    <option value={'0'}>All</option>
                    <option value={'7-10 Years'}>7-10 Years</option>
                    <option value={'11-14 Years'}>11-14 Years</option>
                    <option value={'15-18 Years'}>15-18 Years</option>
                   
                    </select>
                </div>
                <div  id="School" className="col-md-3">
                    <label style={{paddingLeft: '10px'}}>School:</label>
                    <select onChange={schoolchanged} id="schoolval" className="bgcolorcolorB9B9B9 storydropdown">
                    <option value={'0'}>All</option>
                    {SchoolName.map((Sch,index)=>(
                    <option value={Sch.School_Name} key={index}>{Sch.School_Name}</option>
                    ))}
                    </select>
                </div>

                <div id="Name" className="col-md-4">
                  <div style={{display:'flex'}}>
                    <input type="text"  id="entername" />
                    <button className="bgcolorD4AC28" onClick={Byname}>Submit</button>
                  </div>
                </div>
                
                
                </div>
                
            
             </div>
             <div className="col-md-4">
             <div className="sortbysection">
                    <label style={{paddingLeft: '10px'}}>Sort By:</label>
                    <select onChange={Shortby} id="Shortbyval" className="bgcolorcolorB9B9B9 storydropdown">
                    <option value="Newest">Newest</option>
                    <option value="Oldest">Oldest</option>
                    <option value="Popular">Popular</option>
                    <option value="Alphabetically">Alphabetically a-z</option>
                    </select>
                </div>
            </div>
            </div>
            {arraylength>0 ?
                    (
                        <div className="row mt-5 ">
                        {Story.map((st,index)=>(

                            <div className="col-md-3 mb-4" key={index}>
                             
                             <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'360px'}}>
                              <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff studentname">{st.studentname}</span></div>
                            
                              <img src={st.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                             
                             
                              {st.profilepic===null?
                             <p> <img src={defaultprofilepic} width={'100%'}  className="profilepic_story" alt="profilepic" /></p>
                             :<p> <img src={st.profilepic} width={'100%'}  className="profilepic_story" alt="profilepic" /></p>
                             }
                              
                              <p className="text-left mt-3 m-b-none color214545 f-s-14">Category :  {st.maincategory}</p>
                              <p  className="text-left m-b-none color214545 f-s-14">Genre :  {st.subcatname}</p>
                              <p  className="text-left m-b-none color214545 f-s-14">
                                  Age :   {st.AgeGroup}
                                  {/* <Moment fromNow ago>{st.DOB}</Moment> */}
                              </p>
                             
                              {st.School_Name==null?(
                                <p  className="text-left color214545 f-s-14">School :  {st.School_Name}</p>
                              ): <p  className="text-left color214545 f-s-14">School :  {st.School_Name.substring(0, 20)}...</p>}
                             
                              
                              <h6  className="text-left f-s-14"><span className="fw-bold">{st.title.substring(0, 20)}...</span> </h6>
                             
                              <div className="mb-3">

                                {mid==7 ?
                                  (
                                    <Link to={`/novelreading/${st.id}`}   className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link>
                                     
                                  ) :
                                    <Link to={`/storyreading/${st.id}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 
              
                                }
                                 <span className="fl-r bg868686"><i className="fa fa-eye" aria-hidden="true"></i> {st.StoryViewCount}</span>
                                  {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                              </div>
                             </div>
                            </div>
                            
                             ))}
                          </div>
                    ) :
                    <div className="row mt-5 ">
                          <div className="col-md-12 mb-4">
                            <p className='text-center'>No Record Found</p>
                            </div>
                    </div>

            }
            {mid==0?(
            <div className='text-center'>
            {totalPages !== page && <button className="btn-load-more bgcolorD4AC28 color214545" onClick={() => Pagecount(page + 1)}>{loading ? 'Loading...' : 'Load More'}</button>}
            
            </div>
            ):null}
   
      

        </div>
        <br/>
<br/>
        <Ads adsarr={ads}/>
        <br/>
        </div>
        

            
        </>
    )
}