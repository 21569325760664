import React,{ useState ,useEffect} from 'react';
import './Bookstore.css';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Bookstore(props)
{
    const { pathname } = useLocation();
    const [btnarray, setbtnarray] = useState([]);
    const baseurlapi=props.baseurlapi;
    const [AllBook, setBook] = useState([]);
    const [WinningEditions, setWinning] = useState([]);
    const [loading, setLoading] = useState(false);
     
      useEffect(() => {
        setLoading(true);
        axios.get(baseurlapi+'/Book.php')
        .then(response2 => {
            setBook(response2.data.AllBook)
            setWinning(response2.data.WinningEditions)
            setLoading(false);
        })
         window.scrollTo(0, 0);
      }, [pathname]);
  
    
      const clickbtn = id => ()=>
      {
       
        setbtnarray([...btnarray, id]);
      }
      
    

    return(
        <>
         {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        ) : (  
        <div className="container-fluid bgcolorFFFFFF p-t-20 border-1px">
            
        <div className="container2 ">
            <div className="row">
            <div className="col-md-6">
                <h4 className='mb-3  color214545 fw-bold'>Book Store </h4>
            </div> 
            </div>

           <div className="row ">
           <h4 className='mb-4 text-center color214545 fw-bold'>Winning Editions </h4>
           
           {WinningEditions.map((W,index)=>(
            <div className="col-md-2 ">
                <div className="card mb-4 b-none  borderD4AC28 booksectionheight" >
                    <img className="card-img-top bookimageheight" src={W.book_image}  alt="Card image cap" />
                    <div className="card-body  pd-10">
                    <h6 className="card-title fw-bold" style={{height:'38px',fontSize:'13px'}}>{W.title}</h6>
                    <p className="card-text banner2pcolor m-b-none content" style={{height:'60px',fontSize:'13px'}}>{W.Descripation.substring(0, 40)+ '...'}</p>
                    {/* <a href="#" className="floatright banner2pcolor">Read More...</a> */}
                    
                    <div>
                        {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolor000000 colorfff">BUY NOW</button> */}
                        {/* <a href={W.ByLink} className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolor000000 colorfff t-decoration-none" target="__blank">BUY NOW</a> */}
                        <Link to={`/bookread/${W.id}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolor000000 colorfff t-decoration-non">View</Link> 
              
                        <span className="floatright color8B752C m-b-none ">INR {W.price}</span>
                    </div>
                    
                    </div>
                </div>
            </div>
            ))}
           </div>

           <div className="row mt-3">
           <h4 className='mb-4 text-center color214545 fw-bold'>Shop From Library </h4>

           {AllBook.map((AB,index)=>(         
             <div className="col-md-2 " key={index}>
                <div className="card mb-4 b-none  borderD4AC28 booksectionheight ">
                    <img className="card-img-top bookimageheight" src={AB.book_image} alt="Card image cap" />
                    <div className="card-body  pd-10">
                    <h6 className="card-title fw-bold" style={{height:'38px',fontSize:'13px'}}>{AB.title}</h6>
                    <p className="card-text banner2pcolor m-b-none content" style={{height:'60px',fontSize:'13px'}}>{AB.Descripation.substring(0, 40)+ '...'}</p>
                    {/* <a href="#" className="floatright banner2pcolor">Read More...</a> */}
                  
                    <div>
                        {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolor000000 colorfff"></button> */}
                        {/* <a href={AB.ByLink} className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolor000000 colorfff t-decoration-none" target="__blank">BUY NOW</a> */}
                        <Link to={`/bookread/${AB.id}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolor000000 colorfff t-decoration-non">View</Link> 
              
                        <span className="floatright color8B752C m-b-none">INR {AB.price}</span>
                    </div>
                    
                    </div>
                </div>
            </div>
          ))}
           </div>
           

         </div>


        </div>
      )}
       
        
        </>
    )
}