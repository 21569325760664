import React,{ useState ,useEffect} from 'react';
import { Routes, Route, Link, useParams ,useNavigate,useLocation  } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Moment from 'react-moment';
import swal from 'sweetalert';
import './Readingcontest.css';
import { Modal, Button } from 'react-bootstrap';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function importAll(r) {
    let images = {};
    r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  



  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Readingcontest (props)
{
    const { pathname } = useLocation();
    const baseurlapi=props.baseurlapi;
    const panelurl=props.baseurl;
    const params = useParams();
    const id=params.id;
    

    const [Maindata, setMaindata]=useState([]);
    const [openLoginmodel, setLogin] = useState(false);
    const [opensignupmodel, setSignup] = useState(false);
    const [Quizstory, setQuizstory]=useState([]);
    const [attendst, seattendst]=useState([]);
    
    const navigate = useNavigate();

 // session user check 
var LoginData = sessionStorage.getItem("LoginData");
if(LoginData ==null || LoginData=='')
{
    var userid='';
    var roleid='';
}
else
{
    //  var  obj ={ userid: '', Name: '', Role: ''}
    //  sessionStorage.setItem('LoginData', JSON.stringify(obj));
    var data= JSON.parse(LoginData)
    var userid=data.userid;
    var roleid=data.Role;
    
}

    useEffect(() => {
        axios.get(baseurlapi+'/Readingcontest.php?id='+id)
        .then(response2 => {
            setMaindata(response2.data.Maindata)
            setQuizstory(response2.data.Quizstory)
        })
        axios.get(baseurlapi+'/Enrollcontest.php?contestid='+id+'&userid='+userid+'&roleid='+roleid+'&flag=R')
        .then(res => {
          seattendst(res.data.attendst)
        })

        window.scrollTo(0, 1);
    }, [pathname],[]);


    const OpenLoginmodel = () => 
    {
      setLogin(true);
      setSignup(false);
      $("#loginmob").val('');
      $("#loginpassword").val('');
    }

    const LoginClose = () => setLogin(false);

    // for login

const Loginuser = id => ()=>
{
  var loginmob= document.getElementById('loginmob').value;
  var loginpassword= document.getElementById('loginpassword').value;
  if(loginmob=='')
  {
    swal({title: "",text: "Please fill out Mobile no",icon: "error", });
  }
  else if(loginpassword=='')
  {
    swal({title: "",text: "Please fill out Password ",icon: "error",});
  }
  else
  {
    const loginarr = new FormData();
    loginarr.append("password", loginpassword);
    loginarr.append("mobileno", loginmob)

    
    axios.post(baseurlapi+"/Login.php", loginarr).then(res => {
    if(res.data.success=='1')
    {
      var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
      sessionStorage.setItem('LoginData', JSON.stringify(obj));
     
      setLogin(false);
      window.location.reload(false);
    

    }
    else if(res.data.success=='2' || res.data.success=='3')
    {
      swal({title: "",text: res.data.message,icon: "error",});
    }
  });
  
  }
 
}


// user Signup function call here
const Usersignup = id => ()=>
{

  var reviewername= document.getElementById('reviewername').value;
  var reviewermno= document.getElementById('reviewermno').value;
  var revieweremail= document.getElementById('revieweremail').value;
  var reviewerpassword= document.getElementById('reviewerpassword').value;

  var country= document.getElementById('country').value;
  var stdcity= document.getElementById('stdcity').value;
  var schoolname= document.getElementById('schoolname').value;

  var dob= document.getElementById('dob').value;

  if(reviewername=='')
  {
    swal({title: "",text: "Please fill out Name",icon: "error", });
  }
  else if(reviewermno=='')
  {
    swal({title: "",text: "Please fill out Mobile no",icon: "error",});
  }
  else if(revieweremail=='')
  {
    swal({title: "",text: "Please fill out Email",icon: "error",});
  }
  else if(country=='')
  {
            swal({title: "",text: "Please fill out Country",icon: "error",});
  }
  else if(stdcity=='')
  {
              swal({title: "",text: "Please fill out City",icon: "error",});
  }
  else if(dob=='')
  {
    swal({title: "",text: "Please fill out Dob",icon: "error",});
  }
  else if(schoolname=='')
  {
                swal({title: "",text: "Please fill out School Name",icon: "error",});
  }
  else if(reviewerpassword=='')
  {
  swal({title: "",text: "Please fill out Password",icon: "error",});
  }
  else
  {
      const signuparr = new FormData();
      signuparr.append("reviewername", reviewername);
      signuparr.append("reviewermno", reviewermno)
      signuparr.append("revieweremail", revieweremail);
      signuparr.append("reviewerpassword", reviewerpassword);
      signuparr.append("role", 2);
      signuparr.append("country", country);
      signuparr.append("city", stdcity);
      signuparr.append("schoolname", schoolname);
      signuparr.append("dob", dob);

      axios.post(baseurlapi+"/ReviewerSignup.php", signuparr).then(res => {
        console.log(res.data.success)
      if(res.data.success=='1')
      {
        var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
        sessionStorage.setItem('LoginData', JSON.stringify(obj));
        setSignup(false);
        window.location.reload(false);
      }
      else if(res.data.success=='2' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
      else if(res.data.success=='3' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
      else if(res.data.success=='4' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
     });
  }

}

    const OpenSignupmodel = () =>
    {
      setLogin(false);
      setSignup(true);
    } 
    const  SignupClose= () => setSignup(false);


    const handleClickConfirm = () => {
    confirmAlert({
      title: '',
      message: 'Are you ready to take this quiz',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            navigate(`/quiz/${id}`,{ replace: true });
          },
        },
        {
          label: 'No',
          onClick: () => 
          {

          },
        },
      ],
    });
  };

  const Allertfun = ()=>
  {
    confirmAlert({
      title: '',
      message: 'Already You Have Done This Contest',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            navigate(`/home`,{ replace: true });
          },
        }
      ],
    });
  }


  
  const Withoutquizfun = ()=>
  {
    confirmAlert({
      title: '',
      message: 'Are you sure want to attend quiz.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            window.open(panelurl, '_blank');
          },
        },
        {
          label: 'No',
          onClick: () => 
          {

          },
        },
      ],
    });
  }

  const Quizstorylength=Quizstory.length;


//   Link to={`/quiz/${id}`}
    return(
        <>
           <div className="container2 mt-5 mb-5 minheight">
            <div className="row ">
                <div className='col-md-12'>
                   <div className='maindiv'>
                      <img src={images['readingcontestbanner.png']} width="100%" />
                      <div className='subdiv'>
                         <h4 className='pd-20 colorfff fw-bold'>Story Reading Contest</h4>
                         {/* <p className='colorfff p-l-20'>18th Sept 2022 | Sunday <br/> Venue: Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</p> */}
                      </div>
                   </div>
                </div>
            </div>
           <br/>
            <div className="row mt-4">
                {Maindata.map((mdata,index)=>(
                        <div className='col-md-12' key={index}>
                        <h4 className='text-center'>{mdata.title}</h4>
                        <br/>
                        <p dangerouslySetInnerHTML={{__html : mdata.Short_Descripation}}></p>
                        <p><span className="fw-bold">Instruction</span>: {mdata.Instruction}</p>
                        </div>
                ))}
            
            </div>
            {Quizstorylength !=0 ?
                    (
            <div className="row mt-5 ">
                      {Quizstory.map((Qstory,index)=>(

                            // 
                            <div className="col-md-3 mb-4" key={index}>
                                                                    
                            <div className="bgFFFCF2 pd-10" style={{border:'1px solid #B9B9B9',height:'360px'}}>
                            <div className="namelable">  <span className="p-l-10 p-r-10 bgcolorD4AC28 colorfff">{Qstory.studentname}</span></div>

                            <img src={Qstory.CoverPic} width={'100%'} height={'160px'} alt="coverpic"/>
                            <p> <img src={Qstory.profilepic} width={'100%'}  className="profilepic_home" alt="profilepic" /></p>
                            <p className="text-left mt-3 m-b-none color214545">Category :  {Qstory.maincategory}</p>
                            <p  className="text-left m-b-none color214545">Genre :  {Qstory.subcategory}</p>
                            <p  className="text-left m-b-none color214545">
                                Age :  {Qstory.AgeGroup}
                                
                            </p>
                            <p  className="text-left color214545">School :  {Qstory.School_Name.substring(0, 18)}..</p>
                            <h6  className="text-left "><span className="fw-bold">{Qstory.title.substring(0, 25)}..</span> </h6>

                            <div className="mb-3">

                            
                                <Link to={`/storyreading/${Qstory.id}`}    className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 bgcolorD4AC28 color000"> Read Now</Link> 

                            
                                {/* <button className="p-l-10 p-r-10 border-0 b-r-30 f-s-12 floatright " style={{marginTop:'4px'}}>Add to Reads</button> */}
                            </div>
                            </div>
                            </div>
                            
                            ))}

                         
                  <div className="text-center">
                  {Maindata.map((mdata2,index)=>(
                    <span key={index}>
                        {mdata2.ContestType=='Quiz'?
                        <span>
                          {userid!='' && roleid==2 ?
                          <span>
                          {attendst=='N'?
                              <button onClick={handleClickConfirm} className="btn btn-dark">Start Now</button> 
                            : <button onClick={Allertfun} className="btn btn-dark">Start Now</button> 
                            }  
                          </span>
                          :<button className="btn btn-dark mt-2" type="button " onClick={OpenLoginmodel} > Enroll Now</button> 
                          } 
                        </span>
                        :
                        <a href={panelurl} target='blank' className="btn btn-dark">Attend Contest</a>
                        // <button onClick={Withoutquizfun} className="btn btn-dark">Attend Contest</button>
                        }
                    </span>
                       
                  ))} 
                 </div>
                     
             </div>

                ) :
                <div className="row mt-5 ">
                    <div className="col-md-12 mb-4">
                        <p className='text-center color'>No Record Found</p>
                      </div>
                </div>

                }
   
            {/* story section end here  */}
            
        
            </div>

            {/* login and signup model */}
    <Modal show={openLoginmodel} onHide={LoginClose} animation={false}  
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Login</h4>
                 
                  <div className="form-group col-md-12">
                    <label className="popuplable">Mobile No.</label>
                    <input type="tel" className="form-control" id="loginmob"  placeholder='Enter Mobile No.'  />
                  </div>
                
                  <div className="form-group col-md-12">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="loginpassword" placeholder='Enter Password' />
                  </div>
                  
                  <div className="form-group col-md-12">
                    
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Loginuser()} >Login</button>
                    <p className="mt-2">Don't have an Account ? &nbsp;<button className="border-0  p-l-10 p-r-10 p-b-5 mt-2 b-r-5 colorfff bgcolorD4AC28"onClick={OpenSignupmodel}>Sign Up</button>
                    </p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>

    <Modal show={opensignupmodel} onHide={SignupClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Register</h4>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name</label>
                    <input type="text" className="form-control" id="reviewername"    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Mobile No</label>
                    <input type="tel" className="form-control" id="reviewermno"   />
                  </div>
                  
                  <div className="form-group col-md-6">
                    <label className="popuplable">Email</label>
                    <input type="email" className="form-control" id="revieweremail"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Country</label>
                    <input type="text" className="form-control" id="country"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">City</label>
                    <input type="text" className="form-control" id="stdcity"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Date Of Birth</label>
                    <input type="date" className="form-control" id="dob"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">School Name</label>
                    <input type="text" className="form-control" id="schoolname"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="reviewerpassword"  />
                  </div>
                 
                  <div className="form-group col-md-8">
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Usersignup()} >Submit</button>
                    <p className="mt-2">Already have an account ? <span className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={OpenLoginmodel}>Sign in</span></p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>
        </>
    )
}