import React,{ useState ,useEffect} from 'react';
import { Link, useLocation,useParams } from 'react-router-dom';
import './Novelreading.css';
import Ads from './Ads';
import axios from "axios";
import swal from 'sweetalert';
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

  export default function Novelreading(props)
  {
    const { pathname } = useLocation();
    const params = useParams();
    const baseurlapi=props.baseurlapi;
    const hid=params.hid;

    const [ads, setads] = useState([]);
    const [noveldata, setnoveldata] = useState([]);
    const [novelbyid, setnovelbyid] = useState([]);
    const [novelid, setnovelid] = useState([]);

    
    const [openLoginmodel, setLogin] = useState(false);
    const [opensignupmodel, setSignup] = useState(false);
    const [openCommentmodel, setCommentmodel] = useState(false);
    const [Comments, setComments] = useState([]);

    let limit = props.limit || 5;
    const [initialValue, setInitialValue] = useState()
    const [ratingstatus, setratingstatus] = useState('')
    const [likestatus, setlikestatus] = useState('')

  const [rating, setRating] = useState(0);
  const stars = [1, 2, 3, 4, 5];
  const socialurl=window.location.href+'&linkname-Stories By Children';
    const OpenLoginmodel = () => 
    {
      setLogin(true);
      setSignup(false);
      $("#loginmob").val('');
      $("#loginpassword").val('');
    }

    const LoginClose = () => setLogin(false);

    const OpenSignupmodel = () =>
    {
      setLogin(false);
      setSignup(true);
    } 
    const  SignupClose= () => setSignup(false);


    var LoginData = sessionStorage.getItem("LoginData");
    if(LoginData ==null || LoginData=='')
    {
      var userid='';
      var roleid='';
    }
    else
    {
      //  var  obj ={ userid: '', Name: '', Role: ''}
      //  sessionStorage.setItem('LoginData', JSON.stringify(obj));
       var data= JSON.parse(LoginData)
       var userid=data.userid;
       var roleid=data.Role;
      
    }
    

    useEffect(() => { 
      axios.get(baseurlapi+"/Ads.php?type=story")
      .then(response1 => {
            setads(response1.data.Ads)
      })

      axios.get(baseurlapi+'/Novelread.php?hid='+hid+'&userid='+userid)
      .then(response2 => {
        setnoveldata(response2.data.Noveldata)
        setnovelid(response2.data.Novelid)
        setComments(response2.data.Comments)
        setratingstatus(response2.data.ratingstatus)
        setlikestatus(response2.data.likestatus)
        
        setInitialValue( props.currentValue || response2.data.avgrating)

        var novel =[];    
          response2.data.Noveldata.forEach(element => {
               var obj = {};
                if(element.id==response2.data.Novelid[0].id)
                {
                  obj['title']=element.title;
                  obj['subject']=element.subject;
                  obj['story']=element.story;
                  obj['studentname']=element.studentname;
                  obj['AgeGroup']=element.AgeGroup;
                  obj['DOB']=element.DOB;
                  obj['School_Name']=element.School_Name;
                  obj['profilepic']=element.profilepic;
                  obj['subcategory']=element.subcategory;
                  obj['maincategory']=element.maincategory;
                  obj['CoverPic']=element.CoverPic;
                  obj['StoryLikeCount']=element.StoryLikeCount;
                  obj['StoryViewCount']=element.StoryViewCount;
                  novel.push(obj);
                }
            });
            setnovelbyid(novel)
      })
         
      window.scrollTo(0, 0);
    }, [pathname],[]);


    const part=id=>()=>
    {
     
      var novel =[];    
      noveldata.forEach(element => {
           var obj = {};
            if(element.id==id)
            {
              obj['title']=element.title;
              obj['subject']=element.subject;
              obj['story']=element.story;
              obj['studentname']=element.studentname;
              obj['AgeGroup']=element.AgeGroup;
              obj['DOB']=element.DOB;
              obj['School_Name']=element.School_Name;
              obj['profilepic']=element.profilepic;
              obj['subcategory']=element.subcategory;
              obj['maincategory']=element.maincategory;
              obj['CoverPic']=element.CoverPic;
              obj['StoryLikeCount']=element.StoryLikeCount;
              obj['StoryViewCount']=element.StoryViewCount;
              
              novel.push(obj);
            }
        });
        setnovelbyid(novel)
    }
    
    const age2=[];
    novelbyid.forEach(element => {
    var dob = new Date(element.DOB);  
    //calculate month difference from current date in time  
    var month_diff = Date.now() - dob.getTime();  
       
    var age_dt = new Date(month_diff);   
      
    //extract year from date      
    var year = age_dt.getUTCFullYear();  
      
    //now calculate the age of the user  
    var age = Math.abs(year - 1970); 
    age2.push(Math.abs(year - 1970)) 
    
  });


  const OpenCommentmodel = () => 
  {
    setCommentmodel(true);
  }

  const CommentClose = () => setCommentmodel(false);

  // for login

  const Loginuser = id => ()=>
  {
    var loginmob= document.getElementById('loginmob').value;
    var loginpassword= document.getElementById('loginpassword').value;
    if(loginmob=='')
    {
      swal({title: "",text: "Please fill out Mobile no",icon: "error", });
    }
    else if(loginpassword=='')
    {
      swal({title: "",text: "Please fill out Password ",icon: "error",});
    }
    else
    {
      const loginarr = new FormData();
      loginarr.append("password", loginpassword);
      loginarr.append("mobileno", loginmob)

      
      axios.post(baseurlapi+"/Login.php", loginarr).then(res => {
      if(res.data.success=='1')
      {
        var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
        sessionStorage.setItem('LoginData', JSON.stringify(obj));
       
        setLogin(false);
        window.location.reload(false);
      
  
      }
      else if(res.data.success=='2' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
    });
    
    }
   
  }


  // user Signup function call here
  const Usersignup = id => ()=>
  {
    var reviewername= document.getElementById('reviewername').value;
    var reviewermno= document.getElementById('reviewermno').value;
    var revieweremail= document.getElementById('revieweremail').value;
    var reviewerpassword= document.getElementById('reviewerpassword').value;

    if(reviewername=='')
    {
      swal({title: "",text: "Please fill out Name",icon: "error", });
    }
    else if(reviewermno=='')
    {
      swal({title: "",text: "Please fill out Mobile no",icon: "error",});
    }
    else if(revieweremail=='')
    {
      swal({title: "",text: "Please fill out Email",icon: "error",});
    }
    else if(reviewerpassword=='')
    {
      swal({title: "",text: "Please fill out Password",icon: "error",});
    }
    else
    {
      const signuparr = new FormData();
      signuparr.append("reviewername", reviewername);
      signuparr.append("reviewermno", reviewermno)
      signuparr.append("revieweremail", revieweremail);
      signuparr.append("reviewerpassword", reviewerpassword)
      signuparr.append("role", 4)
      
      axios.post(baseurlapi+"/ReviewerSignup.php", signuparr).then(res => {
      if(res.data.success=='1')
      {
        var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
        sessionStorage.setItem('LoginData', JSON.stringify(obj));
        setSignup(false);
        window.location.reload(false);
       
      }
      else if(res.data.success=='2' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
    });
    
    }
  
  }
  
  // Share comment 
  
  
    const Sharecomment = () =>
    {
     
      var commment= document.getElementById('comment').value;
      var name= document.getElementById('name').value;
      var mobileno= document.getElementById('mobileno').value;
      var email= document.getElementById('email').value;
      var place= document.getElementById('place').value;
    
      var subject =novelbyid[0].title;
     
      if(name=='')
      {
        swal({title: "",text: "Please fill name",icon: "error", });
      }
      else if(mobileno=='')
      {
        swal({title: "",text: "Please fill mobile no",icon: "error", });
      }
      else if(email=='')
      {
        swal({title: "",text: "Please fill email",icon: "error", });
      }
      else if(commment=='')
      {
        swal({title: "",text: "Please fill Comment",icon: "error", });
      }
      else
      {
        const commentarr = new FormData();
        commentarr.append("name", name);
        commentarr.append("mobileno", mobileno);
        commentarr.append("email", email);
        commentarr.append("subject", subject);
        commentarr.append("comment", commment);
        commentarr.append("storyid", hid);
        commentarr.append("place", place)
        commentarr.append("StoryType", 'N')
        
        
        axios.post(baseurlapi+"/Sharestorycomment.php", commentarr).then(res => {
          console.log(res.data.success)
        if(res.data.success=='1' || res.data.success=='2')
        {
          var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
          sessionStorage.setItem('LoginData', JSON.stringify(obj));
          $("#comment").val('');
          $("#name").val('');
          $("#email").val('');
          $("#mobileno").val('');
          $("#place").val('');
          setCommentmodel(false);
          swal({title: "",text: res.data.message,icon: "success",}).then(function() {
            window.location.reload(false);
          }); 
        }
        else if( res.data.success=='3')
        {
          swal({title: "",text: res.data.message,icon: "error",});
        }
      });
      }
    }


    
  const Deletecomment = val =>() =>
  {
      axios.get(baseurlapi+'/Deletestorycomment.php?id='+val).then(res => {
      if(res.data.Status=='1')
      {
        swal({title: "",text: res.data.Message,icon: "success",}).then(function() {
          window.location.reload(false);
        });
       
      }
       else if( res.data.Status=='3')
      {
        swal({title: "",text: res.data.Message,icon: "error",});
      }
    })
  }


  const Likefunction = () =>
  {
    confirmAlert({
      title: '',
      message: 'Do you want to like this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
          {
            const likearr = new FormData();
            likearr.append("userid", userid);
            likearr.append("roleid", roleid);
            likearr.append("storyid", hid);
            likearr.append("Storytype", 'N');

            axios.post(baseurlapi+"/Storylike.php", likearr).then(res => {
              if(res.data.success=='1' || res.data.success=='2')
              { 
                swal({title: "",text: res.data.message,icon: "success",}).then(function() {
                  window.location.reload(false);
                });
              }
              else if( res.data.success=='3')
              {
                swal({title: "",text: res.data.message,icon: "error",});
              }
            });
           
          } 
        },
        {
          label: 'No',
          onClick: () =>
          {
            console.log('hi')
          }
        },
      ],
    });
  }

  if (rating != '') 
  {
   
    confirmAlert({
      title: '',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
          {
            const stararr = new FormData();
            stararr.append("starval", rating);
            stararr.append("userid", userid);
            stararr.append("roleid", roleid);
            stararr.append("storyid", hid);
            stararr.append("Storytype", 'N');
        
            axios.post(baseurlapi+"/Sharerating.php", stararr).then(res => {
              if(res.data.success=='1' || res.data.success=='2')
              { 
                swal({title: "",text: res.data.message,icon: "success",}).then(function() {
                  window.location.reload(false);
                });
              }
              else if( res.data.success=='3')
              {
                swal({title: "",text: res.data.message,icon: "error",});
              }
            });
           
          } 
        },
        {
          label: 'No',
          onClick: () =>
          {
            console.log('hi')
          }
        },
      ],
    });
    
  } 
  const Alertfunction = () =>
  {
    swal({title: "",text: 'It should just be already liked.',icon: "success",});

  }
  
    return(
        <>
        <div className="container-fluid  p-t-20 p-b-10 bgcolorFFFFFF border-1px">
        {novelbyid.map((novdata,index)=>(
          
          <div className="container2 mt-5" key={index}>
        
            <div className="row mb-5">
             <div className="col-md-6 rightsection" style={{zIndex: '1',textAlign: 'right'}}>
             <img src={novdata.CoverPic} className="novel-storyreadingimg1 b-r-30"/>
             </div>
             <div className="col-md-6 leftsection">
               <div className="novel-studentinfo bgcolor214545">
                <p className="p-t-15"><span className="fl-r bgcolorD4AC28 p-l-10 p-r-10">{novdata.studentname}</span></p>
                <div>
                <img src={novdata.profilepic} className="studentprofile" style={{borderRadius: '50%'}}/>
                <span className="profilesummery">
                 Category :  {novdata.maincategory}<br/>
                 Genre :  {novdata.subcategory}<br/>
                 Age :  {novdata.AgeGroup}<br/>
                 School :  {novdata.School_Name}
                </span>
                <p className="starratingsection">
                <span className="colorfff" style={{marginRight: '20px',fontSize: '15px',float: 'left',paddingTop: '15px',marginLeft: '25px'}}><i className="fa fa-eye" aria-hidden="true"></i> {novdata.StoryViewCount}</span>
                {[...new Array(limit).keys()].map(param => (
                  <span
                    key={param}
                    data={param + 1}
                    className={param < initialValue ? "star rated" : "star"}
                  />
                ))}
                </p>
                </div>
               </div>
             </div>
            </div>
            
            <div className="mb-5">
             {novelid.map((nid,index)=>(
                  <button type="button " key={index} onClick={part(nid.id)} style={{margin: '10px',marginTop: '0px'}} className="btn bgcolor214545 colorfff novelbtn">Part {index+1} </button>
             ))}
   
            </div>
            <div className="row mt-2">
             <div className="col-md-12">
               <h4 className="text-center fw-bold mt-2 color214545 ">{novdata.title}</h4>
               {/* <h6>{novdata.subject}</h6> */}
                 <p className="mt-4 paragraph-text2" style={{display:'block'}} dangerouslySetInnerHTML={{__html: novdata.story}}>
                
                 </p>
                 
                 <div className="mt-5 fw-bold" style={{display:'block ruby'}}>
                   Share &nbsp;
                   <a href={'https://www.addtoany.com/add_to/facebook?linkurl='+socialurl} target="_blank"><i className="fa fa-facebook-square socialicon bgcolor214545 colorfff" aria-hidden="true"></i></a>&nbsp;
                     <a href={'https://www.addtoany.com/add_to/twitter?linkurl='+socialurl} target="_blank"><i className="fa fa-twitter socialicon bgcolor214545 colorfff" aria-hidden="true"> </i></a>&nbsp;
                     <a href={'https://www.addtoany.com/add_to/whatsapp?linkurl='+socialurl} target="_blank"><i className="fa fa-whatsapp socialicon bgcolor214545 colorfff" aria-hidden="true"></i></a>&nbsp;
                     <a href={'https://www.addtoany.com/add_to/google_gmail?linkurl='+socialurl} target="_blank"><i className="fa fa-envelope socialicon bgcolor214545 colorfff" aria-hidden="true"></i></a>
                     
                    
                    {likestatus=='Y'?
                   <span style={{paddingLeft:'50px'}} className="fw-bold " onClick={Alertfunction}>Like <i className="fa fa-thumbs-o-up f-s-20 " aria-hidden="true"></i> ({novdata.StoryLikeCount})</span>
                   
                   :
                   <span>
                   {userid==''?
                    <span>
                       <span style={{paddingLeft:'50px'}} className="fw-bold like" onClick={OpenLoginmodel}>Like <i className="fa fa-thumbs-o-up f-s-20" aria-hidden="true"></i> </span>

                    </span>
                   : <span style={{paddingLeft:'50px'}} className="fw-bold like" onClick={Likefunction}>Like <i className="fa fa-thumbs-o-up f-s-20" aria-hidden="true"></i> </span>

                   }
                   ({novdata.StoryLikeCount})
                   </span>
   
                   }
                   
                  {ratingstatus=='Y'?
                   null
                  :
                  <span>
                  {userid==''?
                  <span>
                    {/* <span className="color000">Rate:</span>  <span className="f-s-25" onClick={OpenLoginmodel}>&#9734;</span> <span className="f-s-25" onClick={OpenLoginmodel}>&#9734;</span> <span className="f-s-25" onClick={OpenLoginmodel}>&#9734;</span> <span className="f-s-25" onClick={OpenLoginmodel}>&#9734;</span> <span className="f-s-25" onClick={OpenLoginmodel}>&#9734;</span>  */}
                    <div className="rating">
                    {stars.map((star) => {
                      return (
                        <i
                          key={star}
                          onClick={() => OpenLoginmodel()}
                          className={
                            rating >= star ? `fa fa-star fa-star-active` : `fa fa-star`
                          }
                          aria-hidden="true"
                        ></i>
                      );
                    })}
                  </div>
                  </span>
                  :
                  <span>
                    {/* <span className="color000">Rate:</span>  <span className="f-s-25" onClick={Submitrating('1')}>&#9734;</span> <span className="f-s-25" onClick={Submitrating('2')}>&#9734;</span> <span className="f-s-25" onClick={Submitrating('3')}>&#9734;</span> <span className="f-s-25" onClick={Submitrating('4')}>&#9734;</span> <span className="f-s-25" onClick={Submitrating('5')}>&#9734;</span>  */}
                  
                    <div className="rating">
                    {stars.map((star) => {
                      return (
                        <i
                          key={star}
                          onClick={() => setRating(star)}
                          className={
                            rating >= star ? `fa fa-star fa-star-active` : `fa fa-star`
                          }
                          aria-hidden="true"
                        ></i>
                      );
                    })}
                  </div>
                  
                  </span>

                  }
                
                </span>
                }
              
                 </div><br/>
         
                 <button type="button " className="btn bgcolor214545 colorfff mt-2 mb-5" onClick={OpenCommentmodel}>Comments</button>
             </div>
            </div>
 
           
            </div>
            ))}


      {/* coomments section */}

          <div className="container2 ">
          <div className="row d-flex justify-content-center">
          <div className="col-md-8">

          {Comments=='' ?
          null
          : <div className="card commentsection">

          {Comments.map((smt,index)=>(

          <div className="card-body p-b-5" key={index} >
          {index>0?

          <hr style={{marginBottom: '15px',marginTop: '0px'}} />
          :null}

          <div className="d-flex flex-start align-items-center">
            <img className="rounded-circle shadow-1-strong me-3"
              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(19).webp" alt="avatar" width="60"
              height="60" />
            <div>
              <h6 className="fw-bold text-primary mb-1">{smt.name}</h6>
              <p className="text-muted small mb-0">
                Shared publicly -  <Moment format="D MMM YYYY" withTitle>
                  {smt.entrydate}
                </Moment> - {smt.place}
              </p>
            </div>
          </div>
          <p className="f-s-13 m-b-0" style={{marginTop:'5px'}}> {smt.comment} </p>
          <div className="small d-flex justify-content-start">
            {smt.userid==userid ?
              <button className="btn  d-flex align-items-center me-3" onClick={Deletecomment(smt.id)}>
              <i className="fa fa-trash" style={{color:'red'}}></i>
            </button>
            :null}


          </div> 

          </div>


          ))}
          </div>
          }


          </div>
          <div className="col-md-4"></div>
          </div>
          </div>

             <Ads adsarr={ads} />
        </div>
         


            {/* ads slider code here */}
           

            <Modal show={openLoginmodel} onHide={LoginClose} animation={false}  
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Login</h4>
                 
                  <div className="form-group col-md-12">
                    <label className="popuplable">Mobile No.</label>
                    <input type="tel" className="form-control" id="loginmob"  placeholder='Enter Mobile No.'  />
                  </div>
                
                  <div className="form-group col-md-12">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="loginpassword" placeholder='Enter Password' />
                  </div>
                  
                  <div className="form-group col-md-12">
                    
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Loginuser()} >Login</button>
                    <p className="mt-2">Don't have an Account ? &nbsp;<button className="border-0  p-l-10 p-r-10 p-b-5 mt-2 b-r-5 colorfff bgcolorD4AC28"onClick={OpenSignupmodel}>Sign Up</button>
                    </p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>

        <Modal show={opensignupmodel} onHide={SignupClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Register</h4>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name</label>
                    <input type="text" className="form-control" id="reviewername"    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Mobile No</label>
                    <input type="tel" className="form-control" id="reviewermno"   />
                  </div>
                  
                  <div className="form-group col-md-6">
                    <label className="popuplable">Email</label>
                    <input type="email" className="form-control" id="revieweremail"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="reviewerpassword"  />
                  </div>
                 
                  <div className="form-group col-md-8">
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Usersignup()} >Submit</button>
                    <p className="mt-2">Already have an Account ? <span className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={OpenLoginmodel}>Sign in</span></p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>

         <Modal show={openCommentmodel} onHide={CommentClose} animation={false}  
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row mt-5">
                  <div className="col-md-6">
                      <span  className="form-label">Name </span><br/>
                      <input type="text"  id="name" placeholder="Enter your name.."  style={{width:'100%'}} />
                  </div>
                  <div className="col-md-6">
                      <span  className="form-label">Mobile No.</span><br/>
                      <input type="tel"  id="mobileno" placeholder="Enter your mobile no.."  style={{width:'100%'}} />
                  </div>
                  <div className="col-md-6">
                      <span  className="form-label">Email</span><br/>
                      <input type="email"  id="email" placeholder="Enter your email.."  style={{width:'100%'}} />
                  </div>
                  <div className="col-md-6">
                      <span  className="form-label">Place</span><br/>
                      <input type="text"  id="place" placeholder="Enter your email.."  style={{width:'100%'}} />
                  </div>
                  <div className="col-md-12">
                            <span  className="form-label">Comment</span><br/>
                            <textarea  id="comment" placeholder="Share your thought..." rows="2" cols="50" style={{width:'100%'}} ></textarea>
                          
                            <button type="button " className="btn btn-dark mt-2 mb-5" onClick={Sharecomment}>Share</button>
                  </div>
                </div>

                </div>
                </Modal.Body>
         </Modal>
        </>
    )
}