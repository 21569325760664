import React from "react";
import Slider from "react-slick";
import { json, Link } from 'react-router-dom';
import Moment from 'react-moment';
// import './Editorpicksection.css';
function importAll(r) {
    let images = {};
    r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Instagramsection(props)
{
  const Instafeed=props.Instafeed;

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    infinite: true,
  

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  };

    return(
        <>
            <div className="container-fluid bgcolorFFFFFF">
            <div className="container2 mt-5">
            <h6 className="p-t-20"><span style={{letterSpacing: '15px'}} className="fw-bold">#Instagram</span> <span className="floatright">@Storiesbychildren</span></h6>
            <div className="row mt-5 pb-20">
                            <Slider {...settings} className="customeslider">
                            {Instafeed.map((inst,index)=>(
                                <div className="col-md-3 p-l-none p-r-none"  key={index}>
                                <img className="card-img-top" height={270} src={inst.media_url} alt="Card image cap"/>
                                <a className="banner2pcolor m-b-none mt-2" href={inst.permalink} target="blank">@{inst.username}</a>
                                </div>
                            ))}
                            </Slider>
                    </div>
                </div>
        </div>
        </>
    )
}