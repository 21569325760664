import React from "react";
import Slider from "react-slick";
import './Ads.css';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Ads(props)
{
  const adsarr=props.adsarr;

    var settings = {
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay: true,
        speed: 1000,
        infinite: true,

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 4
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return(
        <>
         <div className="container-fluid mt-2">
            <div className="row" style={{width:'100%'}}>
                <div className="col-md-12">
                <Slider {...settings}>
                {adsarr.map((adsdata, index) => ( 
                <img src={adsdata.AdsImage}   key={index} />
                )) }
                </Slider>
                </div>
                
            </div>
        </div>
        </>
    )
}