import React,{ useState ,useEffect} from "react";
import './Activities.css';
import { Link, useLocation,useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
import swal from 'sweetalert';
import $ from 'jquery';
import Ads from './Ads';
export default function Activities(props)
{
    const { pathname } = useLocation();
    const baseurlapi=props.baseurlapi;
    const [contest, setcontest] = useState([]);
    const [ads, setads] = useState([]);
    useEffect(() => { 
            axios.get(baseurlapi+"/Actives_Contest.php")
            .then(response => {
              setcontest(response.data.Contests)
            })
            axios.get(baseurlapi+"/Ads.php?type=Contest All Page")
            .then(response2 => {
                   setads(response2.data.Ads)
            })

        }, [pathname],[]);

    return(
        <>
  
        <div className="container-fluid  bgcolorFFFFFF border-1px">
        <div className="container2  p-b-50">
          <div className="row ">
          <h4 className='mb-5 mt-4 color214545 fw-bold'>Discover Our Contests</h4>
        
          {/* {adsarr.map((adsdata, index) => (  */}
            {contest.map((contestdata,index)=>(
          //     <div className="col-md-4" key={index}>
          //   <div className="card mb-4 b-none bgcolorFFF8E6" style={{height:'520px'}}>
          //       <img className="card-img-top" src={contestdata.MainContest_Image} alt="Card image cap"/>
          //       <div className="card-body px-0">
          //         <h5 className="card-title fw-bold p-l-10 p-r-10">{contestdata.title}</h5>
          //         <p className="card-text p-l-10 p-r-10">{contestdata.Short_Descripation}</p>
          //         {/* <a href="#" className="btn btn-dark btn-sm cardbtn b-radius-30">Enroll &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></a> */}
          //         <Link  to={`/readingchellenge/${contestdata.id}`} className="btn btn-dark btn-sm cardbtn b-radius-30 m-l-10">View &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></Link>
          //       </div>
          //   </div>
          // </div>

<div className="col-md-4" key={index}>
<div className="card mb-4 b-none bgcolorFFF8E6" style={{height:'520px'}}>
 <div className="discoverborder"></div>
 <img className="card-img-top discoverimg" src={contestdata.MainContest_Image}  alt="Card image cap"/>
 
   <div className="card-body px-0">
      <h5 className="card-title fw-bold p-l-10 p-r-10 color214545">{contestdata.title}</h5>
      <p className="card-text p-l-10 p-r-10">{contestdata.Short_Descripation}</p>
      {/* <a href="#" className="btn btn-dark btn-sm cardbtn b-radius-30">Enroll &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></a> */}
      <Link  to={`/readingchellenge/${contestdata.id}`} className="btn btn-dark btn-sm cardbtn b-radius-30  m-l-10">View &nbsp;&nbsp;<i className="fa fa-chevron-right whiltecolo" aria-hidden="true"></i></Link>
   </div>
</div>
</div>
            ) )}
          </div>
          </div> 
          
        </div>
       
        </>
    )
}