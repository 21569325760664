import React,{ useState ,useEffect} from "react";
import './Readingchallenge.css';
import { Link, useLocation,useParams,useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
import swal from 'sweetalert';
import $ from 'jquery';
import Ads from './Ads';
import Contestbannersection from './Contestbannersection';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  


  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Readingchallenge(props)
{
    const { pathname } = useLocation();
    const baseurlapi=props.baseurlapi;
    const panelurl=props.baseurl;
    const baseurl=props.baseurl;
    const params = useParams();
    // const mcid=params.id;

    var currenturl1=window.location.pathname; 
    var mid1 = currenturl1.split('/', 4);
    var mcid  = mid1[2];
    var uid  = mid1[3];

  

    var url='';
    var imgurl='';
    if(mcid==1)
    {
        url='readingcontest';  
        imgurl='Readingcontest.png';
    }
    else if(mcid==2)
    {
        url='writingcontest'; 
        imgurl='Writtingcontest.png'; 
    }
    else if(mcid==3)
    {
        url='eventcontest'; 
        imgurl='Annualcontest.png'; 
    }  
    const [openLoginmodel, setLogin] = useState(false);
    const [opensignupmodel, setSignup] = useState(false);
    const [openinstructionmodel, setInstruction] = useState(false);
    const [instructiondata, setinstructiondata] = useState('');
    const [subcontestid, setsubcontestid] = useState('');
    const [Conteststatus, SetConteststatus] = useState([]);
    

    // session user check 
    var LoginData = sessionStorage.getItem("LoginData");
    if(LoginData ==null || LoginData=='')
    {
      var userid='';
      var roleid='';
    }
    else
    {
      //  var  obj ={ userid: '', Name: '', Role: ''}
      //  sessionStorage.setItem('LoginData', JSON.stringify(obj));

      var data= JSON.parse(LoginData)
      var userid=data.userid;
      var roleid=data.Role;
      
    }

    const Readinstruction = val=> ()=>
    {
     
      setinstructiondata(val)
      setInstruction(true);
    }
    const InstructionClose = ()=> setInstruction(false);
    
    const OpenLoginmodel = val => () =>
    {
      setsubcontestid(val)
      setLogin(true);
      setSignup(false);
      $("#loginmob").val('');
      $("#loginpassword").val('');
    }

    const LoginClose = () => setLogin(false);

    const OpenSignupmodel = () =>
    {
      setLogin(false);
      setSignup(true);
    } 
    const  SignupClose= () => setSignup(false);

    const [subcontest, Setsubcontest]=useState([]);
    const [ads, setads] = useState([]);
    const [ContestBanner, SetContestBanner] = useState([]);

    useEffect(() => {
        axios.get(baseurlapi+'/Contestbyid.php?mcid='+mcid+'&type=A')
        .then(response2 => {
            Setsubcontest(response2.data.Subcontest)
            SetContestBanner(response2.data.ContestBanner)
        })

        axios.get(baseurlapi+"/Ads.php?type=Sub Contest")
        .then(response2 => {
               setads(response2.data.Ads)
        })

        axios.get(baseurlapi+'/Checkconteststatus.php?userid='+userid+'&mid='+mcid)
      .then(response3 => {
          SetConteststatus(response3.data.Conteststatus)
      })

      if (typeof(uid) !== 'undefined' && uid != null)
        {
          axios.get(baseurlapi+'/loginviaurl.php?uid='+uid)
          .then(res => {
            console.log(res)
            if(res.data.success=='1')
            {
              var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
              sessionStorage.setItem('LoginData', JSON.stringify(obj));
              var  navigateurl ='/readingchellenge/'+mcid;
              navigate(navigateurl);
              window.location.reload(false);

            }
          }) 
        } 
 
       
        
      

      
        window.scrollTo(0, 1);
    }, [pathname],[]);

   

// for login

const Loginuser = id => ()=>
{
  var subid=subcontestid;
 
  var loginmob= document.getElementById('loginmob').value;
  var loginpassword= document.getElementById('loginpassword').value;
  if(loginmob=='')
  {
    swal({title: "",text: "Please fill out Mobile no",icon: "error", });
  }
  else if(loginpassword=='')
  {
    swal({title: "",text: "Please fill out Password ",icon: "error",});
  }
  else
  {
    const loginarr = new FormData();
    loginarr.append("password", loginpassword);
    loginarr.append("mobileno", loginmob)

    
    axios.post(baseurlapi+"/Login.php", loginarr).then(res => {
    if(res.data.success=='1')
    {
      var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
      sessionStorage.setItem('LoginData', JSON.stringify(obj));
     
     setLogin(false);
     if(mcid==2)
     {
      var  navigateurl ='/writingcontest/'+subid;
      navigate(navigateurl,{ replace: true });
      // window.location.reload(false);
     }
     if(mcid==3)
     {
      window.open(panelurl, '_blank');
      window.location.reload(false);
     }

    }
    else if(res.data.success=='2' || res.data.success=='3')
    {
      swal({title: "",text: res.data.message,icon: "error",});
    }
  });
  
  }
 
}

// user Signup function call here
const Usersignup = id => ()=>
{
  var subid=subcontestid;
 
  var reviewername= document.getElementById('reviewername').value;
  var reviewermno= document.getElementById('reviewermno').value;
  var revieweremail= document.getElementById('revieweremail').value;
  var reviewerpassword= document.getElementById('reviewerpassword').value;
  
  var country= document.getElementById('country').value;
  var stdcity= document.getElementById('stdcity').value;
  var schoolname= document.getElementById('schoolname').value;

  var dob= document.getElementById('dob').value;

  if(reviewername=='')
  {
    swal({title: "",text: "Please fill out Name",icon: "error", });
  }
  else if(reviewermno=='')
  {
    swal({title: "",text: "Please fill out Mobile no",icon: "error",});
  }
  else if(revieweremail=='')
  {
    swal({title: "",text: "Please fill out Email",icon: "error",});
  }
  else if(country=='')
  {
          swal({title: "",text: "Please fill out Country",icon: "error",});
  }
  else if(stdcity=='')
  {
            swal({title: "",text: "Please fill out City",icon: "error",});
  }
  else if(dob=='')
  {
    swal({title: "",text: "Please fill out Dob",icon: "error",});
  }
  else if(schoolname=='')
  {
              swal({title: "",text: "Please fill out School Name",icon: "error",});
  }
  else if(reviewerpassword=='')
  {
    swal({title: "",text: "Please fill out Password",icon: "error",});
  }
  else
  {
      const signuparr = new FormData();
      signuparr.append("reviewername", reviewername);
      signuparr.append("reviewermno", reviewermno)
      signuparr.append("revieweremail", revieweremail);
      signuparr.append("reviewerpassword", reviewerpassword);
      signuparr.append("role", 2);
      signuparr.append("country", country);
      signuparr.append("city", stdcity);
      signuparr.append("schoolname", schoolname);
      signuparr.append("dob", dob);

      axios.post(baseurlapi+"/ReviewerSignup.php", signuparr).then(res => {
        console.log(res.data.success)
      if(res.data.success=='1')
      {
        var  obj ={ userid: res.data.id, Name: res.data.Name, Role: res.data.Role}
        sessionStorage.setItem('LoginData', JSON.stringify(obj));
        setSignup(false);
        if(mcid==2)
        {
         var  navigateurl ='/writingcontest/'+subid;
         navigate(navigateurl,{ replace: true });
         // window.location.reload(false);
        }
        if(mcid==3)
        {
         window.open(panelurl, '_blank');
         window.location.reload(false);
        }
      }
      else if(res.data.success=='2' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
      else if(res.data.success=='3' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
      else if(res.data.success=='4' || res.data.success=='3')
      {
        swal({title: "",text: res.data.message,icon: "error",});
      }
     });
  }

}

// const Eventfun = val => () =>
// {
//   axios.get(baseurlapi+'/Enrollcontest.php?contestid='+val+'&userid='+userid+'&roleid='+roleid)
//   .then(res => {
//     if(res.data.success==1)
//     {
//       window.open(panelurl, '_blank');
//     }
//   })
// }
  
const Conteststs = () =>
{
  swal({title: "",text: 'Already attended?',icon: "error",});

}

let navigate = useNavigate();

  function changeLocation(placeToGo){
      navigate(placeToGo, { replace: true });
      window.location.reload();
  }

// check array length 

const subcontestlength=subcontest.length;
const Conteststatuslength=Conteststatus.length;

    return(
        <>
          <div className="container-fluid bgcolorFFFFFF">
            <div className="row">
              <div className="col-md-1"> 
              </div>
              <div className="col-md-10">
                  <img src={images[imgurl]} style={{width:'100%',height:'270px'}} />
              </div>
              <div className="col-md-1">
               
              </div>
          </div>

    {/* Contest Banner start here  */}

<Contestbannersection ContestBanner={ContestBanner} />

<div className="container2">
      <div className="row">
      <h4 className="mt-2 color214545 fw-bold">Contest  <Link to={`/contest/${mcid}`} onClick={() => changeLocation(`/contest/${mcid}`)} className="subnava color214545 fl-r">Previous  Contest</Link></h4>
    </div>
   </div>

 {subcontestlength>0?(
  <div className="container2 mt-5 p-b-50">
  <div className="row">
    <div className="col-md-2"></div>
    <div className="col-md-9" style={{marginLeft: '25px'}}>
    {subcontest.map((subcst,index)=>(
     <div className="row bgcolor214545 b-r-30 mb-3" key={index} style={{width:'90%'}}>
        <div className="col-md-6 " style={{paddingLeft: '0px',paddingRight:'0px'}}>
         <img src={subcst.SubContest_Image} className="w-100  b-r-30 contestimg"  />
        </div>
        <div className="col-md-6">
         <h4 className="mt-4 fw-bold colorfff fw-normal">{subcst.title}</h4>
         {/* <p>{subcst.runningstatus}</p> */}
        {subcst.runningflag=='R' ?
       <span>
       

                  { mcid=='1'? 
                  <div className="inline-flex">
                     {subcst.ContestType=='Review Story'? 
                      <Link to={`/contestreviewstory/${subcst.id}`}  className="bgcolor enrollbtn t-decoration-none color000 mt-2">Enroll Now</Link>
                    
                      : <Link to={`/readingcontest/${subcst.id}`}  className="bgcolor enrollbtn t-decoration-none color000 mt-2">Enroll Now</Link>
                    }
                  {subcst.VideoUrl !='' ? 
                  <a href={subcst.VideoUrl} target="_blank" className="mt-2"><i className="fa fa-video-camera f-s-40 m-l-10 " style={{color:'red'}} aria-hidden="true"></i></a>
                  :null}
                  </div>
                  
                  :null }

                { mcid=='2'? 
                  <div className="inline-flex">
                     <Link to={`/writingcontest/${subcst.id}`}  className="bgcolor enrollbtn t-decoration-none color000 mt-2">Enroll Now</Link>

                    
                  {subcst.VideoUrl !='' ? 
                  <a href={subcst.VideoUrl} target="_blank" className="mt-2"><i className="fa fa-video-camera f-s-40 m-l-10 " style={{color:'red'}} aria-hidden="true"></i></a>
                  :null}
                  </div>
                  
                  :null }

                  {
                  mcid=='3'?

                  <div className="inline-flex">
                 
                  {/* {userid!='' && roleid==2 ?
                     <button  className="bgcolor enrollbtn t-decoration-none color000 mt-2" onClick={Eventfun(subcst.id)}>Enroll Now</button>
                    :<button className="bgcolor enrollbtn t-decoration-none color000 mt-2" type="button " onClick={OpenLoginmodel(subcst.id)} > Enroll Now</button>
                    } */}

                    <a href={panelurl} target="blank" className="bgcolor enrollbtn t-decoration-none color000 mt-2">Enroll Now</a>


                 {subcst.VideoUrl !='' ? 
                <a href={subcst.VideoUrl} target="_blank" className="mt-2"><i className="fa fa-video-camera f-s-40 m-l-10 " style={{color:'red'}} aria-hidden="true"></i></a>
                :null}
                </div>

                  
                 :null }

       </span>
        :null}
        <p className="mt-3 colorB9B9B9 shortdesctext"  dangerouslySetInnerHTML={{__html : subcst.shortdesc}}></p>
        <p className="mt-4 colorB9B9B9" onClick={Readinstruction(subcst.Instruction)} style={{textDecoration: 'underline',cursor:'pointer'}}>View Instructions</p>
        </div>
      </div>
    ))}
    </div>
    <div className="col-md-1"></div>
  </div>
</div>
          ):null}
        
        <Ads adsarr={ads}/>
        </div>
        
      <Modal show={openLoginmodel} onHide={LoginClose} animation={false}  
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Login</h4>
                 
                  <div className="form-group col-md-12">
                    <label className="popuplable">Mobile No.</label>
                    <input type="tel" className="form-control" id="loginmob"  placeholder='Enter Mobile No.'  />
                  </div>
                
                  <div className="form-group col-md-12">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="loginpassword" placeholder='Enter Password' />
                  </div>
                  
                  <div className="form-group col-md-12">
                    
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Loginuser()} >Login</button>
                    <p className="mt-2">Don't have an Account ? &nbsp;<button className="border-0  p-l-10 p-r-10 p-b-5 mt-2 b-r-5 colorfff bgcolorD4AC28"onClick={OpenSignupmodel}>Sign Up</button>
                    </p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>

    <Modal show={opensignupmodel} onHide={SignupClose} animation={false}  
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row pd-20" > 
                 <h4 className=' fw-bold text-center colorD4AC28'>Register</h4>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Name</label>
                    <input type="text" className="form-control" id="reviewername"    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">Mobile No</label>
                    <input type="tel" className="form-control" id="reviewermno"   />
                  </div>
                  
                  <div className="form-group col-md-6">
                    <label className="popuplable">Email</label>
                    <input type="email" className="form-control" id="revieweremail"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Country</label>
                    <input type="text" className="form-control" id="country"  />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="popuplable">City</label>
                    <input type="text" className="form-control" id="stdcity"  />
                  </div>


                  <div className="form-group col-md-6">
                    <label className="popuplable">Date Of Birth</label>
                    <input type="date" className="form-control" id="dob"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">School Name</label>
                    <input type="text" className="form-control" id="schoolname"  />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="popuplable">Password</label>
                    <input type="password" className="form-control" id="reviewerpassword"  />
                  </div>
                 
                  <div className="form-group col-md-8">
                    <button className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={Usersignup()} >Submit</button>
                    <p className="mt-2">Already have an account ? <span className="border-0  p-l-10 p-r-10 p-b-5 mt-4 b-r-5 colorfff  bgcolorD4AC28" onClick={OpenLoginmodel}>Sign in</span></p>
                  </div>
                 </div>

                </div>
                </Modal.Body>
         </Modal>

         {/* instruction model start here */}

         <Modal show={openinstructionmodel} onHide={InstructionClose} animation={false}  
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered >
                <Modal.Header closeButton className=" border-0">
                </Modal.Header>
                <Modal.Body className=" " style={{borderRadius:'0px 0px 10px 10px'}}>
                <div className="container ">   
                <div className="row "  style={{marginTop: '-40px'}}> 
                 <h4 className=' fw-bold text-center colorD4AC28'>Instruction</h4>
                 
                 <p style={{whiteSpace: 'pre-wrap'}}>{instructiondata}</p>
                 
                 </div>

                </div>
                </Modal.Body>
         </Modal>
        
        </>
    )
}