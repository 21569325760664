import React,{ useState ,useEffect} from 'react';
import { Routes, Route, Link, useParams ,useNavigate,useLocation  } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Moment from 'react-moment';
import swal from 'sweetalert';
import './Quiz.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export default function Quiz (props)
{

    const { pathname } = useLocation();
    const baseurlapi=props.baseurlapi;
    const params = useParams();
    const id=params.id;

    const [Quizdata, setQuizdata]=useState([]);
    var timeval=.5;
    const navigate = useNavigate();


var LoginData = sessionStorage.getItem("LoginData");
if(LoginData ==null || LoginData=='')
{
    var userid='';
    var roleid='';
}
else
{
    //  var  obj ={ userid: '', Name: '', Role: ''}
    //  sessionStorage.setItem('LoginData', JSON.stringify(obj));
    var data= JSON.parse(LoginData)
    var userid=data.userid;
    var roleid=data.Role;
    
}

    useEffect(() => {
        axios.get(baseurlapi+'/Readingcontest.php?id='+id)
        .then(response2 => {
            setQuizdata(response2.data.Quizdata)
        })

       

        if(timeval!='')
        {
        setRunTimer(true);
        }

       window.scrollTo(0, 1);
    }, [pathname],[]);


// option click function here 

const submit  =  ()=>
{

  confirmAlert({
    title: '',
    message: 'Are you sure want to submit',
    buttons: [
      {
        label: 'Yes',
        onClick: () => 
        {
          var Arr =[];
          for (let i = 1; i <= Quizdata.length; i++) 
          {
              var obj = {};
              var option=$("input[name=qz"+(i)+"]:checked").val();
              obj['id']=option;
              Arr.push(obj);
          }
      
          const qzarr = new FormData();
          qzarr.append("qzarr", JSON.stringify(Arr));
          qzarr.append("userid", userid);
          qzarr.append("contestid", id)
       
      
          axios.post(baseurlapi+"/Submitquiz.php", qzarr).then(res => {
              
              if(res.data.success=='1')
              {
                swal({title: "",text: res.data.message,icon: "success",});
                $("#comment").val('');
              }
              else if(res.data.success=='2' || res.data.success=='3')
              {
                swal({title: "",text: res.data.message,icon: "error",});
              }
            });
          
          navigate(`/home`,{ replace: true });
        },
      },
      {
        label: 'No',
        onClick: () => {
          
        },
      },
    ],
  });
    
}


// count douwn code start  here 

  const [countDown, setCountDown] = React.useState(1);
  const [runTimer, setRunTimer] = React.useState(false);

  React.useEffect(() => { 
    let timerId;
    if (runTimer) {

      axios.get(baseurlapi+'/Quiztime.php?contestid='+id+'&userid='+userid)
      .then(res=> {
         
          setCountDown(60 * res.data.time);
      })
      
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
        submit2()
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);  

// count down end here 


const submit2 = ()=>
{
  confirmAlert({
    title: '',
    message: 'Your Exam Time Expired, Press Yes Button To Submit Quiz!',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          var Arr =[];
          for (let i = 1; i <= Quizdata.length; i++) 
          {
              var obj = {};
              var option=$("input[name=qz"+(i)+"]:checked").val();
              obj['id']=option;
              Arr.push(obj);
          }
      
          const qzarr = new FormData();
          qzarr.append("qzarr", JSON.stringify(Arr));
          qzarr.append("userid", userid);
          qzarr.append("contestid", id)
       
      
          axios.post(baseurlapi+"/Submitquiz.php", qzarr).then(res => {
              
              if(res.data.success=='1')
              {
                swal({title: "",text: res.data.message,icon: "success",});
                $("#comment").val('');
              }
              else if(res.data.success=='2' || res.data.success=='3')
              {
                swal({title: "",text: res.data.message,icon: "error",});
              }
            });
          
          navigate(`/home`,{ replace: true });
        },
      }
    ],
  });
}




  return(
    <>
      <div className="container2 mt-5">
      <h2>
        Exam Time : {minutes}:{seconds}
      </h2>

      <div className='row mt-5 mb-5'>
               <div className='col-md-12'>
                <h4>Answer the quiz below</h4>

                {Quizdata.map((Qz,index)=>(

                 <div className='row' key={index}>
                    <p> {Qz.Question}</p>
                   <div className='col-md-6 mb-2'>
                   <div className="form-check">
                    <input className="form-check-input question" type="radio" value={'1#'+Qz.id+'#'+Qz.Answer}   name={'qz'+(index+1)}  id={'qz'+Qz.id}/>
                    <label className="form-check-label"> {Qz.option1} </label>
                    </div>
                   </div>
                  
                   <div className='col-md-6 mb-2'>
                   <div className="form-check">
                    <input className="form-check-input question" type="radio"  value={'2#'+Qz.id+'#'+Qz.Answer}   name={'qz'+(index+1)} id={'qz'+Qz.id}/>
                    <label className="form-check-label">{Qz.option2} </label>
                    </div>
                   </div>
                   <div className='col-md-6 mb-2'>
                   <div className="form-check">
                    <input className="form-check-input question" type="radio"  value={'3#'+Qz.id+'#'+Qz.Answer}   name={'qz'+(index+1)} id={'qz'+Qz.id}/>
                    <label className="form-check-label"> {Qz.option3} </label>
                    </div>
                   </div>
                   <div className='col-md-6 mb-2'>
                   <div className="form-check">
                    <input className="form-check-input question" type="radio"  value={'4#'+Qz.id+'#'+Qz.Answer}   name={'qz'+(index+1)}  id={'qz'+Qz.id}/>
                    <label className="form-check-label"> {Qz.option4} </label>
                    </div>
                   </div>
                  
                 </div>
                 
                ))}
                <br/>
                <br/>
               <button type="button" className="btn btn-dark" onClick={submit}>Submit</button>
               </div>
               
            </div>
      </div>
    </>
  )
}