import React,{ useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import './Contactus.css';
import swal from 'sweetalert';
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));
export default function Contactus (props)
{

  const baseurl=props.baseurl;
  const baseurlapi=props.baseurlapi;
  // school registration

  function Contact()
  {
    var name= document.getElementById('name').value;
    var number= document.getElementById('number').value;
    var email= document.getElementById('email').value;
    var message= document.getElementById('message').value;
  
    if(name=='')
      {
        swal({title: "",text: "Please fill out  name",icon: "error", });
      }
      else if(number=='')
      {
        swal({title: "",text: "Please fill out number ",icon: "error",});
      }
      else if(email=='')
      {
        swal({title: "",text: "Please fill out email ",icon: "error",});
      }
      else if(message=='')
      {
        swal({title: "",text: "Please fill out message",icon: "error",});
      }
      else
      {
            const Contactarr = new FormData(); 
            Contactarr.append("name",name);
            Contactarr.append("number", number);
            Contactarr.append("message",message);
            Contactarr.append("email", email);
        
            axios.post(baseurlapi+"/Contactus.php", Contactarr)
            .then(res => {
        //       console.log(res);
        // console.log(res.data);
                if(res.data.success=='1')
                {
                  swal({title: "",text: res.data.message,icon: "success",}).then(function() {
                    window.location.reload(false);
                });
                }
                else if(res.data.success=='2')
                {
                  swal({title: "",text: res.data.message,icon: "error",});
                } 
          });

   
    }
  }
    return(
        <>
         <div className="container-fluid">
            <div className="container2">
            <div className="row mt-5 mb-5" style={{minHeight: '440px'}}>
              <div className="col-md-12">
               <h1 className='mt-1 color214545 fw-bold text-center'>CONTACT US</h1>
                <p className="mt-0 text-center">Send in your queries. We will be happy to help.</p>
                <div className="row">
                  <div className="col-md-6">
                      <div>
                        <img src={images['contactus.png']} className="w-100" />
                      </div>
                      {/* <p>Cell : +91 8660477790</p>
                      <p className='m-b-0'>Email id - info@storiesbychildren.com</p> */}
                      <p className='m-b-0'>Registered Address: 102 Maruthi Nilaya Shubh Enclave Bengaluru KARNATAKA 560102</p>
                      <p className='m-b-0'>Operational Address: 102 Maruthi Nilaya Shubh Enclave Bengaluru KARNATAKA 560102 </p>
                      <p className='m-b-0'>Telephone No: 8660477790</p>
                      <p className='m-b-0'>E-Mail ID: storiesbychildren@gmail.com </p>
                  </div>
                  <div className="col-md-6">
                    <div className="col-lg-12">
                     
                      <div className="form-group">
                        <input type="text" id="name" className="form-control mt-2" placeholder="Name" required/>
                      </div>
                    </div>
                    <div className="col-lg-12 ">
                      <div className="form-group">
                        <input type="text" id="number" className="form-control mt-2" placeholder="Mobile No." required/>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-2">
                      <div className="form-group">
                        <input type="email" id="email" className="form-control mt-2" placeholder="Email" required/>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div className="form-group">

                        <textarea id="message" name="message" rows="8" cols="60" className="w-100" placeholder="Message"></textarea>
                      </div>
                    </div>
                   <div className="col-12 mt-2">
                      <button className="btn mt-2 bgcolor214545 colorfff" type="submit" onClick={Contact}>Submit</button>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         </div>
        </>
    )
}