import React from "react";
import Slider from "react-slick";
import { json, Link } from 'react-router-dom';
import Moment from 'react-moment';
// import './Editorpicksection.css';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Associationsection(props)
{
  const association=props.association;

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    infinite: true,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };

    return(
        <>
         <div className="container-fluid bgcolorFFFFFF">
    <div className="container2">
     <div className="row">
     <h4 className='mb-5 mt-5 color214545 fw-bold'> Our Association 
     <Link to="/ourassociation" className="floatright color214545">View All</Link>
     </h4>
     <div className="row textcenter">
            <div className="col-md-1"></div>
                    <Slider {...settings} className="customeslider">
                    {association.map((asso,index) => (
                        <div className="col-md-2" key={index}>
                          <div className="associationsection">
                            <img src={asso.filename} alt="news" className="Associationimg"  />
                            <p style={{wordWrap: 'break-word'}}>{asso.title}</p>
                          </div>
                           
                        </div>
                        ))}
                    
                    </Slider>
                    <div className="col-md-1"></div>
              </div>
            
            </div>
        </div>

        </div>
        
        </>
    )
}